import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';


export const Call_Single_Order_Details = createAsyncThunk(
    'singleOrder/Call_Single_Order_Details',
    async (id) => {
        const response = await axios.get(`/api/v1/customer/order/details/${id}`);
        return response.data;
    },
);


const singleOrderSlice = createSlice({
    name: "singleOrder",
    initialState: {
        status: 'idle',
        isLoading: false,
        order: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            
            // Scanned Cup Details 
            .addCase(Call_Single_Order_Details.pending, (state) => {
                state.status = 'loading';
                state.isLoading = true;
                state.order = null;
                state.error = null;
            })
            .addCase(Call_Single_Order_Details.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.order = action.payload;
                state.error = null;
            })
            .addCase(Call_Single_Order_Details.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.order = false;
                state.error = action.error.message;
            })
    },
});

export const state_Call_Fetch_Status = (state) => state.singleOrder.status;
export const state_Is_Loading = (state) => state.singleOrder.isLoading;
export const state_Single_Order = (state) => state.singleOrder.order;
export const state_Has_Error = (state) => state.singleOrder.error;

export default singleOrderSlice.reducer;