import React from 'react';
import { RxDotFilled } from "react-icons/rx";
import { Helmet } from 'react-helmet';

const RefundPolicy = () => {
  return (
    <div className='w-full h-max flex items-center justify-center'>
            <Helmet>
        <title>CHIPT | REFUND POLICY</title>
      </Helmet>
      <div className='w-[92%] mx-[4%] h-auto overflow-auto mt-4 mb-16'>
        <p className='w-full h-auto py-2 px-4 text-2xl font-bold text-center'>Return & Refund Policy</p>
        <p className='w-full h-auto text-xs sm:text-xs my-4'>Updated at 2023-12-15</p>
        <div className='w-[100%] h-auto my-4'>
          <p className='w-[100%] h-auto text-lg font-semibold break-all'>Definitions and key terms</p>
          <div className='w-[100%] h-auto'>
            <p className='text-xs sm:text-xs font-normal break-word'>To help explain things as clearly as possible in this Return & Refund Policy, every time any of these terms are referenced, they are strictly defined </p>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal break-word'>Cookie: a small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, and remember information about you such as your language preference or login information.</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>Company: when this policy mentions "Company," "we," "us," or "our," it refers to CHIPT Smart Cup System, the entity responsible for your information under this Return & Refund Policy.</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>Customer: refers to the individual or entity that has purchased or intends to purchase CHIPT smart cups or related products from the Company.</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>Device: any internet-connected device such as a phone, tablet, computer, or any other device that can be used to access the CHIPT Smart Cup System and its services.</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>Service: refers to the services provided by CHIPT Smart Cup System as described in the relevant terms and on the website.</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>Website: the CHIPT Smart Cup System website, accessible via the URL: [insert website URL]</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>You: a person or entity that has registered with CHIPT Smart Cup System to use the services or has made a purchase.</p>
            </div>
          </div>
        </div>

        {/* 2 */}
        <div className='w-[100%] h-auto my-8'>
          <p className='w-[100%] h-auto text-lg font-semibold  break-all'>Return & Refund Policy</p>
          <p className='w-[100%] h-auto text-xs sm:text-xs font-normal break-word'>Thank you for shopping at CHIPT Smart Cup System. We strive to provide you with the best products and a rewarding experience. Please review the following terms and conditions that apply to transactions and purchases made through CHIPT Smart Cup System. By placing an order or making a purchase, you agree to the terms set forth below.</p>
        </div>

        {/* 3 */}
        <div className='w-[100%] h-auto my-8'>
          <p className='w-[100%] h-auto text-lg font-semibold  break-all'>Refunds</p>
          <p className='w-[100%] h-auto text-xs sm:text-xs font-normal  break-word'>We take great care in inspecting, checking for defects, and packaging our products to ensure your satisfaction. However, in some cases, we may face issues with our inventory or quality check, which may result in the cancellation of your order. If this occurs, you will be notified in advance, and if you have made an online payment (not Cash on Delivery), you will be refunded once our team confirms your request.</p>
          <p className='w-[100%] h-auto text-xs sm:text-xs font-normal  break-word'>Please note that CHIPT Smart Cup System is not liable for any damages that occur to the items during transit or transportation. We ensure proper packaging to minimize the risk of damage, but we cannot guarantee the handling by third-party shipping providers.</p>
        </div>

        {/* 4 */}
        <div className='w-[100%] h-auto my-8'>
          <p className='w-[100%] h-auto text-lg font-semibold  break-all'>Return Policy</p>
          <p className='w-[100%] h-auto text-xs sm:text-xs font-normal break-word'>We do not allow returns or accept returned goods for sold products, whether purchased online or in retail outlets. We believe in providing our customers with the best quality products and do not encourage exchanges or reselling of used products. Therefore, refunds are not given for any purchases made, and exchanges or returns are not supported.</p>
        </div>

        {/* 5 */}
        <div className='w-[100%] h-auto my-8'>
          <p className='w-[100%] h-auto text-lg font-semibold break-all'>For International Orders:</p>
          <div className='w-[100%] h-auto'>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal break-word'>Exchanges or returns are not supported.</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>If you cancel your order before it is processed and dispatched for shipping, a refund can be processed. Please note that orders generally take 1-2 days to process before dispatch.</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>Orders that have already been shipped cannot be returned, canceled, or refunded.</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-notext-xs sm:text-xsrmal'>If you encounter any issues, please contact our Support Team immediately.</p>
            </div>
          </div>
        </div>

        {/* 6 */}
        <div className='w-[100%] h-auto my-8'>
          <p className='w-[100%] h-auto text-lg font-semibold  break-all'>Changes to Our Return & Refund Policy</p>
          <p className='w-[100%] h-auto text-xs sm:text-xs font-normal break-word'>We may update, amend, or make changes to this Return & Refund Policy to accurately reflect our service and policies. Any changes will be prominently posted here, and by continuing to use the service, you will be bound by the updated Return & Refund Policy. If you do not agree to the updated policy, you can delete your account.</p>
        </div>

        {/* 7 */}
        <div className='w-[100%] h-auto my-8'>
          <p className='w-[100%] h-auto text-lg font-semibold  break-all'>Contact Us</p>
          <p className='w-[100%] h-auto text-xs sm:text-xs font-normal break-word'>If you have any questions or concerns regarding our products or services, please don't hesitate to contact us:</p>
          <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>Via Email: dalytrading@gmail.com</p>
            </div>
        </div>

      </div>
    </div>
  )
}

export default RefundPolicy