import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';


export const Call_Single_Txn_Details = createAsyncThunk(
    'singleTxnDetails/Call_Single_Txn_Details',
    async (id) => {
        const response = await axios.get(`/api/v1/customer/order/transaction/${id}`);
        return response.data;
    },
);


const singleTransactionSlice = createSlice({
    name: "singleTxnDetails",
    initialState: {
        status: 'idle',
        isLoading: false,
        singleTxn: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            
            // Scanned Cup Details 
            .addCase(Call_Single_Txn_Details.pending, (state) => {
                state.status = 'loading';
                state.isLoading = true;
                state.singleTxn = null;
                state.error = null;
            })
            .addCase(Call_Single_Txn_Details.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.singleTxn = action.payload;
                state.error = null;
            })
            .addCase(Call_Single_Txn_Details.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.singleTxn = false;
                state.error = action.error.message;
            })
    },
});

export const state_Call_Fetch_Status = (state) => state.singleTxnDetails.status;
export const state_Is_Loading = (state) => state.singleTxnDetails.isLoading;
export const state_Single_Txn = (state) => state.singleTxnDetails.singleTxn;
export const state_Has_Error = (state) => state.singleTxnDetails.error;

export default singleTransactionSlice.reducer;