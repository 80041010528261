import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import OtpInput from 'react-otp-input';
import toast from 'react-hot-toast';
import Chipt_Security_Footer from '../../NavigationComponents/Chipt_Security_Footer';
import { Call_OTP_Verify, Call_Resend_OTP_Verify } from '../../../redux/authentication/AccountOTPSlice';
import { Call_Account_Status } from '../../../redux/authentication/AccountStatusSlice';
import { Call_Get_Profile } from '../../../redux/Profile/CustomerProfileSlice';
import { ActionsContext } from '../../../context/Context';
import Project_Loader from '../../NavigationComponents/Project_Loader';
import { Helmet } from 'react-helmet';


const Chipt_Account_OTP_Verifcation = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const chiptAction = useContext(ActionsContext);
  const [otp, setOtp] = useState('');
  const [callLoader, setCallLoader] = useState(false);

  const handleOTPSubmit = (e) => {
    e.preventDefault();
    setCallLoader(true);
    chiptAction.loadRef.current.staticStart();
    if (!otp) {
      toast.error('Please provide OTP');
      chiptAction.loadRef.current.complete();
      setCallLoader(false);
      return
    }
    dispatch(Call_OTP_Verify(otp))
      .then((data) => {
        if (data.payload) {
          if (data.payload.success) {
            dispatch(Call_Get_Profile())
            dispatch(Call_Account_Status())
            toast.success(data.payload.message);
            chiptAction.loadRef.current.complete();
            setCallLoader(false);
            navigate('/profile/update');
          } else {
            toast.error(data.payload.message);
            chiptAction.loadRef.current.complete();
            setCallLoader(false);
          }
        } else {
          toast.error(`Something went wrong`);
          chiptAction.loadRef.current.complete();
          setCallLoader(false);
        }
      })
  }

  const handleResendOTP = (e) => {
    e.preventDefault();
    setCallLoader(true);
    chiptAction.loadRef.current.staticStart();
    dispatch(Call_Resend_OTP_Verify())
      .then((data) => {
        if (data.payload) {
          if (data.payload.success) {
            toast.success(data.payload.message, true);
            chiptAction.loadRef.current.complete();
            setCallLoader(false);
          } else {
            toast.error(data.payload.message, false);
            chiptAction.loadRef.current.complete();
            setCallLoader(false);
          }
        } else {
          toast.error(`Something went wrong`, false);
          chiptAction.loadRef.current.complete();
          setCallLoader(false);
        }
      })
  }

  useEffect(() => { }, [callLoader])

  return (
    <>
      {/* Main Container Start */}
      {callLoader ? <div className='w-[100%] md:-ml-[15%] lg:-ml-[25%] h-screen absolute flex items-center justify-center'><Project_Loader /></div> : <></>}
      <div className='w-[80%] h-auto mx-[10%] overflow-auto'>

        {/* Sub Container start */}
        <div className='w-[100%] h-[82vh] sm:h-[86vh]'>

          {/* Project logo navigation */}

          {/* Content Start */}
          <div className='w-[100%] h-[84vh] sm:h-[77vh] lg:w-[40%] lg:mx-[30%] md:w-[50%] md:mx-[25%]'>
            <div className='pt-28 text-center'>
              <h1 className='font-bold text-2xl'>Enter OTP</h1>
              <p className='text-sm mt-2'>Check you email for OTP</p>
            </div>
            <div className='mt-12 flex item-center justify-center'>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                placeholder={0}
                containerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                inputStyle={{ width: "8%", borderBottom: '0.15rem solid #C8C1C1', margin: '0 2%', textAlign: 'center', outline: 'none' }}
                renderSeparator={<span></span>}
                renderInput={(props) => <input  {...props} />}
              />
            </div>
            <div className='mt-8 flex justify-center'>
              <p className='text-xs font-medium'>Didn't received OTP&nbsp;<span className='font-bold underline cursor-pointer' onClick={handleResendOTP}>Resend</span></p>
            </div>
            <div className='text-center'>
              <button className='bg-primaryBlue hover:bg-primaryBlueHover hover:shadow-md text-text-light text-lg w-[70%] py-2 sm:py-1.5 rounded-2xl font-bold my-4' onClick={handleOTPSubmit}>Verify</button>
            </div>
          </div>
        </div>
        {/* Sub Container End */}

        {/* bottom footer */}
        <Chipt_Security_Footer />
      </div>
      {/* Main Container end */}
      <Helmet>
        <title>CHIPT | OTP VERIFICATION</title>
      </Helmet>
    </>

  )
}

export default Chipt_Account_OTP_Verifcation;