import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';


export const Call_Forgot_Password_OTP = createAsyncThunk(
    'accountReset/Call_Forgot_Password_OTP',
    async (data) => {
        const response = await axios.post(`/api/v1/customer/account/forgot/password/otp`, { email: data });
        return response.data;
    },
);

export const Call_Forgot_Password_OTP_Verify = createAsyncThunk(
    'accountReset/Call_Forgot_Password_OTP_Verify',
    async (data) => {
        const response = await axios.put(`/api/v1/customer/account/forgot/otp/verify`, data);
        return response.data;
    },
);

export const Call_Reset_Password_OTP = createAsyncThunk(
    'accountReset/Call_Reset_Password_OTP',
    async (data) => {
        const response = await axios.put(`/api/v1/customer/account/password/reset/otp`, data );
        return response.data;
    },
);


const accountOTPSlice = createSlice({
    name: "accountReset",
    initialState: {
        status: 'idle',
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Sign In
            .addCase(Call_Forgot_Password_OTP.pending, (state) => {
                state.status = 'loading';
                state.isLoading = true;
                state.error = null;
            })
            .addCase(Call_Forgot_Password_OTP.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.error = null;
            })
            .addCase(Call_Forgot_Password_OTP.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.error = action.error.message;
            })
            // Sign In
            .addCase(Call_Forgot_Password_OTP_Verify.pending, (state) => {
                state.status = 'loading';
                state.isLoading = true;
                state.error = null;
            })
            .addCase(Call_Forgot_Password_OTP_Verify.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.error = null;
            })
            .addCase(Call_Forgot_Password_OTP_Verify.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.error = action.error.message;
            })
            // Sign In
            .addCase(Call_Reset_Password_OTP.pending, (state) => {
                state.status = 'loading';
                state.isLoading = true;
                state.error = null;
            })
            .addCase(Call_Reset_Password_OTP.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.error = null;
            })
            .addCase(Call_Reset_Password_OTP.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.error = action.error.message;
            })
    },
});

export const state_Call_Fetch_Status = (state) => state.accountOTP.status;
export const state_Is_Loading = (state) => state.accountOTP.isLoading;
export const state_Has_Error = (state) => state.accountOTP.error;

export default accountOTPSlice.reducer;