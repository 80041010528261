import React, { useContext } from 'react';
import Loader from '../asset/spinner1.gif';

const Chipt_NFC_Scanner = ({changeFun}) => {

  return (
    <div className='w-[88%] h-[13.5rem] mx-[6%] p-1 my-4 rounded-lg shadow-lg shadow-gray-light border border-search-box-border overflow-hidden'>
      <div className='w-[100%] h-[2rem] float-left flex items-center justify-end overflow-hidden'>
        <button className="w-min rounded-full bg-black text-white text-xs font-bold px-3 py-2" onClick={changeFun}>X</button>
      </div>
      <div className='w-[100%] h-[10.5rem] float-left my-1 flex items-center justify-center'>
        <img className="w-auto h-[10rem" src={Loader} alt="Loading" />
      </div>
    </div>

  );
};

export default Chipt_NFC_Scanner;