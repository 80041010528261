import React, { useEffect, useState } from "react";
import { Call_Get_Profile } from "../../redux/Profile/CustomerProfileSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const WalletMain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(Call_Get_Profile()).then((data) => {
      if (data.payload) {
        setCustomer(data.payload.customer);
        fetchWalletData(data.payload.customer._id);
      }
    });
  }, [dispatch]);

  const fetchWalletData = async (id) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`/api/v1/customer/wallet/${id}`);

      console.log(response.data);
      if (response.data && response.data.data) {
        setWallet(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching wallet data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const activateWallet = async () => {
    try {
      const response = await axios.post("/api/v1/customer/wallet/activate", {
        customerId: customer._id,
        securityDepositAmount: 100,
      });

      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      console.error("Error activating wallet:", error);
    }
  };

  const handleWithdraw = async () => {
    try {
      const response = await axios.post("/api/v1/customer/wallet/withdraw", {
        customerId: customer._id,
      });

      if (response.data.success) {
        fetchWalletData(customer._id);
      }
    } catch (error) {
      console.error("Error processing withdrawal:", error);
    }
  };

  const redirectToPricingPage = () => {
    navigate("/packages");
  };

  if (loading && !wallet) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <h2 className="text-2xl font-semibold mb-4">Wallet Balance</h2>
        {wallet?.isWalletActive ? (
          <>
            <p className="text-xl text-gray-700 mb-6">
              Credits: {wallet.cupCredits}
            </p>
            <p className="text-xl text-gray-700 mb-6">
              Security Deposit: RM {wallet.securityDeposit}
            </p>
            <button
              onClick={redirectToPricingPage}
              className="mt-4 px-6 py-2 bg-gray-dark text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
            >
              Buy Credits
            </button>
            <button
              onClick={handleWithdraw}
              className="mt-4 ml-4 px-6 py-2 bg-gray-dark text-white rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
            >
              Withdraw
            </button>
          </>
        ) : (
          <button
            onClick={activateWallet}
            className="mt-4 px-6 py-2 bg-gray-dark text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
          >
            Activate Wallet
          </button>
        )}
        {loading && !wallet && (
          <button
            onClick={activateWallet}
            className="mt-4 px-6 py-2 bg-gray-dark text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
          >
            Create Wallet
          </button>
        )}
      </div>
    </div>
  );
};

export default WalletMain;
