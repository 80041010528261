import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';


export const Call_Get_All_Vendors = createAsyncThunk(
    'allVendorsData/Call_Get_All_Vendors',
    async (data) => {
        let response;
        if(data && data.srch){
            response = await axios.put(`/api/v1/customer/vendor/all?keyword=${data.srch}`, data.loc);
        } else {
            response = await axios.put(`/api/v1/customer/vendor/all`, data.loc);
        }
        return response.data;
    },
);


const allVendorsDataSlice = createSlice({
    name: "allVendors",
    initialState: {
        status: 'idle',
        isLoading: false,
        vendors: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        // Sign In
            .addCase(Call_Get_All_Vendors.pending, (state) => {
                state.status = 'loading';
                state.isLoading = true;
                state.error = null;
            })
            .addCase(Call_Get_All_Vendors.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.vendors = action.payload;
                state.error = null;
            })
            .addCase(Call_Get_All_Vendors.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.error = action.error.message;
            })
    },
});

export const state_Call_Fetch_Status = (state) => state.allVendors.status;
export const state_Is_Loading = (state) => state.allVendors.isLoading;
export const state_Vendors = (state) => state.allVendors.vendors;
export const state_Has_Error = (state) => state.allVendors.error;

export default allVendorsDataSlice.reducer;