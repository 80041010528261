import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';


export const Call_Scanned_Cup_Details = createAsyncThunk(
    'scannedCup/Call_Scanned_Cup_Details',
    async (data) => {
        const response = await axios.put(`/api/v1/customer/order/scanned/cup/details/${data.uniqueID}`, data);
        return response.data;
    },
);

const scannedCupSlice = createSlice({
    name: "scannedCup",
    initialState: {
        status: 'idle',
        isLoading: false,
        cupDetails: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            
            // Scanned Cup Details 
            .addCase(Call_Scanned_Cup_Details.pending, (state) => {
                state.status = 'loading';
                state.isLoading = true;
                state.cupDetails = null;
                state.error = null;
            })
            .addCase(Call_Scanned_Cup_Details.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.cupDetails = action.payload;
                state.error = null;
            })
            .addCase(Call_Scanned_Cup_Details.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.cupDetails = false;
                state.error = action.error.message;
            })
    },
});

export const state_Call_Fetch_Status = (state) => state.scannedCup.status;
export const state_Is_Loading = (state) => state.scannedCup.isLoading;
export const state_Cup_Details = (state) => state.scannedCup.cupDetails;
export const state_Has_Error = (state) => state.scannedCup.error;

export default scannedCupSlice.reducer;