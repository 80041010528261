import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';
import Scanner from '../components/NFCScanner/NFCScanner';
import { ActionsContext } from '../context/Context';
import toast from 'react-hot-toast';
import { state_Is_Authenticated } from '../redux/authentication/CustomerAuthSlice';
import { Call_Scanned_Cup_Details } from '../redux/orders/ScannedCupSlice';

const NFCScan = ({ changeFun }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state_Is_Authenticated);
    const [serialNumber, setSerialNumber] = useState();
    const [scanAgain, setScanAgain] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false)
    const nfcScanContext = useContext(ActionsContext);

    const scan = useCallback(async () => {
        setScanAgain(false);
        if ('NDEFReader' in window) {
            try {
                const ndef = new window.NDEFReader();
                await ndef.scan();

                // Scan started successfully
                ndef.onreadingerror = () => {
                    console.log("Cannot read data from the NFC tag. Try another one?");
                };

                ndef.onreading = event => {
                    // NDEF message read
                    // event.preventDefault();
                    onReading(event);
                    nfcScanContext.actionsValue.setActions({
                        scan: 'scanned',
                        write: null
                    });
                };

            } catch (error) {
                toast.error('NFC scanner not found', { duration: 2000 });
                changeFun.checkNFCAvailable();
            };
        }
    }, [nfcScanContext.actionsValue.setActions]);

    const onReading = ({ serialNumber }) => {
        // event.preventDefault()
        setSerialNumber(serialNumber);
        setScanAgain(true);
    };

    const proccedToFetchCupDetails = () => {
        setBtnLoading(true);
        if(!isAuthenticated){
            toast.success(`Please login first`);
            setBtnLoading(false);
            navigate('/signin')
            return
        }
        changeFun.handleCupCallHandler('true');
        const temp = serialNumber.split(":").join("")
        dispatch(Call_Scanned_Cup_Details({uniqueID: temp}))
            .then((data)=>{
                if(data.payload){
                    if(data.payload.success){
                        changeFun.handleCupCallHandler('false');
                        navigate(`/scanned/cup/${temp}`);
                    } else {
                        changeFun.handleCupCallHandler('false');
                        toast.error('No data found')
                    }
                } else {
                    changeFun.handleCupCallHandler('false');
                    toast.error('No data found')
                }
            })
    }

    useEffect(() => {
        if (scanAgain) changeFun.changeBTNmsg('Scanned')
        scan();
    }, [scan, scanAgain]);

    useEffect(()=>{

    })

    return (
        <>
            {nfcScanContext.actionsValue.actions.scan === 'scanned' ?
                <>
                    <div className='w-[90%] h-[12rem] mx-[5%] p-2 my-4 rounded-lg shadow shadow-gray-light gray-light overflow-hidden'>
                        <div className='w-[100%] h-[11rem] float-left flex items-center justify-center'>
                            <div className='w-[70%] mx-[15%]'>
                                <h1 className='text-center text-2xl text-navy font-bold'>Scan Cup Details</h1>
                                <p className='text-sm text-center text-navy py-1'><i className='text-gold'>Cup ID:&nbsp;</i>CUP{serialNumber.split(":").join("")}</p>
                            </div>
                        </div>

                    </div>
                    {serialNumber ?
                        <>
                            <div className='w-[100%] float-left mt-4 h-[8vh] flex items-center justify-center'>
                                {btnLoading ?
                                    <button className='w-max h-max py-2 px-8 text-lg text-text-color-light font-bold rounded-4xl bg-gray'>Proceed</button>
                                    :
                                    <button className='w-max h-max py-2 px-8 text-lg text-text-color-light font-bold rounded-4xl bg-primaryBlue' onClick={proccedToFetchCupDetails} >Procced</button>
                                }

                            </div></>
                        : <></>
                    }
                </>
                :
                <>
                    <Scanner changeFun={changeFun.handleScanCancel}></Scanner>
                </>
            }
        </>

    );
};

export default NFCScan;