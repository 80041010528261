import React from 'react';
import Loader from '../asset/loader.png';

const Chipt_Loader = () => {
  return (
    <div className='w-[100%] h-auto flex items-center justify-center'>
      <img className="animate-spin h-auto w-[30%] lg:w-[10%] ... " viewBox="0 0 24 24" src={Loader} alt="Loading" />
    </div>
  )
}

export default Chipt_Loader;