import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { Call_User_All_Transactions } from '../../redux/transactions/AllTransactionSlice';
import ShowTransactions from './Chipt_Show_Transactions';
import Project_Loader from '../NavigationComponents/Project_Loader';
import { state_Is_Authenticated } from '../../redux/authentication/CustomerAuthSlice';
import { MdErrorOutline } from "react-icons/md";
import { Helmet } from 'react-helmet';


const Chipt_All_Transactions = ({ }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state_Is_Authenticated);
  const [isLoading, setLoading] = useState(false)
  const [allTxn, setAllTxn] = useState([]);

  useEffect(() => {
    if (!isAuthenticated) {
      toast.success(`Please login first`);
      navigate('/signin')
    }
    setLoading(true)
    dispatch(Call_User_All_Transactions())
      .then((data) => {
        if (data.payload) {
          if (data.payload.success) {
            setAllTxn(data.payload.transactions)
          }
        }
        setLoading(false)
      })
  }, [dispatch])

  useEffect(() => { }, [isLoading])

  return (
    <>
      {/* Main Container Start */}
      {isLoading ? <div className='w-[100%] h-screen'><Project_Loader /></div>
        :
        <>
          <div className='w-[100%] lg:w-[90%] lg:mx-[5%] min-h-screen h-auto float-left mb-12'>

            {/* Sub Container Start */}
            <div className='w-[100%] min-h-[82vh] h-auto'>
              <h2 className='text-center text-2xl font-bold my-4'>Transactions</h2>
              <div className='w-[100%] min-h-[75vh] float-left lg:mt-4'>
                {isLoading
                  ? <></> :
                  <>
                    {allTxn.length === 0
                      ? <div className='w-[100%] h-[30rem]'>
                        <p className='flex items-start justify-center mt-[4rem]'><MdErrorOutline className='text-[15rem] text-red' /></p>
                        <p className='text-center mt-8 text-2xl font-bold'>No Data</p>
                      </div>
                      :
                      <>
                        {allTxn.map((data) => {
                          return <ShowTransactions data={data} />
                        })}
                      </>
                    }
                  </>
                }
              </div>
            </div>
            {/* Sub Container End */}

          </div>
        </>
      }

      {/* Main Container End */}
      <Helmet>
        <title>CHIPT | ALL TRANSACTION</title>
      </Helmet>
    </>
  )
}

export default Chipt_All_Transactions