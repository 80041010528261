import React, { useRef, useState, useEffect, useContext } from 'react';
import { useToggle, useVibrate } from 'react-use';
import { ActionsContext } from '../context/Context';
import { BrowserQRCodeReader } from '@zxing/browser';
import { IoQrCode } from "react-icons/io5";
import { LuScanLine } from "react-icons/lu";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { state_Is_Authenticated } from '../redux/authentication/CustomerAuthSlice';
import { Call_Scanned_Cup_Details } from '../redux/orders/ScannedCupSlice';
import Project_Loader from '../components/NavigationComponents/Project_Loader';

const QrScanner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sideNavBar = useContext(ActionsContext);
  const [vibrating, toggleVibrating] = useToggle(false);
  useVibrate(vibrating, [100], false);

  const isAuthenticated = useSelector(state_Is_Authenticated);
  const [btnLoading, setBtnLoading] = useState(false);

  const videoRef = useRef(null);
  const [scanning, setScanning] = useState(false);
  const [code, setCode] = useState(null);
  let barcodeReader = null;

  const startScanning = () => {
    setTimeout(() => { if (sideNavBar.screenSize.current.width < 800) toggleVibrating() }, 0);
    setTimeout(() => {}, 100)
    barcodeReader = new BrowserQRCodeReader();
    barcodeReader
      .decodeOnceFromVideoDevice(undefined, videoRef.current)
      .then(result => {
        setCode(result.text);
      })
      .catch(error => {
        console.error('Barcode scanning error:', error);
      });
    setScanning(true);
  };

  const stopCamera = () => {
    // if (barcodeReader) {
    //   console.log(barcodeReader)
    //   // barcodeReader.stop();
    // }
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      videoRef.current.srcObject = null; // Clear the srcObject to stop the video stream
    }
  };

  const cancelScanning = () => {
    stopCamera(); // Stop camera on cancel
    setScanning(false);
    setCode(null);
    // navigate('/home'); // Navigate to the home route
  };

  const proceedToOrder = () => {
    setBtnLoading(true);
    setTimeout(() => { if (sideNavBar.screenSize.current.width < 800) toggleVibrating() }, 0);
    setTimeout(() => {}, 100)
    if (!isAuthenticated) {
      toast.success(`Please login first`);
      setBtnLoading(false);
      navigate('/signin')
      return
    }
    const temp = code.split(":").join("")
    dispatch(Call_Scanned_Cup_Details({ uniqueID: code.toUpperCase() }))
      .then((data) => {
        if (data.payload) {
          if (data.payload.success) {
            navigate(`/scanned/cup/${temp}`); 
          } else {
            toast.error('No data found');
            setCode(null);
            navigate(`/home`);
          }
        } else {
          toast.error('No data found')
          setCode(null);
          navigate(`/home`);
        }
      })
  };

  useEffect(() => {
    if(code){
      proceedToOrder()
    }
   }, [code, scanning])

  useEffect(() => {
    return () => {
      stopCamera(); // Stop camera when component unmounts
    };
  }, []);

  return (
    <div className='w-[100%] h-screen overflow-auto'>
      {btnLoading ? <div className='w-[100%] h-screen absolute z-50'><Project_Loader/></div>: <></>}
      <div className='w-[100%] h-auto my-8 grid grid-cols-1 place-items-center'>
        <div className='w-[100%] h-auto mt-4 mb-6 flex items-center justify-center'>
          <p className='text-2xl px-2 font-extrabold text-primaryNavy'><LuScanLine /></p>
          <p className='text-2xl font-extrabold text-primaryNavy'>SCAN QR</p>
        </div>
        <div className='w-56 h-56 rounded-lg shadow shrink-gray bg-white overflow-hidden'>
          <div className={`w-56 h-56 absolute z-10 flex items-center justify-center rounded-lg overflow-auto ${scanning || code ? '' : 'bg-white'}`}>
            {scanning || code ? <></> : <><IoQrCode className='text-[12rem] text-gray' /></>}
          </div>
          <video ref={videoRef} className='w-full h-full object-cover' ></video>
        </div>
      </div>
      {scanning && code && (
        <div className='w-[80%] h-auto my-4 p-4 mx-[10%] float-left overflow-auto shadow-sm bg-white'>
          <p className='text-center text-sm text-primaryNavy font-bold'>CUP ID</p>
          <p className='w-[100%] h-[5rem] line-clamp-4 break-all text-center'>{code}</p>
        </div>
      )}

      <div className='w-[50%] sm:w-[100%] md:w-[70%] h-auto p-2 fixed bottom-0 flex items-center justify-center'>
        {!scanning && (
          <button className='px-4 py-1.5 text-xl font-bold text-white bg-primaryBlue hover:bg-primaryBlueHover rounded-md' onClick={startScanning}>Scan</button>
        )}
        {scanning && (
          <button className='px-4 py-1.5 text-xl font-bold text-white bg-primaryBlue hover:bg-primaryBlueHover rounded-md' onClick={cancelScanning}>Cancel</button>
        )}
      </div>
    </div>
  );
};

export default QrScanner;