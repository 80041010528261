import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import OtpInput from 'react-otp-input';
import { Call_Forgot_Password_OTP_Verify } from '../../../redux/authentication/ForgotPasswordSlice';
import toast from 'react-hot-toast';
import Chipt_Security_Footer from '../../NavigationComponents/Chipt_Security_Footer';
import { ActionsContext } from '../../../context/Context';
import Project_Loader from '../../NavigationComponents/Project_Loader';
import { Helmet } from 'react-helmet';

const Chipt_Account_Forgot_OTP_Verification = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [otp, setOtp] = useState('');
    const { state } = useLocation();
    const [callLoader, setCallLoader] = useState(false);
    const chiptAction = useContext(ActionsContext);

    const afterOTPVerification = (e) => {
        e.preventDefault();
        setCallLoader(true);
        chiptAction.loadRef.current.staticStart();
        dispatch(Call_Forgot_Password_OTP_Verify({ email: state.email, otp: otp }))
            .then((data) => {
                if (data.payload) {
                    if (data.payload.success) {
                        toast.success('OTP verified');
                        setCallLoader(false);
                        navigate(`/reset/password`, { state: { email: state.email } });
                        chiptAction.loadRef.current.complete();
                    } else if (!data.payload.success) {
                        toast.error(data.payload.message);
                        chiptAction.loadRef.current.complete();
                        setCallLoader(false);
                    }
                } else if (!data.payload) {
                    toast.success('Something went wrong');
                    chiptAction.loadRef.current.complete();
                    setCallLoader(false);
                }
            })
    }

    useEffect(() => { }, [dispatch, callLoader])

    return (
        <>
            {/* Main Container Start */}
            {callLoader ? <div className='w-[100%] md:-ml-[15%] lg:-ml-[25%] h-screen absolute flex items-center justify-center'><Project_Loader /></div> : <></>}
            <div className='w-[80%] h-auto mx-[10%] overflow-auto'>

                {/* Sub Container start */}
                <div className='w-[100%] min-h-screen h-auto'>

                    {/* Content Start */}
                    <div className='w-[100%] h-auto'>
                        <div className='pt-28 text-center'>
                            <h1 className='font-bold text-2xl'>Enter OTP</h1>
                            <p className='text-sm mt-2'>Check you email for OTP</p>
                        </div>
                        <form onSubmit={afterOTPVerification}>

                            <div className='mt-12 flex item-center justify-center'>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    placeholder={0}
                                    containerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    inputStyle={{ width: "8%", borderBottom: '0.15rem solid #C8C1C1', margin: '0 2%', textAlign: 'center', outline: 'none' }}
                                    renderSeparator={<span></span>}
                                    renderInput={(props) => <input  {...props} />}

                                />
                            </div>
                            <div className='mt-8 flex justify-center'>
                                <p className='text-xs font-medium'>Didn't recieved OTP&nbsp;<Link className='font-bold underline'>Resend</Link></p>
                            </div>
                            <div className='text-center'>
                                {/* <Link to="/reset/password"> */}
                                <button className='bg-primaryBlue hover:bg-primaryBlueHover hover:shadow-md text-text-light text-lg w-[70%] py-2 sm:py-1.5 rounded-2xl font-bold my-4'>Verify</button>
                                {/* </Link> */}
                            </div>
                        </form>

                    </div>
                </div>
                {/* Sub Container End */}

                {/* bottom footer */}
                <Chipt_Security_Footer />
                <Helmet>
                    <title>CHIPT | OTP VERIFICATION</title>
                </Helmet>
            </div>
            {/* Main Container end */}
        </>
    )
}

export default Chipt_Account_Forgot_OTP_Verification;