import React, { useEffect, useContext } from 'react';
import { useToggle, useVibrate } from 'react-use';
import NavHeaderCollapse from './Chipt_Collapse_Nav';
import { useNavigate, useLocation } from "react-router-dom";
import ChiptLogo from '../asset/chipt.svg';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import { ActionsContext } from '../../context/Context';
import { IoMdNotifications, IoMdNotificationsOff } from "react-icons/io";
import { useSelector, useDispatch } from 'react-redux';
import InAppNotification from '../NotificationComponents/InAppNotification';
import toast from 'react-hot-toast';
import { Call_Log_Out, state_Is_Authenticated, state_Has_Error } from '../../redux/authentication/CustomerAuthSlice';
import { Call_Clear_Profile_Image_Data } from '../../redux/Profile/CustomerProfileImageSlice';
import { Call_Clear_Profile_Data } from '../../redux/Profile/CustomerProfileSlice';
import { Call_Clear_Notification_Data } from '../../redux/Profile/CustomerNotificationSlice';
import { state_Is_Active } from '../../redux/authentication/AccountStatusSlice';

const Chipt_Nav_Bar = () => {

    const sideNavBar = useContext(ActionsContext);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state_Is_Authenticated);
    const isAccountActive = useSelector(state_Is_Active);
    const has_Error = useSelector(state_Has_Error);

    const [vibrating, toggleVibrating] = useToggle(false);
    useVibrate(vibrating, [100], false);

    const handleHomeNavigation = (e) => {
        e.preventDefault();
        sideNavBar.setActiveNavBar(false);
        sideNavBar.setNotiNavBar(false);
        setTimeout(() => { if (sideNavBar.screenSize.current.width < 800) toggleVibrating() }, 0);
        setTimeout(() => {
            if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
            navigate('/home')
        }, 100)
    }

    const handleNavigationRender = (e) => {
        e.preventDefault()
        sideNavBar.setActiveNavBar(!sideNavBar.activeNavBar);
        sideNavBar.setNotiNavBar(false);
        setTimeout(() => { if (sideNavBar.screenSize.current.width < 800) toggleVibrating() }, 0);
        setTimeout(() => { if (sideNavBar.screenSize.current.width < 800) toggleVibrating() }, 100)
    }

    const handleNotificationRender = (e) => {
        e.preventDefault()
        if (isAuthenticated) {
            if (typeof isAccountActive === 'boolean' && !isAccountActive) {
                toast.error("Your account is not active yet.", { autoClose: 5000 });
                return
            }
        }
        sideNavBar.setNotiNavBar(!sideNavBar.notiNavBar);
        sideNavBar.setActiveNavBar(false);
        setTimeout(() => { if (sideNavBar.screenSize.current.width < 800) toggleVibrating() }, 0);
        setTimeout(() => { if (sideNavBar.screenSize.current.width < 800) toggleVibrating() }, 100)
    }

    const handleLogout = () => {
        sideNavBar.setActiveNavBar(false)
        if (isAuthenticated === true) {
            dispatch(Call_Log_Out()).then(() => {
                dispatch(Call_Clear_Profile_Image_Data());
                dispatch(Call_Clear_Profile_Data());
                dispatch(Call_Clear_Notification_Data());
                toast.success('You are logout')
                navigate('/home')
            })
        }
    }

    useEffect(() => {
        sideNavBar.setActiveNavBar(false);
    }, [isAuthenticated, has_Error, dispatch]);

    useEffect(() => {
        sideNavBar.setActiveNavBar(false);
        sideNavBar.setNotiNavBar(false);
    }, [location])

    return (
        <>
            {/* Main container start */}
            <div className={`w-[100%] h-auto flex items-center justify-center bg-navBar-cbg`}>

                {/* Sub Container start */}
                <div className='w-[100%] h-auto float-left' >

                    {/* Project logo navigation */}
                    <div className='w-[80%] h-[4rem] sm:h-[3rem] mx-[10%] flex items-center justify-start float-left'>
                        <div className='float-left w-[50%] flex items-center justify-start'>
                            <img className='w-auto h-7 lg:h-8' src={ChiptLogo} alt="Chipt_Logo" onClick={handleHomeNavigation} />
                        </div>

                        <div className='float-right w-[50%] flex items-center justify-end'>
                            <div className='w-[20%] flex items-center justify-end'>
                                {isAuthenticated ?                                 
                                <button className='text-gold hover:text-gold-dark' onClick={handleNotificationRender}>
                                    {sideNavBar.notiNavBar ? <><IoMdNotificationsOff className='text-2xl' /></> : <><IoMdNotifications className='text-2xl' /></>}
                                </button>: <></>}

                            </div>
                            <div className='w-[25%] lg:w-[10%] flex items-center justify-end '>
                                <button onClick={handleNavigationRender}>
                                    {sideNavBar.activeNavBar ? <><AiOutlineMenuFold className='text-2xl' /></> : <><AiOutlineMenuUnfold className='text-2xl' /></>}
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Content Start */}
                    <div className='w-[100%] h-auto float-left'>
                        {sideNavBar.notiNavBar ? <><InAppNotification/></> : <></>}
                    </div>

                    <div className='w-[80%] mx-[10%] h-auto float-left'>
                        {sideNavBar.activeNavBar ? <><NavHeaderCollapse logoutFun={handleLogout} /></> : <></>}
                    </div>
                    {/* Content end */}

                </div>
                {/* Sub Container end */}

            </div>
            {/* Main container end */}

        </>
    )
}

export default Chipt_Nav_Bar;