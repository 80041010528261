import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';


export const Call_Get_Notification = createAsyncThunk(
    'customerNotification/Call_Get_Notification',
    async (data) => {
        const response = data.id 
            ? await axios.get(`/api/v1/customer/account/all/notifications?page=${data.page}&rdnid=${data.id}`) 
            : await axios.get(`/api/v1/customer/account/all/notifications?page=${data.page}`)
        return response.data;
    }
)

export const Call_Clear_Notification_Data = createAsyncThunk(
    'customerNotification/Call_Clear_Notification_Data',
    async () => {
        const response = {
            data: null
        };
        return response.data;
    },
);


const customerNotificationSlice = createSlice({
    name: "customerNotification",
    initialState: {
        status: 'idle',
        isLoading: false,
        nofifications: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder

            // Customer Profile Information
            .addCase(Call_Get_Notification.pending, (state) => {
                state.status = 'idle';
                state.isLoading = true;
                state.nofifications = null;
                state.error = null;
            })
            .addCase(Call_Get_Notification.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.nofifications = action.payload;
                state.error = null;
            })
            .addCase(Call_Get_Notification.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.nofifications = null;
                state.error = action.error.message;
            })

            // Customer Profile Information
            .addCase(Call_Clear_Notification_Data.pending, (state) => {
                state.status = 'idle';
                state.isLoading = true;
                state.nofifications = null;
                state.error = null;
            })
            .addCase(Call_Clear_Notification_Data.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.nofifications = null;
                state.error = null;            
            })
            .addCase(Call_Clear_Notification_Data.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.nofifications = null;
                state.error = null;
            })
    }
});

export const state_Call_Fetch_Status = (state) => state.customerNotification.status;
export const state_Is_Loading = (state) => state.customerNotification.isLoading;
export const state_Customer_Notification = (state) => state.customerNotification.nofifications;
export const state_Has_Error = (state) => state.customerNotification.error;

export default customerNotificationSlice.reducer;