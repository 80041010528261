import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { Call_Forgot_Password_OTP } from '../../../redux/authentication/ForgotPasswordSlice';
import toast from 'react-hot-toast';
import Chipt_Security_Footer from '../../NavigationComponents/Chipt_Security_Footer';
import { ActionsContext } from '../../../context/Context';
import Project_Loader from '../../NavigationComponents/Project_Loader';
import { Helmet } from 'react-helmet';

const Chipt_Account_Forgot_Password_OTP = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [email, setEmail] = useState();
    const chiptAction = useContext(ActionsContext);
    const [callLoader, setCallLoader] = useState(false);

    const onEmailSubmit = (e) => {
        e.preventDefault();
        setCallLoader(true);
        chiptAction.loadRef.current.staticStart();
        dispatch(Call_Forgot_Password_OTP(email))
            .then((data) => {
                if (data.payload) {
                    if (!data.payload.success) {
                        toast.error(data.payload.message);
                        chiptAction.loadRef.current.complete();
                        setCallLoader(false);
                    } else if (data.payload.success) {
                        toast.success(data.payload.message);
                        chiptAction.loadRef.current.complete();
                        setCallLoader(false);
                        navigate(`/forgot/otp/verify`, { state: { email: email } });
                    }
                } else {
                    toast('Somethin went wrong');
                    chiptAction.loadRef.current.complete();
                    setCallLoader(false);
                }
            })
    }

    useEffect(() => { }, [dispatch, callLoader])

    return (
        <>
            {/* Main Container Start */}
            {callLoader ? <div className='w-[100%] md:-ml-[15%] lg:-ml-[25%] h-screen absolute flex items-center justify-center'><Project_Loader /></div> : <></>}
            <div className='w-[90%] h-auto mx-[5%] overflow-auto'>

                {/* Sub Container start */}
                <div className='w-[100%] h-auto'>

                    {/* Content Start */}
                    <div className='w-[90%] h-auto mx-[5%]'>
                        <div className='w-[100%] my-16 text-center'>
                            <h1 className='font-bold text-2xl'>Forgot Password!</h1>
                            <p className='text-sm'>Please provide your email address!</p>
                        </div>
                        <form onSubmit={onEmailSubmit}>
                            <div className={`${callLoader ? '' : 'relative'} rounded-lg mt-4 sm:mt-16 mb-1`}>
                                <input type="email" id="email" name='email'
                                    onChange={(e) => setEmail(e.target.value)} value={email ? email : ''}
                                    className="block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer hover:outline-primaryBlue" placeholder="" autoComplete='true' />
                                <label htmlFor="email" className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Email</label>
                            </div>

                            <div className='w-[100%] py-4 text-center'>
                                <button className='bg-primaryBlue hover:bg-primaryBlue-hover hover:shadow-md w-[100%] h-auto text-xl text-white py-2 my-4 text-center rounded-4xl font-bold'>Send</button>
                            </div>

                            <div className='flex items-center justify-center mb-40'>
                                <div className='block'>
                                    <p className='text-xs text-center'>Don't have account?&nbsp;<Link className='underline font-bold text-sm hover:text-blue' to='/signup'>Sign Up</Link></p>
                                    <p className='text-xs mt-1 text-center'>Already have an account?&nbsp;<Link className='underline font-bold text-sm hover:text-blue' to='/signin'>Sign In</Link></p>
                                </div>
                            </div>


                        </form>
                    </div>
                    {/* Content Start */}
                </div>
                {/* Sub Container End */}

                {/* bottom footer */}
                <Chipt_Security_Footer />

            </div>
            {/* Main Container end */}
            <Helmet>
                <title>CHIPT | FORGOT PASSWORD</title>
            </Helmet>
        </>
    )
}

export default Chipt_Account_Forgot_Password_OTP;