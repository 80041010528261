import React, { useState, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import NFCChip from '../asset/nfcChip.svg';
import NFCChipHover from '../asset/nfcChipHover.svg'
import { GoDotFill } from 'react-icons/go';
import { useToggle, useVibrate } from 'react-use';
import { ActionsContext } from '../../context/Context';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import { Helmet } from 'react-helmet';

const Chipt_NFC_Scan_Guide = () => {

  const sideNavBar = useContext(ActionsContext);

  const [showImage, setShowImage] = useState(NFCChip);
  const [displayText, setDisplayText] = useState('Tap your cup here!')
  const navigate = useNavigate();

  const [vibrating, toggleVibrating] = useToggle(false);
  useVibrate(vibrating, [500], false);

  const changeImageOnClick = (e) => {
    e.preventDefault();

    if (sideNavBar.screenSize.current.width < 800) {
      setTimeout(() => {
        toggleVibrating();
        setDisplayText('Cup scan successfully!');
        setShowImage(NFCChipHover);
      }, 0);

      setTimeout(() => {
        toggleVibrating();
        setShowImage(NFCChip)
        navigate('/payment/guide')
      }, 300)
    } else {
      setTimeout(() => {
        setDisplayText('Cup scan successfully!');
        setShowImage(NFCChipHover);
      }, 0);
      setTimeout(() => {
        setShowImage(NFCChip)
        navigate('/payment/guide')
      }, 300)
    }
  }

  return (
    <>
          <Helmet>
        <title>CHIPT | NFC SCAN</title>
      </Helmet>
      {/* Main container start */}
      <div className='w-[100%] h-screen'>

        {/* Sub Container start */}
        <div className='w-[10%] h-[43rem] text-xs float-left flex items-center justify-center animate-ping' onClick={() => navigate('/cup/scan')}><MdOutlineArrowBackIos /></div>
        <div className='w-[80%] h-[43rem] float-left' >

          {/* navigation start */}
          <div className='w-[100%] h-[3rem] flex'>
            <div className='w-[50%] h-[3rem] flex items-center justify-start'>
              <Link to='/cup/scan'><button className='float-left bg-gray-light rounded-xl text-xs font-bold px-2 py-1'>BACK</button></Link>
            </div>
            <div className='w-[50%] h-[3rem] flex items-center justify-end'>
              <Link to="/payment/guide"><button className='float-right bg-gray-light rounded-xl text-xs font-bold px-2 py-1' >NEXT</button></Link>
            </div>
          </div>
          {/* navigation end */}

          {/* Content start */}
          <div className='w-[100%] h-[30rem] text-center'>
            <div className='w-[100%] h-[28rem] flex items-center justify-center overflow-hidden animate-pulse'>
              <img className='w-auto h-[26rem] lg:h-[25rem] mt-[8rem]' src={showImage} onClick={changeImageOnClick} alt="Chipt_Logo" />
            </div>

            <h1 className='font-bold text-xl'>{displayText}</h1>
          </div>
          {/* Content end */}
        </div>
        <div className='w-[10%] h-[43rem] text-xs float-left flex items-center justify-center animate-ping' onClick={() => navigate('/payment/guide')}><MdOutlineArrowForwardIos /></div>
        {/* Sub Container end */}

        {/* bottom footer */}
        <div className='w-[80%] h-[2rem] mx-[10%] float-left text-center'>
          <div className='flex justify-center text-xl'>
            <p className='text-gray-light'><GoDotFill /></p>
            <p><GoDotFill /></p>
            <p className='text-gray-light'><GoDotFill /></p>
          </div>
        </div>

      </div>
      {/* Main Container End */}
    </>
  )
}

export default Chipt_NFC_Scan_Guide;