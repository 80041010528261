import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';


export const Call_Sign_Up = createAsyncThunk(
    'customerAuth/Call_Sign_Up',
    async (data) => {
        const response = await axios.post(`/api/v1/customer/account/signup`, data);
        return response.data;
    },
);

export const Call_Sign_In = createAsyncThunk(
    'customerAuth/Call_Sign_In',
    async (data) => {
        const response = await axios.post(`/api/v1/customer/account/signin`, data);
        return response.data;
    },
);

export const Call_Log_Out = createAsyncThunk(
    'customerAuth/Call_Log_Out',
    async () => {
        const response = await axios.get(`/api/v1/customer/account/signout`);
        return response.data;
    },
);

export const Call_Password_Change = createAsyncThunk(
    'customerAuth/Call_Password_Change',
    async (data) => {
        const response = await axios.put(`/api/v1/customer/account/password/change`, data);
        return response.data;
    },
);

const customerAuthInSlice = createSlice({
    name: "customerAuth",
    initialState: {
        status: 'idle',
        isLoading: false,
        customer: null,
        isAuthenticated: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Sign Up
            .addCase(Call_Sign_Up.pending, (state) => {
                state.status = 'loading';
                state.isLoading = true;
                state.customer = null;
                state.isAuthenticated = false;
                state.error = null;
            })
            .addCase(Call_Sign_Up.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.customer = action.payload;
                state.isAuthenticated = true;
                state.error = null;
            })
            .addCase(Call_Sign_Up.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.customer = null;
                state.isAuthenticated = false;
                state.error = action.error;
            })

            // Sign In
            .addCase(Call_Sign_In.pending, (state) => {
                state.status = 'loading';
                state.isLoading = true;
                state.customer = null;
                state.isAuthenticated = false;
                state.error = null;
            })
            .addCase(Call_Sign_In.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.customer = action.payload;
                state.isAuthenticated = true;
                state.error = null;
            })
            .addCase(Call_Sign_In.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.customer = null;
                state.isAuthenticated = false;
                state.error = action.error.message;
            })

            // Sign out
            .addCase(Call_Log_Out.pending, (state) => {
                state.status = 'loading';
                state.isLoading = true;
                state.customer = null;
                state.isAuthenticated = true;
                state.error = null;
            })
            .addCase(Call_Log_Out.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.customer = null;
                state.isAuthenticated = false;
                state.error = null;
            })
            .addCase(Call_Log_Out.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.customer = null;
                state.isAuthenticated = false;
                state.error = action.error.message;
            })

            // Password change
            .addCase(Call_Password_Change.pending, (state) => {
                state.status = 'loading';
                state.isLoading = true;
                state.customer = null;
                state.isAuthenticated = true;
                state.error = null;
            })
            .addCase(Call_Password_Change.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.customer = action.payload;
                state.isAuthenticated = true;
                state.error = null;
            })
            .addCase(Call_Password_Change.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.customer = null;
                state.isAuthenticated = true;
                state.error = action.error.message;
            })
    },
});

export const state_Call_Fetch_Status = (state) => state.customerAuth.status;
export const state_Is_Loading = (state) => state.customerAuth.isLoading;
export const state_Is_Authenticated = (state) => state.customerAuth.isAuthenticated;
export const state_Customer = (state) => state.customerAuth.customer;
export const state_Has_Error = (state) => state.customerAuth.error;

export default customerAuthInSlice.reducer;