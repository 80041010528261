import React, { useState, useEffect, useContext } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useToggle, useVibrate } from "react-use";
import { useNavigate } from "react-router-dom";
import { ActionsContext } from "../../context/Context";
import { useDispatch, useSelector } from "react-redux";
// React Icons
import toast from "react-hot-toast";
import { RiHome7Line } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { IoIosArrowForward } from "react-icons/io";
import { TfiShoppingCartFull } from "react-icons/tfi";
import { RiLockPasswordLine } from "react-icons/ri";
import { TbMug } from "react-icons/tb";

// All Fetch API
import { state_Is_Authenticated } from "../../redux/authentication/CustomerAuthSlice";
import { state_Is_Active } from "../../redux/authentication/AccountStatusSlice";
import {
  Call_Get_Profile_Image,
  state_Customer_Profile_Image,
  state_Is_Loading,
} from "../../redux/Profile/CustomerProfileImageSlice";
import { Call_Customer_All_Cups_Bought } from "../../redux/orders/CustomerAllOrderedCups";
import { Helmet } from "react-helmet";
import {
  Call_Get_Profile,
  state_Customer_Profile,
} from "../../redux/Profile/CustomerProfileSlice";
import axios from "axios";

const Chipt_Collapse_Nav = ({ logoutFun }) => {
  const sideNavBar = useContext(ActionsContext);
  const [customer, setCustomer] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [customerImage, setCustomerImage] = useState();
  const is_Authenticated = useSelector(state_Is_Authenticated);
  const isActive = useSelector(state_Is_Active);
  const isLoading = useSelector(state_Is_Loading);
  const customerProfileImage = useSelector(state_Customer_Profile_Image);

  const [vibrating, toggleVibrating] = useToggle(false);
  useVibrate(vibrating, [100], false);

  const changeOnNaigation = (e, navTo) => {
    e.preventDefault();
    switch (navTo) {
      case "profile":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          if (is_Authenticated) navigate("/profile");
          else toast("Please login!");
        }, 100);
        break;
      case "home":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/home");
        }, 100);
        break;

      case "profileupdate":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/profile/update");
        }, 100);
        break;

      case "passwordchange":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/password/change");
        }, 100);
        break;

      case "allorderedcups":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        dispatch(Call_Customer_All_Cups_Bought());
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/all/ordered/cups");
        }, 100);
        break;

      case "allorders":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/all/orders");
        }, 100);
        break;

      case "alltransactions":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/all/transactions");
        }, 100);
        break;

      case "termcondition":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/termcondition");
        }, 100);
        break;

      case "privacypolicy":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/privacypolicy");
        }, 100);
        break;

      case "refundpolicy":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/refundpolicy");
        }, 100);
        break;

      case "signup":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/signup");
        }, 100);
        break;

      case "signin":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/signin");
        }, 100);
        break;

      default:
        console.log("Invalid navigation");
    }
  };

  useEffect(() => {
    if (is_Authenticated) {
      if (customerProfileImage) {
        if (customerProfileImage.data) {
          setCustomerImage(customerProfileImage.data.customer);
        } else setCustomerImage(customerProfileImage.customer);
      } else if (!customerProfileImage) {
        dispatch(Call_Get_Profile_Image()).then((data) => {
          if (data.payload) {
            setCustomerImage(data.payload.customer);
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    dispatch(Call_Get_Profile()).then((data) => {
      if (data.payload) {
        setCustomer(data.payload.customer);
      }
    });
  }, []);

  useEffect(() => {}, [is_Authenticated, isLoading]);

  const gotToWallet = async () => {
    navigate("/wallet");
  };

  return (
    <>
      {/* Main container start */}
      <div className="w-[100%] absolute min-h-screen h-auto -ml-[10%] bg-navBar-cbg">
        <OutsideClickHandler
          onOutsideClick={() => sideNavBar.setActiveNavBar(false)}
        >
          {/* Sub Container start */}
          <div className="w-[100%] lg:w-[60%] lg:mx-[20%] min-h-mx h-auto shadow-gray">
            <div className="min-h-mx lg:w-[100%] h-auto flex items-center justify-center">
              <div className="w-[100%] h-auto text-center">
                {/* Profile Show */}
                <div className="w-[100%] h-auto rounded-t-2xl">
                  <div
                    className="w-[100%] h-[8rem] lg:h-[10rem] flex items-end justify-center float-left rounded-b-[50%] bg-navBar-top-cbg"
                    onClick={(e) => changeOnNaigation(e, "profile")}
                  >
                    {is_Authenticated && isActive ? (
                      <img
                        className="w-[6rem] lg:w-[8rem] h-[6rem] lg:h-[8rem] outline outline-offset-4 outline-primaryBlue rounded-full"
                        src={
                          customerImage
                            ? customerImage.profilePicture?.url
                            : "http://res.cloudinary.com/dva7hs5oo/image/upload/v1697284398/default/rnbfoapzh3yn5pg8mknh.png"
                        }
                        alt="user"
                      />
                    ) : (
                      <img
                        className="w-[6rem] lg:w-[8rem] h-[6rem] lg:h-[8rem] outline outline-offset-4 outline-primaryBlue rounded-full"
                        src={
                          "http://res.cloudinary.com/dva7hs5oo/image/upload/v1697284398/default/rnbfoapzh3yn5pg8mknh.png"
                        }
                        alt="user"
                      />
                    )}
                  </div>
                  <div className="w-[100%] h-[3rem] float-left flex items-center justify-center">
                    {is_Authenticated && isActive ? (
                      <div className="h-[8vh] flex items-center justify-start pl-2 text-navy">
                        <p className="text-lg font-bold py-1">
                          {customerImage
                            ? customerImage.username
                              ? customerImage.username
                              : customerImage.contact
                            : "User name"}
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                {/* Menu Table */}

                <div className="w-[100%] h-auto overflow-auto float-left flex items-center justify-center">
                  <div className="w-[90%] h-auto px-2 py-4 lg:py-6 bg-white shadow-sm shadow-gray my-4 rounded-xl text-[1rem] lg:text-md">
                    <div
                      className="w-[94%] h-auto py-2 mx-[3%] font-semibold flex items-center justify-start float-left"
                      onClick={(e) => changeOnNaigation(e, "home")}
                    >
                      <p className="w-[15%] h-full text-2xl flex items-center justify-center">
                        <RiHome7Line />
                      </p>
                      <p className="w-[75%] h-full pl-2 text-left">Home</p>
                      <p className="w-[10%] h-full text-xl flex items-center justify-center">
                        <IoIosArrowForward />
                      </p>
                    </div>
                    {is_Authenticated && isActive ? (
                      <>
                        <div
                          className="w-[94%] h-auto py-2 mx-[3%] font-semibold flex items-center justify-start float-left"
                          onClick={(e) => changeOnNaigation(e, "profileupdate")}
                        >
                          <p className="w-[15%] h-full text-2xl flex items-center justify-center">
                            <FaRegEdit />
                          </p>
                          <p className="w-[75%] h-full pl-2 text-left">
                            Edit Profile
                          </p>
                          <p className="w-[10%] h-full text-xl flex items-center justify-center">
                            <IoIosArrowForward />
                          </p>
                        </div>

                        <div
                          className="w-[94%] h-auto py-2 mx-[3%] font-semibold flex items-center justify-start float-left"
                          onClick={(e) =>
                            changeOnNaigation(e, "passwordchange")
                          }
                        >
                          <p className="w-[15%] h-full text-2xl flex items-center justify-center">
                            <RiLockPasswordLine />
                          </p>
                          <p className="w-[75%] h-full pl-2 text-left">
                            Change Password
                          </p>
                          <p className="w-[10%] h-full text-xl flex items-center justify-center">
                            <IoIosArrowForward />
                          </p>
                        </div>

                        <div
                          className="w-[94%] h-auto py-2 mx-[3%] font-semibold flex items-center justify-start float-left"
                          onClick={(e) =>
                            changeOnNaigation(e, "allorderedcups")
                          }
                        >
                          <p className="w-[15%] h-full text-2xl flex items-center justify-center">
                            <TbMug />
                          </p>
                          <p className="w-[75%] h-full pl-2 text-left">
                            Ordered Cups
                          </p>
                          <p className="w-[10%] h-full text-xl flex items-center justify-center">
                            <IoIosArrowForward />
                          </p>
                        </div>

                        <div
                          className="w-[94%] h-auto py-2 mx-[3%] font-semibold flex items-center justify-start float-left"
                          onClick={(e) => changeOnNaigation(e, "allorders")}
                        >
                          <p className="w-[15%] h-full text-2xl flex items-center justify-center">
                            <TfiShoppingCartFull />
                          </p>
                          <p className="w-[75%] h-full pl-2 text-left">
                            Orders
                          </p>
                          <p className="w-[10%] h-full text-xl flex items-center justify-center">
                            <IoIosArrowForward />
                          </p>
                        </div>

                        <div
                          className="w-[94%] h-auto py-2 mx-[3%] font-semibold flex items-center justify-start float-left"
                          onClick={(e) =>
                            changeOnNaigation(e, "alltransactions")
                          }
                        >
                          <p className="w-[15%] h-full text-2xl flex items-center justify-center">
                            <LiaMoneyBillWaveSolid />
                          </p>
                          <p className="w-[75%] h-full pl-2 text-left">
                            Transactions
                          </p>
                          <p className="w-[10%] h-full text-xl flex items-center justify-center">
                            <IoIosArrowForward />
                          </p>
                        </div>

                        <div
                          onClick={() => {
                            gotToWallet();
                          }}
                          className="cursor-pointer w-[94%] h-auto py-2 mx-[3%] font-semibold flex items-center justify-start float-left"
                        >
                          <p className="w-[15%] h-full text-2xl flex items-center justify-center">
                            <LiaMoneyBillWaveSolid />
                          </p>
                          <p className="w-[75%] h-full pl-2 text-left">
                            Wallet
                          </p>
                          <p className="w-[10%] h-full text-xl flex items-center justify-center">
                            <IoIosArrowForward />
                          </p>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div
                      className="w-[94%] h-auto py-2 mx-[3%] font-semibold flex items-center justify-start float-left"
                      onClick={(e) => changeOnNaigation(e, "termcondition")}
                    >
                      <p className="w-[15%] h-full text-2xl flex items-center justify-center">
                        <HiOutlineClipboardDocumentList />
                      </p>
                      <p className="w-[75%] h-full pl-2 text-left">
                        Terms & Condition
                      </p>
                      <p className="w-[10%] h-full text-xl flex items-center justify-center">
                        <IoIosArrowForward />
                      </p>
                    </div>

                    <div
                      className="w-[94%] h-auto py-2 mx-[3%] font-semibold flex items-center justify-start float-left"
                      onClick={(e) => changeOnNaigation(e, "privacypolicy")}
                    >
                      <p className="w-[15%] h-full text-2xl flex items-center justify-center">
                        <HiOutlineClipboardDocumentList />
                      </p>
                      <p className="w-[75%] h-full pl-2 text-left">
                        Privacy Policy
                      </p>
                      <p className="w-[10%] h-full text-xl flex items-center justify-center">
                        <IoIosArrowForward />
                      </p>
                    </div>

                    <div
                      className="w-[94%] h-auto py-2 mx-[3%] font-semibold flex items-center justify-start float-left"
                      onClick={(e) => changeOnNaigation(e, "refundpolicy")}
                    >
                      <p className="w-[15%] h-full text-2xl flex items-center justify-center">
                        <HiOutlineClipboardDocumentList />
                      </p>
                      <p className="w-[75%] h-full pl-2 text-left">
                        Refund Policy
                      </p>
                      <p className="w-[10%] h-full text-xl flex items-center justify-center">
                        <IoIosArrowForward />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-[94%] h-auto mx-[3%] pt-2 pb-8 mb-2 float-left flex items-center justify-center rounded-b-2xl">
                  {is_Authenticated ? (
                    <>
                      <button
                        className="w-[40%] py-2.5 bg-primaryBlue hover:bg-primaryBlue-hover text-white font-bold rounded-4xl"
                        onClick={() => logoutFun()}
                      >
                        Logout
                      </button>
                    </>
                  ) : (
                    <>
                      <p
                        className="w-[40%] py-2.5 bg-primaryBlue hover:bg-primaryBlueHover text-white font-bold rounded-4xl mt-[10rem] lg:mt-[1rem] mx-2"
                        onClick={(e) => changeOnNaigation(e, "signup")}
                      >
                        Sign Up
                      </p>
                      <p
                        className="w-[40%] py-2.5 bg-primaryBlue hover:bg-primaryBlueHover text-white font-bold rounded-4xl mt-[10rem] lg:mt-[1rem] mx-2"
                        onClick={(e) => changeOnNaigation(e, "signin")}
                      >
                        Sign In
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Sub Container end */}
        </OutsideClickHandler>
      </div>
      {/* Main container end */}
      <Helmet>
        <title>CHIPT | NAVIGATION</title>
      </Helmet>
    </>
  );
};

export default Chipt_Collapse_Nav;
