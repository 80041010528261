import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';


export const Call_Get_Profile = createAsyncThunk(
    'customer/Call_Get_Profile',
    async () => {
        const response = await axios.get(`/api/v1/customer/account/information`);
        return response.data;
    }
)

export const Call_Update_Profile_Contact = createAsyncThunk(
    'customer/Call_Update_Profile_Contact',
    async (data) => {
        const response = await axios.put(`/api/v1/customer/account/contact/update`, data);
        return response;
    }
)

export const Call_Update_Profile_Basic_Information = createAsyncThunk(
    'customer/Call_Update_Profile_Basic_Information',
    async (data) => {
        const response = await axios.put(`/api/v1/customer/account/basic/info/update`, data);
        return response;
    }
)

export const Call_Update_Profile_Address = createAsyncThunk(
    'customer/Call_Update_Profile_Address',
    async (data) => {
        const response = await axios.put(`/api/v1/customer/account/address/update`, data);
        return response;
    }
)

export const Call_Update_Profile_Location = createAsyncThunk(
    'customer/Call_Update_Profile_Location',
    async (data) => {
        const response = await axios.put(``, data);
        return response;
    }
)

export const Call_Clear_Profile_Data = createAsyncThunk(
    'customer/Call_Clear_Profile_Data',
    async () => {
        const response = {
            data: null
        };
        return response.data;
    },
);


const customerProfileSlice = createSlice({
    name: "customer",
    initialState: {
        status: 'idle',
        isLoading: false,
        profileData: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        
        builder

            // Customer Profile Information
            .addCase(Call_Get_Profile.pending, (state) => {
                state.status = 'idle';
                state.isLoading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(Call_Get_Profile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(Call_Get_Profile.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.customer = null;
                state.error = action.error.message;
            })

            // Customer Contacts Update
            .addCase(Call_Update_Profile_Contact.pending, (state) => {
                state.status = 'idle';
                state.isLoading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(Call_Update_Profile_Contact.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(Call_Update_Profile_Contact.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.customer = null;
                state.error = action.error.message;
            })

            // Customer Basic Details Update
            .addCase(Call_Update_Profile_Basic_Information.pending, (state) => {
                state.status = 'idle';
                state.isLoading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(Call_Update_Profile_Basic_Information.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(Call_Update_Profile_Basic_Information.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.customer = null;
                state.error = action.error.message;
            })

            // Customer Address Update
            .addCase(Call_Update_Profile_Address.pending, (state) => {
                state.status = 'idle';
                state.isLoading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(Call_Update_Profile_Address.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(Call_Update_Profile_Address.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.customer = null;
                state.error = action.error.message;
            })

            // Customer Location Update
            .addCase(Call_Update_Profile_Location.pending, (state) => {
                state.status = 'idle';
                state.isLoading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(Call_Update_Profile_Location.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(Call_Update_Profile_Location.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.customer = null;
                state.error = action.error.message;
            })

            // Clear State
            .addCase(Call_Clear_Profile_Data.pending, (state) => {
                state.status = 'idle';
                state.isLoading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(Call_Clear_Profile_Data.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.customer = null;
                state.error = null;
            })
            .addCase(Call_Clear_Profile_Data.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.customer = null;
                state.error = null;
            })
    }
});

export const state_Call_Fetch_Status = (state) => state.customer.status;
export const state_Is_Loading = (state) => state.customer.isLoading;
export const state_Customer_Profile = (state) => state.customer.customer;
export const state_Has_Error = (state) => state.customer.error;

export default customerProfileSlice.reducer;