import React, { useEffect, useState } from "react";
import Cupsvg from "./Cupsvg";
import axios from "axios";
import styles from "./Wallet.module.css";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Call_Get_Profile } from "../../redux/Profile/CustomerProfileSlice";

const packages = [
  {
    id: 1,
    name: "Basic Package",
    description:
      "Ideal for beginners. Get started with a few cups and credits.",
    cups: 10,
    credits: 5,
    photo: "https://via.placeholder.com/150",
  },
  {
    id: 2,
    name: "Standard Package",
    description: "Perfect for regular users. More cups and credits.",
    cups: 20,
    credits: 15,
    photo: "https://via.placeholder.com/150",
  },
  {
    id: 3,
    name: "Premium Package",
    description: "Best value for frequent users. Maximum cups and credits.",
    cups: 50,
    credits: 30,
    photo: "https://via.placeholder.com/150",
  },
  {
    id: 3,
    name: "Premium Package",
    description: "Best value for frequent users. Maximum cups and credits.",
    cups: 50,
    credits: 30,
    photo: "https://via.placeholder.com/150",
  },
  {
    id: 3,
    name: "Premium Package",
    description: "Best value for frequent users. Maximum cups and credits.",
    cups: 50,
    credits: 30,
    photo: "https://via.placeholder.com/150",
  },
  {
    id: 3,
    name: "Premium Package",
    description: "Best value for frequent users. Maximum cups and credits.",
    cups: 50,
    credits: 30,
    photo: "https://via.placeholder.com/150",
  },
];

const PackagesMain = () => {
  const dispatch = useDispatch();
  const [packages, setPackages] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(Call_Get_Profile()).then((data) => {
      if (data.payload) {
        setCustomer(data.payload.customer);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get("/api/v1/customer/package");
        if (response.data && response.data.success) {
          setPackages(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };

    fetchPackages();
  }, []);

  useEffect(() => {
    if (customer) {
      const fetchWalletData = async () => {
        try {
          setIsLoading(true);
          const response = await axios.get(
            `/api/v1/customer/wallet/${customer._id}`
          );

          console.log(response.data);
          if (response.data && response.data.data) {
            setWallet(response.data.data);
          }
        } catch (error) {
          console.error("Error fetching wallet data:", error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchWalletData();
    }
  }, [customer]);

  const handleBuyNow = async (packageId) => {
    if (!customer) {
      console.error("Customer information not loaded");
      return;
    }

    if (!wallet || !wallet.isWalletActive) {
      toast.error("Activate the wallet first");
      return;
    }

    try {
      const response = await axios.post("/api/v1/customer/package/buy", {
        packageId,
        customerId: customer._id,
      });

      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      console.error("Error buying package:", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-3xl font-semibold mb-8">Our Packages</h1>
      <div
        className={`overflow-x-scroll w-full flex ${styles.customScrollbar}`}
      >
        {packages.map((pkg) => (
          <div
            key={pkg.id}
            className="bg-white mr-2 p-6 rounded-lg shadow-lg border border-gray-200  text-center w-72 flex-shrink-0 flex flex-col"
          >
            <div className="flex justify-center items-center mb-4">
              <Cupsvg />
            </div>
            <h2 className="text-2xl font-semibold mb-2 truncate">{pkg.name}</h2>
            <div className="mt-auto">
              <p className="text-gray-700 mb-4">{pkg.description}</p>
              <p className="text-gray-700 mb-2">
                <strong>Cups:</strong> {pkg.numberOfCups}
              </p>
              <p className="text-gray-700 mb-2">
                <strong>Free Credits</strong> {pkg.freeCupCredits}
              </p>

              <p className="text-gray-700 mb-4">
                <strong>Credits:</strong> {pkg.totalCredits}
              </p>
            </div>
            <button
              onClick={() => handleBuyNow(pkg._id)}
              className="px-6 py-2 bg-gray-dark text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 mt-auto"
            >
              Buy Now
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PackagesMain;
