import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Compressor from 'compressorjs';
import toast from 'react-hot-toast';
import { FiEdit } from 'react-icons/fi';
import { ImCross } from 'react-icons/im';
import ChiptDataLoader from '../../NavigationComponents/Chipt_Data_Loader';
import { Call_Get_Profile_Image, Call_Update_Profile_Image, state_Customer_Profile_Image, state_Is_Loading } from '../../../redux/Profile/CustomerProfileImageSlice';
import { Helmet } from 'react-helmet';

const Chipt_Profile_Account_Image_Update = () => {

    const dispatch = useDispatch();
    const customerProfileImage = useSelector(state_Customer_Profile_Image);
    const isLoading = useSelector(state_Is_Loading);

    const [editProfile, setEditProfile] = useState(false);
    const [loading, setLoading] = useState(false);
    const [customerData, setCustomerData] = useState()
    const [uploadImage, setUploadImage] = useState(null)
    const [tempProfile, setTempProfile] = useState('http://res.cloudinary.com/dva7hs5oo/image/upload/v1697284398/default/rnbfoapzh3yn5pg8mknh.png')

    const ProfileEditButtonEnable = (e) => {
        e.preventDefault();
        if (editProfile) setUploadImage(null)
        setEditProfile(!editProfile);
    }

    const handlepreviewProfile = async (e) => {
        e.preventDefault();
        if (e.target.files[0]) {
            const compressedBlob = await new Promise((resolve, reject) => {
                new Compressor(e.target.files[0], {
                    quality: 0.65, // Adjust the desired image quality (0.0 - 1.0)
                    maxWidth: 800, // Adjust the maximum width of the compressed image
                    maxHeight: 800, // Adjust the maximum height of the compressed image
                    mimeType: "image/jpeg", // Specify the output image format
                    success(result) {
                        resolve(result);
                    },
                    error(error) {
                        reject(error);
                    },
                });
            });
            let file = compressedBlob
            const formData = new FormData();
            formData.append('file', file);
            setUploadImage(formData);
            setTempProfile(URL.createObjectURL(compressedBlob))
        }
    }

    const handleProfileUpdate = (e) => {
        e.preventDefault();
        setLoading(true);
        if (uploadImage) {
            dispatch(Call_Update_Profile_Image(uploadImage))
                .then((data) => {
                    if (data.payload) {
                        if (data.payload.data.success) {
                            setLoading(false);
                            setUploadImage(null);
                            setEditProfile(false);
                            setCustomerData(data.payload.data.customer)
                            toast.success(`Profile picture updated successfully!`, { duration: 2500 })
                        } else {
                            setLoading(false);
                            toast.success(data.payload.data.message, { duration: 2500 })
                        }
                    }
                })
        }
        else {
            toast.error('Please select image.');
            setLoading(false);
        }

    }

    useEffect(() => {
        if (customerProfileImage) {
            if (customerProfileImage.data) {
                setCustomerData(customerProfileImage.data.customer);
            } else setCustomerData(customerProfileImage.customer);
        } else if (!customerProfileImage) {
            dispatch(Call_Get_Profile_Image())
        }
    }, [])
    
    useEffect(() => {
    }, [isLoading, dispatch, tempProfile])

    return (
        <div className='w-[98%] h-auto m-[1%] shadow shadow-gray-light overflow-auto '>
            {/* Profile Image */}
            <div className='w-[34%] h-[8rem] lg:h-[10rem] float-left flex items-center justify-center'>
                <div className='w-[100%] h-[8rem] lg:h-[10rem] flex items-center justify-center float-left overflow-hidden '>
                    <div className='w-[5.5rem] lg:w-[8rem] h-[5.5rem] lg:h-[8rem] flex items-center justify-center rounded-full outline outline-primaryBlue outline-offset-2 overflow-hidden'>
                        {isLoading
                            ? <img className='w-[5.5rem] lg:w-[8rem] h-[5.5rem] lg:h-[8rem] rounded-full blur-sm ' src={tempProfile} alt='profile_image' />
                            :
                            <>
                                {loading
                                    ? <img className='w-[5.5rem] lg:w-[8rem] h-[5.5rem] lg:h-[8rem] rounded-full blur-sm' src={!uploadImage ? customerData?.profilePicture?.url : tempProfile} alt='profile_image' />
                                    : <img className='w-[5.5rem] lg:w-[8rem] h-[5.5rem] lg:h-[8rem] rounded-full' src={!uploadImage ? customerData?.profilePicture?.url : tempProfile} alt='profile_image' />
                                }
                            </>}
                    </div>
                </div>
            </div>


            <div className='w-[66%] h-[8rem] lg:h-[10rem] float-left flex items-center justify-center'>
                {loading ?
                    <div className='w-[100%] h-auto float-left flex items-center justify-start'>
                        <span className='w-[30%] float-left text-xs fount-bold text-navy'>Uploading</span>
                        <span><ChiptDataLoader className='w-[70%] float-left' /></span>
                    </div>
                    :
                    <>
                        {/* Customer Name */}
                        < div className='w-[100%] h-auto float-left flex items-center justify-center'>
                            <div className='w-[85%] float-left'>
                                {editProfile ?
                                    <>
                                        <div className='w-[90%] h-auto my-1 flex items-end justify-start'>
                                            <input id='imageUpload' className='text-[0.75rem] h-auto' type='file' name='myImage' accept="image/png, image/jpeg" placeholder='Upload' onChange={handlepreviewProfile} autoComplete='off' />
                                        </div>
                                        <div className='text-xl w-[100%] flex items-center justify-start'>
                                            <button
                                                className='text-sm bg-primaryBlue hover:bg-primaryBlueHover text-text-light font-bold py-1 px-2 my-1 rounded-sm'
                                                onClick={handleProfileUpdate}>Save</button>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='w-[100%] h-auto flex items-end justify-start'>
                                            <p className='text-medium lg:text-2xl font-bold text-black'>{customerData ? customerData.username ? customerData.username : customerData?.contact : ''}</p>
                                        </div>
                                        <div className='w-[100%] text-start truncate overflow-auto'>
                                            <p className='text-xs text-gray'>Change you profile picture</p>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className='w-[15%] flex items-center justify-center float-left'>
                                {editProfile ? <p className='text-lg text-red hover:text-red-hover'><ImCross onClick={ProfileEditButtonEnable} /></p> : <p className='text-2xl text-primaryBlue hover:text-primaryBlue-hover'><FiEdit onClick={ProfileEditButtonEnable} /></p>}
                            </div>
                        </div>
                    </>
                }
            </div>
            <Helmet>
                <title>CHIPT | PROFILE UPDATE</title>
            </Helmet>
        </div >
    )
}

export default Chipt_Profile_Account_Image_Update;