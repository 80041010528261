import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';


export const Call_Customer_Cup_Return_To_Vendor = createAsyncThunk(
    'customerReturnCup/Call_Customer_Cup_Return_To_Vendor',
    async (data) => {
        const response = await axios.put('/api/v1/customer/order/retutn/vednor/cup', data);
        return response.data;
    },
);


const customerReturnCupSlice = createSlice({
    name: "customerReturnCup",
    initialState: {
        status: 'idle',
        isLoading: false,
        returnCup: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            
            // Scanned Cup Details 
            .addCase(Call_Customer_Cup_Return_To_Vendor.pending, (state) => {
                state.status = 'loading';
                state.isLoading = true;
                state.returnCup = null;
                state.error = null;
            })
            .addCase(Call_Customer_Cup_Return_To_Vendor.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.returnCup = action.payload;
                state.error = null;
            })
            .addCase(Call_Customer_Cup_Return_To_Vendor.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.returnCup = false;
                state.error = action.error.message;
            })
    },
});

export const state_Call_Fetch_Status = (state) => state.customerReturnCup.status;
export const state_Is_Loading = (state) => state.customerReturnCup.isLoading;
export const state_Customer_Return_Cup = (state) => state.customerReturnCup.returnCup;
export const state_Has_Error = (state) => state.customerReturnCup.error;

export default customerReturnCupSlice.reducer;