import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';


export const Call_Vendor_Store_Cups = createAsyncThunk(
    'vendorStoreCup/Call_Vendor_Store_Cups',
    async (vid) => {
        const response = await axios.get(`/api/v1/customer/vendor/${vid}/store/cups`);
        return response.data;
    },
);

const vendorStoreCupSlice = createSlice({
    name: "vendorStoreCup",
    initialState: {
        status: 'idle',
        isLoading: false,
        isAuthenticated: false,
        vendorStoreData: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        // Vendor Store Cups
                .addCase(Call_Vendor_Store_Cups.pending, (state) => {
                    state.status = 'idle';
                    state.isLoading = true;
                    state.isAuthenticated = false;
                    state.vendorStoreData = null;
                    state.error = null;
                })
                .addCase(Call_Vendor_Store_Cups.fulfilled, (state, action) => {
                    state.status = 'succeeded';
                    state.isLoading = false;
                    state.isAuthenticated = true;
                    state.vendorStoreData = action.payload;
                    state.error = null;
                })
                .addCase(Call_Vendor_Store_Cups.rejected, (state, action) => {
                    state.status = 'failed';
                    state.isLoading = false;
                    state.isAuthenticated = false;
                    state.vendorStoreData = null;
                    state.error = action.error.message;
                })
    },
});

export const state_Call_Fetch_Status = (state) => state.vendorStoreCup.status;
export const state_Is_Loading = (state) => state.vendorStoreCup.isLoading;
export const state_Is_Authenticated = (state) => state.vendorStoreCup.isAuthenticated;
export const state_Vendor_Store_Data = (state) => state.vendorStoreCup.vendorStoreData;
export const state_Has_Error = (state) => state.vendorStoreCup.error;

export default vendorStoreCupSlice.reducer;