import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import ScanCup from '../asset/scanCup.svg';
import { GoDotFill } from 'react-icons/go';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import {Helmet} from 'react-helmet';

const Chipt_Cup_Scan_Guide = () => {
  const navigate = useNavigate()
  return (
    <>
          <Helmet>
        <title>CHIPT | SCAN GUIDE</title>
      </Helmet>
      {/* Main container start */}
      <div className='w-[100%] h-screen'>

        {/* Sub Container start */}
        <div className='w-[10%] h-[43rem] text-xs float-left flex items-center justify-center animate-ping' onClick={() => navigate('/about')}><MdOutlineArrowBackIos /></div>
        <div className='w-[80%] h-[43rem] float-left overflow-hidden' >

          {/* navigation start */}

          <div className='w-[100%] h-[3rem] flex items-center justify-center'>
            <div className='w-[50%] h-[3rem] flex items-center justify-start'>
              <Link to='/about'><button className='float-left bg-gray-light rounded-xl text-xs font-bold px-2 py-1'>BACK</button></Link>
            </div>
            <div className='w-[50%] h-[3rem] flex items-center justify-end'>
              <Link to="/scan/guide"><button className='float-right bg-gray-light rounded-xl text-xs font-bold px-2 py-1' >NEXT</button></Link>
            </div>
          </div>
          {/* navigation end */}

          {/* Content start */}
          <div className='w-[100%] h-[40rem] flex items-center justify-center overflow-hidden'>
            <Link to="/scan/guide">
              <img className='w-[100%] h-[32rem] mt-[4rem]' src={ScanCup} alt="Chipt_Logo" />
            </Link>
          </div>
          {/* Content end */}
        </div>
        <div className='w-[10%] h-[43rem] text-xs float-right flex items-center justify-center animate-ping' onClick={() => navigate('/scan/guide')}><MdOutlineArrowForwardIos /></div>
        {/* Sub Container end */}

        {/* bottom footer */}
        <div className='w-[80%] h-[2rem] mx-[10%] float-left text-center'>
          <div className='flex justify-center text-xl'>
            <p><GoDotFill /></p>
            <p className='text-gray-light'><GoDotFill /></p>
            <p className='text-gray-light'><GoDotFill /></p>
          </div>
        </div>

      </div>
      {/* Main container end */}
    </>
  )
}

export default Chipt_Cup_Scan_Guide;