import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { Call_Get_Profile, state_Customer_Profile } from '../../redux/Profile/CustomerProfileSlice';
import { state_Is_Loading, state_Customer_New_Order } from '../../redux/orders/NewOrderCreation';
import { state_Cup_Details } from '../../redux/orders/ScannedCupSlice';
import { Call_User_Transaction_Status_Check } from '../../redux/transactions/TransactionStatus';
import ProjectLoader from '../NavigationComponents/Project_Loader';
import { state_Is_Authenticated } from '../../redux/authentication/CustomerAuthSlice';
import { Helmet } from 'react-helmet';

const Payment_Screen = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [customer, setCustomer] = useState();
  const [timeCheck, setTimeCheck] = useState();
  const isAuthenticated = useSelector(state_Is_Authenticated);
  const customer_Profile = useSelector(state_Customer_Profile);
  const isLoading = useSelector(state_Is_Loading);
  const customerNewOrder = useSelector(state_Customer_New_Order);
  const scannedCup = useSelector(state_Cup_Details);
  const [upTimer, setUpTimer] = useState();

  const handleProceedPayment = (e, btnType) => {
    const isTime = Date.now() < timeCheck;
    if (btnType === '11') {
      const tempData = {
        orderId: customerNewOrder.order._id,
        txnStatus: 'success',
        tokenID: 'CASH',
        orderAmount: customerNewOrder.order.orderAmount,
        currency: 'RM',
        timeOftxn: Date.now(),
        dateOftxn: Date.now()
      }
      // SUCCESS API CAll
      if (isTime && timeCheck) {
        dispatch(Call_User_Transaction_Status_Check(tempData))
        navigate('/payment/status')
      } else {
        // Failed TXN API call
        toast.error("Payment timeout, Please order again!");
        navigate('/home')
        // Navigation to payment page
      }

    } else if (btnType === '22') {
      if (isTime && timeCheck) {
        const firstname = customer.firstname ? customer.firstname.charAt(0).toUpperCase() + customer.firstname.slice(1) + " " : "";
        const middlename = customer.middlename ? customer.middlename.charAt(0).toUpperCase() + customer.middlename.slice(1) + " " : "";
        const lastname = customer.lastname ? customer.lastname.charAt(0).toUpperCase() + customer.lastname.slice(1) + " " : "";
        const userName = firstname + middlename + lastname;
        window.open(`https://sandbox.senangpay.my/payment/904170554825461?detail=${'NFC_Cup_Order_Payment'}&name=${userName}&email=${customer_Profile.customer.email}&phone=${customer_Profile.customer.contact}&amount_paid=${customerNewOrder.order.orderAmount}&order_id=${customerNewOrder.order._id}&hash=${customerNewOrder.payCode}&timeout=${480}`)
      } else {
        // Failed TXN API call
        toast.error("Payment timeout, Please order again!");
        navigate('/home')
        // Navigation to payment page
      }
    }
  }

  useEffect(() => {
    if (!isAuthenticated) {
      toast.success(`Please login first`);
      navigate('/signin')
    }
    if (customerNewOrder && !customerNewOrder.success) {
      toast.error(customerNewOrder.message);
      navigate('/nfc/reader')
    }
    if (customerNewOrder && customerNewOrder.order) {
      let timr = new Date(customerNewOrder.order.orderTime).getTime() + 900000;
      let countTimer = Date.now();
      setUpTimer(timr-countTimer);
      setTimeCheck(timr)
    }
    if (!customer_Profile) {
      dispatch(Call_Get_Profile())
    } else if (customer_Profile) {
      setCustomer(customer_Profile.customer);
    }
    if(upTimer<1000){
      toast.error("Payment timeout, Please order again!");
      navigate('/home')
    }
  }, [isLoading, customer_Profile]);

  useEffect(() => {
    if(upTimer<1000){
      toast.error("Payment timeout, Please order again!");
      navigate('/home')
    }
    const timer = setInterval(() => {
      setUpTimer(prevTime => prevTime - 1000);
    }, 1000); // Update every second

    return () => clearInterval(timer);
  }, []);
  const minutes = Math.floor(upTimer / 60000);
const seconds = ((upTimer % 60000) / 1000).toFixed(0);


  // ==========
  return (
    <div className='w-[100%] min-h-screen h-auto overflow-auto'>
      <div className='w-full h-full overflow-auto'>
        {isLoading ? <div className='w-[100%] h-screen'><ProjectLoader /></div>
          :
          <>
            <div className='w-[100%] h-auto float-left my-4 flex flex-wrap items-center justify-center'>
              <div className='w-[100%] h-auto px-2 flex items-center justify-end'>
                <i className='text-[0.6rem] text-red font-semibold'>{`${minutes}:${seconds} min Left`}</i>
              </div>
              <div className='w-[97%] float-left min-h-[15rem] h-auto bg-white border border-gray-light shadow-sm shadow-gray-light rounded-lg overflow-hidden'>
                <div className='w-[100%] h-auto py-3 sm:py-2 float-left overflow-auto bg-primaryNavy'>
                  <h1 className='text-center text-2xl text-white font-semibold'>Payment</h1>
                </div>
                <div className='w-[100%] h-auto float-left my-4 px-2 flex flex-wrap items-center justify-center overflow-auto'>
                  <div className='w-[99%] h-auto py-2 px-3 my-3 flex items-center justify-center outline-dashed outline-offset-2 outline-gray-light rounded-full overflow-hidden'>
                    <span className='w-[25%] text-sm sm:text-[0.85rem] font-medium text-left'>Order</span>
                    <span className='w-[75%] text-sm sm:text-[0.75rem] text-right truncate overflow-hidden'>{customerNewOrder?.order ? customerNewOrder.order._id.toUpperCase() : ''}</span>
                  </div>
                  <div className='w-[99%] h-auto py-2 px-3 my-3 flex items-center justify-center outline-dashed outline-offset-2 outline-gray-light rounded-full overflow-hidden'>
                    <span className='w-[25%] text-sm sm:text-[0.85rem] font-medium text-left'>Email</span>
                    <span className='w-[75%] text-sm sm:text-[0.75rem] text-right truncate overflow-hidden'>{customer ? customer.email : ''}</span>
                  </div>
                  <div className='w-[99%] h-auto py-2 px-3 my-3 flex items-center justify-center outline-dashed outline-offset-2 outline-gray-light rounded-full overflow-hidden'>
                    <span className='w-[25%] text-sm sm:text-[0.85rem] font-medium text-left'>Phone</span>
                    <span className='w-[75%] text-sm sm:text-[0.75rem] text-right truncate overflow-hidden'>{customer ? customer.contact : ''}</span>
                  </div>
                  <div className='w-[99%] h-auto py-2 px-3 my-3 flex items-center justify-center outline-dashed outline-offset-2 outline-gray-light rounded-full overflow-hidden'>
                    <span className='w-[25%] text-sm sm:text-[0.85rem] font-medium text-left'>Model</span>
                    <span className='w-[75%] text-sm sm:text-[0.75rem] text-right truncate overflow-hidden'>{scannedCup?.cupData ? scannedCup?.cupData?.modelID.toUpperCase() : ''}</span>
                  </div>
                  <div className='w-[99%] h-auto py-2 px-3 my-3 flex items-center justify-center outline-dashed outline-offset-2 outline-gray-light rounded-full overflow-hidden'>
                    <span className='w-[25%] text-sm sm:text-[0.85rem] font-medium text-left'>Cup</span>
                    <span className='w-[75%] text-sm sm:text-[0.75rem] text-right truncate overflow-hidden'>{scannedCup?.cupData ? scannedCup?.cupData?.uniqueID.toUpperCase() : ''}</span>
                  </div>
                </div>
                {/* <hr className='w-[95%] mx-[2.5%] h-[1px] mt-4 mb-2 border-none bg-black rounded-full'/> */}
                {/* <div className='w-[98%] mx-[1%] h-auto float-left px-4 py-2 my-2 flex items-center justify-center overflow-hidden border-2 border-gray-light rounded-full'>
                  <span className='w-[40%] text-sm font-medium text-left truncate overflow-hidden'>Amount to pay</span>
                  <span className='w-[60%] text-sm text-right px-2 py-1 truncate overflow-hidden'>{customerNewOrder?.order ? customerNewOrder.order.currency.toUpperCase() + " " + customerNewOrder.order.orderAmount : ''}</span>
                </div> */}
              </div>
              <div className='w-[97%] mx-[1.5%] h-auto float-left px-4 py-2 my-4 flex items-center justify-center overflow-hidden bg-white border-2 border-gray-light rounded-full'>
                <span className='w-[40%] text-base font-bold text-left text-primaryNavy truncate overflow-hidden'>Amount to pay</span>
                <span className='w-[60%] text-base font-bold text-right text-primaryNavy overflow-hidden'>{customerNewOrder?.order ? customerNewOrder.order.currency.toUpperCase() + " " + customerNewOrder.order.orderAmount : ''}</span>
              </div>

              <div className='w-[100%] h-auto fixed bottom-0 flex items-center justify-center'>
                <button className='w-auto h-auto mx-4 my-2 px-6 py-1 text-lg font-bold text-white bg-primaryBlue shadow-md shadow-gray rounded-lg' onClick={(e) => handleProceedPayment(e, '11')}>Cash</button>
                <button className='w-auto h-auto mx-4 my-2 px-6 py-1 text-lg font-bold text-white bg-primaryBlue shadow-md shadow-gray rounded-lg' onClick={(e) => handleProceedPayment(e, '22')}>Wallet</button>
              </div>
            </div>
          </>
        }

      </div>
      <Helmet>
        <title>CHIPT | ORDER PAYMENT</title>
      </Helmet>
    </div>
  )
}

export default Payment_Screen