import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionsContext } from '../../context/Context';
import { state_Is_Loading } from '../../redux/Profile/CustomerNotificationSlice';
import { Call_Get_Notification } from '../../redux/Profile/CustomerNotificationSlice';
import { TbAlertSquareFilled } from "react-icons/tb";
import { MdErrorOutline } from "react-icons/md";
import Project_Loader from '../NavigationComponents/Project_Loader';
import { GiPreviousButton, GiFastBackwardButton, GiFastForwardButton, GiNextButton } from "react-icons/gi";
import { BsCupHotFill } from "react-icons/bs";
import { Helmet } from 'react-helmet';

const InAppNotification = () => {

    const dispatch = useDispatch();
    const notificationContext = useContext(ActionsContext);
    const isLoading = useSelector(state_Is_Loading);
    const [allNotifications, setNotifications] = useState();
    const [currentTotal, setCurrentTotal] = useState(0)
    const [totalNotification, setTotalNotification] = useState();
    const [activeBTN, setActiveBTN] = useState(0)
    const [pageNumber, setPageNumber] = useState(1);

    const handleOnPageChange = (e, reqType) => {
        e.preventDefault();
        let x = Math.ceil(totalNotification / 10);
        if (reqType === 'first') {
            setPageNumber(1);
            setActiveBTN(1);
        } else if (reqType === 'prev') {
            if (pageNumber === 1) setPageNumber(1);
            else {
                let temp = pageNumber - 1;
                setPageNumber(temp)
            };
            setActiveBTN(2);
        } else if (reqType === 'next') {
            if (pageNumber === x) setPageNumber(x);
            else {
                let temp = pageNumber + 1;
                setPageNumber(temp)
            };
            setActiveBTN(3);
        } else if (reqType === 'last') {
            setPageNumber(x);
            setActiveBTN(4);
        }
    }

    const readNotificationCall = (e, id) => {
        e.preventDefault();
        notificationContext.loadRef.current.staticStart();
        dispatch(Call_Get_Notification({ page: pageNumber, id: id }))
            .then((data) => {
                if (data.payload) {
                    if (data.payload.success) {
                        console.log(data.payload)
                        setNotifications(data.payload.allNotification);
                        setCurrentTotal(data.payload.currentLength)
                        setTotalNotification(data.payload.totalNotifications);
                        notificationContext.loadRef.current.complete();
                    } else {
                        setNotifications(false);
                        notificationContext.loadRef.current.complete();
                    }
                } else notificationContext.loadRef.current.complete();
            })
    }

    useEffect(() => {
        notificationContext.loadRef.current.staticStart();
        dispatch(Call_Get_Notification({ page: pageNumber }))
            .then((data) => {
                if (data.payload) {
                    if (data.payload.success) {
                        setNotifications(data.payload.allNotification);
                        setCurrentTotal(data.payload.currentLength)
                        setTotalNotification(data.payload.totalNotifications);
                        notificationContext.loadRef.current.complete();
                    } else {
                        setNotifications(false);
                        notificationContext.loadRef.current.complete();
                    }
                } else notificationContext.loadRef.current.complete();
            })
    }, [dispatch, activeBTN, pageNumber])

    return (
        <div className='w-[100%] md:w-[70%] md:mx-[15%] lg:w-[50%] lg:mx-[25%] min-h-screen h-auto'>
            <div className='w-[100%] h-auto sm:my-4 overflow-auto'>
                <p className='w-[60%] h-[3rem] ml-[20%] float-left text-2xl font-bold flex items-center justify-center text-primaryNavy'>Notifications</p>
                <p className='w-[20%] h-[3rem] px-4 float-left flex items-center justify-end sm:justify-start'><button className='text-red text-2xl' onClick={() => notificationContext.setIsPopUp(!notificationContext.isPopUp)}><TbAlertSquareFilled /></button></p>
                <div className='w-[100%] h-auto float-left flex items-center justify-center mb-6'>
                    <hr className='w-[50%] h-[1px] text-gray-light bg-gray-light border-none' />
                </div>
                {isLoading
                    ?
                    <div className='w-[100%] md:-ml-[15%] lg:-ml-[25%] h-screen absolute overflow-hidden'><Project_Loader /></div>
                    :
                    <></>
                }
                <div className='w-[100%] h-auto overflow-auto'>

                    {allNotifications
                        ?
                        <>
                            <div className='w-[94%] h-auto mx-[3%] px-4 py-1 text-right'>
                                <p className='text-[0.7rem] font-bold'>{totalNotification ? <>{currentTotal} of {totalNotification}</> : <>0</>}</p>
                            </div>
                            {
                                allNotifications?.map((data) => {
                                    return (
                                        <div key={data._id} className='w-[96%] h-auto mx-[2%] overflow-auto bg-white border-[1px] border-primaryBlue shadow-md  shadow-gray-light hover:shadow-gray rounded-md mb-4' onClick={(e) => readNotificationCall(e, data._id)}>
                                            <div className='w-full h-auto overflow-auto p-3'>
                                                <p className='w-full h-auto text-[0.6rem] text-primaryBlue font-medium text-left'><i>{new Date(data.createdAt).toLocaleString()}</i></p>
                                                <p className='w-full h-auto text-[1rem] font-bold overflow-hidden truncate text-primaryBlueHover'>{data.title}</p>
                                                <p className='w-full h-auto text-[0.8rem] break-all'>{data.message}</p>
                                            </div>
                                            <div className='bg-primaryBlue w-[100%] h-[0.15rem]'></div>
                                        </div>
                                    )
                                })
                            }

                            <div className='w-[100%] h-auto float-left flex items-center justify-center mt-4 mb-6'>
                                <hr className='w-36 h-[1px] text-gray-light bg-gray-light border-none' />
                                &nbsp;<BsCupHotFill className='text-gray-light' />&nbsp;
                                <hr className='w-36 h-[1px] text-gray-light bg-gray-light border-none' />
                            </div>

                            <div className='w-[100%] h-auto px-4 py-1 flex items-center justify-center my-6'>
                                <button className={`w-10 lg:w-12 h-6 lg:h-8 text-[0.75rem] lg:text-[1rem] font-medium text-white flex items-center justify-center ${activeBTN === 1 ? 'bg-black' : 'bg-primaryBlue'} hover:bg-primaryBlueHover rounded-md mx-1`} onClick={(e) => handleOnPageChange(e, 'first')}><GiPreviousButton /></button>
                                <button className={`w-10 lg:w-12 h-6 lg:h-8 text-[0.75rem] lg:text-[1rem] font-medium text-white flex items-center justify-center ${activeBTN === 2 ? 'bg-black' : 'bg-primaryBlue'} hover:bg-primaryBlueHover rounded-md mx-1`} onClick={(e) => handleOnPageChange(e, 'prev')}><GiFastBackwardButton /></button>
                                <button className={`w-10 lg:w-12 h-6 lg:h-8 text-[0.75rem] lg:text-[1rem] font-medium text-white flex items-center justify-center ${activeBTN === 3 ? 'bg-black' : 'bg-primaryBlue'} hover:bg-primaryBlueHover rounded-md mx-1`} onClick={(e) => handleOnPageChange(e, 'next')}><GiFastForwardButton /></button>
                                <button className={`w-10 lg:w-12 h-6 lg:h-8 text-[0.75rem] lg:text-[1rem] font-medium text-white flex items-center justify-center ${activeBTN === 4 ? 'bg-black' : 'bg-primaryBlue'} hover:bg-primaryBlueHover rounded-md mx-1`} onClick={(e) => handleOnPageChange(e, 'last')}><GiNextButton /></button>
                            </div>
                        </>
                        :
                        <>
                            {isLoading ? <></> :
                                <div className='w-[100%] h-[30rem]'>
                                    <p className='flex items-start justify-center mt-[4rem]'><MdErrorOutline className='text-[15rem] text-primaryBlue' /></p>
                                    <p className='text-center mt-8 text-2xl font-bold'>No Notification</p>
                                </div>
                            }

                        </>
                    }
                </div>
            </div>
            <Helmet>
                <title>CHIPT | NOTIFICATION</title>
            </Helmet>
        </div>
    )
}

export default InAppNotification