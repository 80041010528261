import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';


export const Call_User_Transaction_Status_Check = createAsyncThunk(
    'userTXNStatus/Call_User_Transaction_Status_Check',
    async (data) => {
        const response = await axios.post('/api/v1/customer/order/new/transaction', data);
        return response.data;
    },
);


const userTXNStatusSlice = createSlice({
    name: "userTXNStatus",
    initialState: {
        status: 'idle',
        isLoading: false,
        txnStatus: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            
            // Scanned Cup Details 
            .addCase(Call_User_Transaction_Status_Check.pending, (state) => {
                state.status = 'loading';
                state.isLoading = true;
                state.txnStatus = null;
                state.error = null;
            })
            .addCase(Call_User_Transaction_Status_Check.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.txnStatus = action.payload;
                state.error = null;
            })
            .addCase(Call_User_Transaction_Status_Check.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.txnStatus = false;
                state.error = action.error.message;
            })
    },
});

export const state_Call_Fetch_Status = (state) => state.userTXNStatus.status;
export const state_Is_Loading = (state) => state.userTXNStatus.isLoading;
export const state_Transaction_Status = (state) => state.userTXNStatus.txnStatus;
export const state_Has_Error = (state) => state.userTXNStatus.error;

export default userTXNStatusSlice.reducer;