import React, { useState, useEffect } from 'react';
import ProfileUpdate from './Chipt_Profile_Account_Image_Update';
import ChiptBasicDetailsUpdate from './Chipt_Basic_Data_Update';
import ChiptAddressUpdate from './Chipt_Address_Update';
import { Helmet } from 'react-helmet';

const Chipt_Profile_Account_Update = () => {

  const [enableField, setEnableField] = useState(false)

  const handleEnableEditField1 = (e) => {
    e.preventDefault();
    setEnableField(false)
  }

  const handleEnableEditField2 = (e) => {
    e.preventDefault();
    setEnableField(true)
  }

  useEffect(() => { }, [enableField])

  return (
    <>
      <div className='w-[90%] h-auto mx-[5%] lg:w-[80%] lg:mx-[10%] md:w-[75%] md:mx-[12.5%]'>

        {/* Sub Container start */}
        <div className='w-[100%] h-auto overflow-auto'>

          {/* Content Start */}
          <div className='w-[100%] h-auto my-4'>

            <div className='w-[100%] h-auto overflow-auto my-4'>
              <ProfileUpdate />
            </div>

            {/* Update Option */}
            <div className='w-[100%] h-[3rem] float-left mt-2'>
              <button onClick={handleEnableEditField1} className={`w-[49.5%] mr-[0.5%] h-[2.5rem] float-left bg-primaryNavy hover:bg-primaryNavyHover text-lg ${enableField ? 'text-white' : 'text-gold'} hover:text-gold font-bold rounded-lg hover:shadow-lg`}>Basic</button>
              <button onClick={handleEnableEditField2} className={`w-[49.5%] ml-[0.5%] h-[2.5rem] float-left bg-primaryNavy hover:bg-primaryNavyHover text-lg ${enableField ? 'text-gold' : 'text-white'} hover:text-gold font-bold rounded-lg hover:shadow-lg`}>Address</button>
            </div>

            {/* Profile Imformation Update*/}
            <div className='w-[100%] h-auto float-left'>
              {enableField ? <ChiptAddressUpdate /> : <ChiptBasicDetailsUpdate />}
            </div>
          </div>
        </div>
      </div>
      <Helmet>
        <title>CHIPT | PROFILE UPDATE</title>
      </Helmet>
    </>
  )
}

export default Chipt_Profile_Account_Update