import React, { useState, useContext, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { useToggle, useVibrate } from 'react-use';
import NFCTagImage from '../asset/nfcChip.svg';
import NFCGreenTag from '../asset/nfcChipHover.svg';
import Scan from '../../containers/NFCScan';
import { ActionsContext } from '../../context/Context';
import { BiSolidError } from "react-icons/bi";
import Project_Loader from '../NavigationComponents/Project_Loader';

const NFCReader = () => {

  const NFCContext = useContext(ActionsContext);
  const [nfcImage, setNFCImage] = useState(NFCTagImage);
  const [scanBool, setScanBool] = useState(false);
  const [nfcAvailable, setNFCAvailable] = useState(true);
  const [btnMsg, setbtnMsg] = useState('Scan');
  const [callLoading, setCallLoading] = useState(false)
  const { scan, write } = NFCContext.actionsValue.actions || {};

  const [vibrating, toggleVibrating] = useToggle(false);
  useVibrate(vibrating, [100], false);

  // Checking if NFC Available
  function checkNFCAvailable() {
    setNFCImage(NFCTagImage);
    setNFCAvailable(false)
  }

  // Scanning
  const onHandleAction = (actions) => {
    NFCContext.actionsValue.setActions(actions);
    setbtnMsg('Scanning...')
    setNFCImage(NFCGreenTag);
    setScanBool(true)
    setTimeout(() => {
      toggleVibrating();
    }, 0);
    setTimeout(() => {
      toggleVibrating();
    }, 100)
  }

  // Cancel Button Function
  function handleScanCancel() {
    if (btnMsg === 'Scanning...') {
      setbtnMsg('Scan')
      setScanBool(false)
      setNFCImage(NFCTagImage);
      NFCContext.actionsValue.setActions({ ...NFCContext.actionsValue.actions, scan: null });
    }
  }

  // Changing Message after scanned
  function changeBTNmsg(msg) {
    setbtnMsg(msg)
  }

  function handleCupCallHandler(statusVal){
    if(statusVal === 'true') setCallLoading(true)
    else if(statusVal === 'false') {
      setCallLoading(false);
      setTimeout(()=>{
        setbtnMsg('Scan')
        setScanBool(false)
        setNFCImage(NFCTagImage);
        NFCContext.actionsValue.setActions({ ...NFCContext.actionsValue.actions, scan: null });
      }, 1000)
    }
    else setCallLoading(false)
  }

  useEffect(() => {
  }, [btnMsg, callLoading])


  return (
    <>
      {/* Main Container Start */}
      {callLoading ? <Project_Loader/> : <></> }
      <div className='w-[100%] min-h-screen h-auto float-left overflow-auto'>

        {/* Sub Container Start */}
        <div className='w-[100%] lg:w-[50%] lg:mx-[25%] h-auto pb-8 overflow-auto float-left'>

          {
            nfcAvailable ?
              <>
                <div className='w-[100%] h-[18rem] flex items-center justify-center overflow-hidden'>
                  <img className='w-auto h-[17rem] mt-[4rem]' src={nfcImage} alt="logo" />
                </div>
                <div className="w-[100%] h-[3rem] flex items-center justify-center">
                  <button
                    className="w-[45%] py-2 bg-primaryBlue text-xl font-bold text-text-color-light flex items-center justify-center rounded-lg"
                    onClick={() => onHandleAction({ scan: 'scanning', write: null })}>{btnMsg}</button>
                </div>
                <div className='w-[100%] min-h-[15rem] h-auto float-left overflow-auto'>
                  {scanBool ?
                    <>
                      {scan && <Scan changeFun={{ handleScanCancel, changeBTNmsg, checkNFCAvailable, handleCupCallHandler }} />}
                    </>
                    : <div className='w-[90%] h-[10rem] mx-[5%] flex items-center justify-center text-sm break-words'>Put you device in NFC tag range of cup.</div>
                  }
                </div>
              </>
              :
              <div className='w-[100%] h-auto'>
                <div className='w-[90%] mx-[5%] h-[20rem] float-left flex items-center justify-center'>
                  <p className='text-[18rem]'><BiSolidError className='text-red' /></p>

                </div>
                <div className='w-[100%] h-[10vh] float-left flex items-center justify-center '>
                  <p className='text-lg text-navy font-bold'>Current device doesn't support NFC</p>
                </div>
                <div className='w-[100%] h-[10vh] float-left flex items-center justify-center'>
                  <button className='bg-primaryBlue text-text-color-light mt-2 py-1 px-4 font-medium rounded text-lg'><Link to='/home'>Home</Link></button>
                </div>
              </div>
          }
        </div>
        {/* Sub Container End */}

      </div>
      {/* Main Container End */}
    </>
  )
}

export default NFCReader;