import React from 'react';
import ChiptLogo from '../asset/chipt.svg';
import { Link } from 'react-router-dom';

const Chipt_Security_Footer = () => {
    return (
        <div className='w-full'>
                <div className='w-[100%] h-[4rem]'>
                    <div className='h-auto flex items-center justify-center'>
                        <p className='flex items-center justify-center text-md sm:text-sm'>2023&nbsp;<img className='w-10' src={ChiptLogo} alt="Chipt_Logo" />&nbsp;All Rights Reserved</p>
                    </div>
                    <div className='w-[100%] h-auto flex items-center justify-center overflow-auto my-1'>
                        <div className='flex flex-wrap text-md sm:text-xs break-all'>
                            <Link to="/privacypolicy" className='font-medium lg:font-bold underline decoration-1'>Privacy Policy</Link>,&nbsp;
                            <Link to="/termcondition" className='font-medium lg:font-bold underline decoration-1'>Term & Condition</Link>,
                            <span className='lg:font-medium'>&nbsp;and&nbsp;</span>
                            <Link to="/refundpolicy" className='font-medium lg:font-bold underline decoration-1'>Refund Policy</Link>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default Chipt_Security_Footer