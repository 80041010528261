import React, { useEffect, useState } from 'react';
import { FaBeerMugEmpty } from "react-icons/fa6";
import { HiBellAlert } from "react-icons/hi2";
import { state_Cups_ReturnAble } from '../../redux/orders/CustomerReturnableCupSlice';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

const PopUpNotification = () => {

    const [showAlert, setShowAlert] = useState();
    const returnAbleCups = useSelector(state_Cups_ReturnAble);

    useEffect(() => {
        setShowAlert(returnAbleCups.isCups)
    }, []);

    return (
        <div className='w-[100%] h-auto py-8 flex items-start justify-center bg-white overflow-auto'>
            <div className='w-[75%] md:w-[85%] sm:w-[100%] bg-white h-auto overflow-auto'>
                <div className='w-[100%] h-auto p-2 '>
                    {showAlert
                        ?
                        <>
                            {showAlert && showAlert.map((data) => {
                                let buyDate = new Date(data.orderDate).toDateString();
                                let temp = new Date(data.orderDate).getTime() + data.cupID.returnTime * 86400000
                                let returnDate = new Date(temp).toDateString()
                                return (
                                    <div key={data._id} className='w-[100%] h-[4.25rem] my-4 shadow shadow-gray-light overflow-hidden rounded border border-primaryBlue'>
                                        <div className='w-[15%] h-[4rem] float-left flex items-center justify-center'>
                                            <FaBeerMugEmpty className='text-primaryBlue text-[3rem]' />
                                        </div>
                                        <div className='w-[85%] h-[4rem] float-left overflow-hidden flex items-center justify-center'>
                                            <p className='line-clamp-2 sm:line-clamp-3 text-sm sm:text-xs px-4'>Cup {"'" + data?.cupID?.cupType?.charAt(0).toUpperCase() + data?.cupID?.cupType?.slice(1) + "'"} size {"'" + data?.cupID?.cupSize?.charAt(0).toUpperCase() + data?.cupID?.cupSize?.slice(1) + "'"} bought from vendor {"'" + data?.currentVendor?.name.charAt(0) + data?.currentVendor?.name.slice(1) + "'"} on {buyDate} is pending for return. Please return it before {returnDate}.</p>
                                        </div>
                                        <div className='w-[100%] h-[0.25rem] float-left bg-primaryBlue'></div>
                                    </div>
                                )
                            })}
                        </>
                        : <></>
                    }
                    <div className='w-[100%] h-auto p-2 mt-4 text-gray-light flex items-center justify-center'>
                        <hr className='w-32 h-[1px] border-none bg-gray-light' />
                        &nbsp;<HiBellAlert className='text-lg' />&nbsp;
                        <hr className='w-32 h-[1px] border-none bg-gray-light' />
                    </div>
                </div>
            </div>
            <Helmet>
                <title>CHIPT | ALERT</title>
            </Helmet>
        </div>
    )
}

export default PopUpNotification