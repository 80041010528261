import React, { useState, useEffect, useContext } from 'react';
import { BsPatchCheckFill } from 'react-icons/bs';
import { BiSolidErrorAlt } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import ShopImage from '../asset/admin.jpg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { Call_Single_Txn_Details } from '../../redux/transactions/SingleTransactionsSlice';
import CupDetails from '../asset/cupDetails.svg';
import { GiPayMoney } from "react-icons/gi";
import { ActionsContext } from '../../context/Context';
import Project_Loader from '../NavigationComponents/Project_Loader';
import { state_Is_Authenticated } from '../../redux/authentication/CustomerAuthSlice';
import { Helmet } from 'react-helmet';
const Chipt_Single_Transactions = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state_Is_Authenticated);
    const [txnData, setTXNData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [payStatus, setPayStatus] = useState(true);
    const chiptAction = useContext(ActionsContext);
    const [callLoader, setCallLoader] = useState(false);

    useEffect(() => {
        if (!isAuthenticated) {
            toast.success(`Please login first`);
            navigate('/signin')
        }
        setIsLoading(true);
        setCallLoader(true);
        chiptAction.loadRef.current.staticStart();
        dispatch(Call_Single_Txn_Details(id))
            .then((data) => {
                if (data.payload) {
                    if (data.payload.success) {
                        setTXNData(data.payload.transaction);
                        if (data.payload.transaction.txnStatus.toLowerCase() === 'success') {
                            setPayStatus(true);
                            chiptAction.loadRef.current.complete();
                            setIsLoading(false);
                            setCallLoader(false);
                        }
                        else {
                            setPayStatus(false);
                            chiptAction.loadRef.current.complete();
                            setIsLoading(false);
                            setCallLoader(false);
                        }
                    }
                } else {
                    setIsLoading(false);
                    chiptAction.loadRef.current.complete();
                    setCallLoader(false);
                }
            })
    }, [dispatch]);

    useEffect(() => { }, [callLoader])

    return (
        <>
            {/* Main Containe Start */}
            {callLoader ? <div className='w-full h-screen'><Project_Loader /></div>
                :
                <>
                    <div className='w-[100%] lg:w-[90%] lg:mx-[5%] min-h-screen h-auto overflow-auto'>
                        <h2 className='text-center text-2xl font-bold my-4'>Transaction Detail</h2>

                        <div className='w-[90%] h-auto mx-[5%] my-4 px-2 py-4 border rounded-xl border-gray-light shadow-sm shadow-gray-light overflow-auto'>
                            {/* Top Container start */}
                            <div className='w-[92%] mx-[4%] h-auto py-4 float-left'>
                                {/* Left */}
                                <div className='w-[100%] h-[8rem] py-1 float-left overflow-auto'>
                                    <p className='h-[2rem] text-2xl font-bold'>Amount</p>
                                    <div className='h-[3rem] flex items-center justify-start text-3xl font-extrabold'>
                                        <span>{payStatus && txnData && !isLoading ? <i>{txnData.currency.toUpperCase()}</i> : <i>RM 0</i>}&nbsp;</span>
                                        <span>{txnData && !isLoading ? payStatus ? <BsPatchCheckFill className='text-primaryBGrn' /> : <BiSolidErrorAlt className='text-red' /> : <GiPayMoney className='text-gray' />}</span>
                                        <span>{txnData && !isLoading && payStatus ? txnData.orderAmount : <></>}</span>
                                    </div>
                                    <p className='h-[1rem] flex items-start justify-start text-[0.75rem]'><i>TXN ID:&nbsp;</i>{payStatus && txnData && !isLoading ? txnData._id : isLoading ? <>Loading...</> : ''}</p>
                                    <p className='h-[1rem] flex items-start justify-start text-[0.75rem]'>
                                        {payStatus
                                            ? <span className='text-primaryBGrn'>Paid on&nbsp;{txnData && !isLoading ? txnData.timeOftxn : isLoading ? <>Loading...</> : ''}</span>
                                            : <span className='text-red'>Failed on&nbsp;{txnData && !isLoading ? txnData.timeOftxn : isLoading ? <>Loading...</> : ''}</span>}
                                    </p>
                                </div>

                                {/* Right */}
                                <div className='w-[100%] min-h-[8rem] h-auto mt-4 py-1 float-left overflow-hidden border-[1px] border-l-0 border-r-0 border-gray-light'>
                                    <div className='w-[35%] h-[10rem] flex items-start justify-start float-left'>
                                        <img className="w-auto h-[9rem] p-2" src={!isLoading && txnData ? txnData.order.cupID.cupImages[0].url : CupDetails} alt='Cup_Image' />
                                    </div>
                                    <div className='w-[65%] h-[8rem] p-1 float-left'>
                                        <p className='w-[100%] h-[2rem] text-2xl font-bold break-word truncate overflow-hidden'>{txnData && !isLoading ? txnData.order.cupID?.cupType.charAt(0).toUpperCase() + txnData.order.cupID?.cupType.slice(1) : isLoading ? <>Loading...</> : ''}</p>
                                        <p className='w-[100%] h-[1.5rem] mb-1 text-xl font-medium break-word truncate overflow-hidden'>
                                            {txnData && !isLoading ? txnData.order.cupID?.cupSize.charAt(0).toUpperCase() + txnData.order.cupID?.cupSize.slice(1) : isLoading ? <>Loading...</> : ''}&nbsp;
                                            <i className='text-md sm:text-sm'>{txnData && !isLoading ? txnData.order.cupID?.cupCapacity + " ml" : isLoading ? <></> : ''}</i>
                                        </p>
                                        <p className='w-[100%] h-[1rem] text-[0.7rem] break-word truncate overflow-hidden'><i>Model ID:&nbsp;</i>{txnData && !isLoading ? txnData.cupModelUniqueId : isLoading ? <></> : ''}</p>
                                        <p className='w-[100%] h-[1rem] text-[0.7rem] break-word truncate overflow-hidden'><i>Unique ID:&nbsp;</i>{txnData && !isLoading ? txnData.cupUniqueId : isLoading ? <></> : ''}</p>
                                    </div>
                                </div>
                            </div>

                            {/* Top Container End */}

                            {/* Bottom Container Start */}
                            <div className='w-[90%] mx-[5%] min-h-[12rem] h-auto py-4 flex flex-wrap items-center justify-center overflow-auto'>
                                <div className='w-[100%] h-[12rem] p-1 float-left flex items-center justify-start overflow-hidden'>
                                    <img className="w-[11rem] h-[11rem] rounded shadow shadow-gray-light" src={!isLoading && txnData ? txnData.vendor.profilePicture.url : ShopImage} alt='SHop_Image' />
                                </div>
                                <div className='w-[100%] min-h-[10rem] h-auto float-left'>
                                    <p className='w-[100%] h-[2.5rem] text-3xl text-navy font-semibold break-word truncate overflow-hidden mt-2'>{!isLoading && payStatus && txnData ? <>{txnData.vendor.name.charAt(0).toUpperCase() + txnData.vendor.name.slice(1)}</> : <>{isLoading ? <>Loading...</> : <></>}</>}</p>
                                    <p className='w-[100%] h-[1.75rem] text-base font-medium break-word truncate overflow-hidden'>{!isLoading && payStatus && txnData ? <>{txnData.vendor.primaryEmail}</> : <>{isLoading ? <>Loading...</> : <></>}</>}</p>
                                    <p className='w-[100%] h-[1.75rem] text-base font-medium break-word truncate overflow-hidden'>{!isLoading && payStatus && txnData ? <>{"+" + txnData.vendor?.countryCode + " " + txnData.vendor?.primaryContactNumber}</> : <>{isLoading ? <>Loading...</> : <></>}</>}</p>
                                    <p className='w-[100%] h-[4.5rem] text-sm break-word font-normal '>{!isLoading && payStatus && txnData ? <>{txnData.vendor?.plotnumber + " " + txnData.vendor?.address + " " + txnData.vendor?.city + ", " + txnData.vendor?.state + ", " + txnData.vendor?.country}</> : <>{isLoading ? <>Loading...</> : <></>}</>}</p>
                                </div>
                            </div>
                            {/* Bottom Container End */}
                        </div>
                        {/* Main Containe End */}
                    </div>
                </>
            }
            <Helmet>
                <title>CHIPT | SINGLE TRANSACTION</title>
            </Helmet>
        </>
    )
}

export default Chipt_Single_Transactions