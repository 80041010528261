import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { Call_Reset_Password_OTP } from '../../../redux/authentication/ForgotPasswordSlice';
import Chipt_Security_Footer from '../../NavigationComponents/Chipt_Security_Footer';
import { ActionsContext } from '../../../context/Context';
import Project_Loader from '../../NavigationComponents/Project_Loader';
import { Helmet } from 'react-helmet';

const Chipt_Account_Password_Reset_Email = () => {

    const [formData, setFormData] = useState();
    const [passwordSame, setPasswordSame] = useState(false);
    const [passChangeAble, setPassChangeAble] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const chiptAction = useContext(ActionsContext);
    const [callLoader, setCallLoader] = useState(false);

    const handleOnChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value })
        if (e.target.name === 'confirmPassword') {
            if (formData?.newPassword) {
                if (formData.newPassword === e.target.value) {
                    setPasswordSame(true)
                } else {
                    setPasswordSame(false)
                }
            }
        }
        if (e.target.name === 'newPassword') {

            let numCheck = false;
            let smallChar = false;
            let capitalChar = false;
            const allowedNum = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
            const allowedSmallChar = [65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90]
            const allowedCapitalNum = [97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122]
            if (!e.target.value) return
            else {
                for (let i = 0; i < e.target.value.length; i++) {
                    let x = e.target.value[i]
                    let charCheck = x.charCodeAt()
                    if (allowedNum.includes(charCheck)) numCheck = true
                    else if (allowedSmallChar.includes(charCheck)) smallChar = true
                    else if (allowedCapitalNum.includes(charCheck)) capitalChar = true
                }
            }
            if (numCheck && smallChar && capitalChar) {
                setPassChangeAble(true);
            }
            if (formData?.confirmPassword) {
                if (formData.confirmPassword === e.target.value) {
                    setPasswordSame(true)
                } else {
                    setPasswordSame(false)
                }
            }
        }

    }

    const onPasswordReset = (e) => {
        e.preventDefault();
        setCallLoader(true);
        chiptAction.loadRef.current.staticStart();
        if (!passChangeAble) {
            toast.error(`Please include atleast one small alphabet, one capital alphabet and one number in your password!`);
            chiptAction.loadRef.current.complete();
            setCallLoader(false);
            return
        }
        if (formData.newPassword !== formData.confirmPassword) {
            toast.error(`Password doesn't match!`);
            chiptAction.loadRef.current.complete();
            setCallLoader(false);
            return
        }
        dispatch(Call_Reset_Password_OTP({
            email: state.email,
            newPassword: formData.newPassword,
            confirmPassword: formData.confirmPassword
        })).then((data) => {
            if (data.payload.success) {
                toast.success('Password reset successful!')
                chiptAction.loadRef.current.complete();
                setCallLoader(false);
                navigate('/signin', { state: { email: undefined } })
            } else if (!data.payload.success) {
                toast.success(data.payload.message);
                chiptAction.loadRef.current.complete();
                setCallLoader(false);
            }
        })
    }

    useEffect(() => { }, [dispatch, passwordSame, callLoader])


    return (
        <>
            {/* Main Container Start */}
            {callLoader ? <div className='w-[100%] md:-ml-[15%] lg:-ml-[25%] h-screen absolute flex items-center justify-center'><Project_Loader /></div> : <></>}
            <div className='w-[80%] min-h-screen h-auto mx-[10%] overflow-auto'>

                {/* Sub Container start */}
                <div className='w-[100%] h-auto overflow-auto'>

                    {/* Content Start */}
                    <div className='w-[100%] h-[25rem] mt-[3rem] mb-[8rem] lg:mt-[5rem] lg:mb-[5rem]'>
                        <div className='w-[100%] py-16 lg:py-8 text-center'>
                            <h1 className='font-bold text-2xl'>Set New Password!</h1>
                            <p className='text-sm'>Enter new and confirm password</p>
                        </div>

                        <form onSubmit={onPasswordReset}>
                            <div className='w-[100%] justify-center'>
                                <input
                                    className={`w-[100%] my-2 py-2 px-2 text-sm border-b outline-none ${passwordSame ? 'text-green' : 'text-black'}`} type="password"
                                    onChange={handleOnChange} value={formData?.newPassword ? formData.newPassword : ''}
                                    name='newPassword' placeholder='New Password' required autoComplete='off' />

                                <input
                                    className={`w-[100%] my-2 py-2 text-sm px-2 border-b outline-none ${passwordSame ? 'text-green' : 'text-black'}`} type="text"
                                    onChange={handleOnChange} value={formData?.confirmPassword ? formData.confirmPassword : ''}
                                    name='confirmPassword' placeholder='Confirm Password' required autoComplete='off' />
                            </div>
                            <div className='text-center py-6'>
                                {/* <Link to='/signin'> */}
                                <button className='bg-primaryBlue hover:bg-primaryBlueHover hover:shadow-md text-text-light text-lg w-[70%] py-2 sm:py-1.5 rounded-2xl font-bold'>Reset</button>
                                {/* </Link> */}
                            </div>
                        </form>


                    </div>
                    {/* Content End */}
                </div>
                {/* Sub Container End */}

                {/* bottom footer */}
                <Chipt_Security_Footer />
            </div>
            {/* Main Container End */}
            <Helmet>
                <title>CHIPT | RESET PASSWORD</title>
            </Helmet>
        </>

    )
}

export default Chipt_Account_Password_Reset_Email;