import React, { useEffect, useState } from 'react';
import CupImage from '../asset/admin.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { TbMugOff } from "react-icons/tb";
import Project_Loader from '../NavigationComponents/Project_Loader';
import { Call_Customer_All_Cups_Bought, state_Is_Loading, state_Bought_Cups, state_Has_Error } from '../../redux/orders/CustomerAllOrderedCups';
import { Helmet} from 'react-helmet';
const Customer_All_Bought_Cups = () => {

  const dispatch = useDispatch();
  const [cupData, setCupData] = useState();

  const isLoading = useSelector(state_Is_Loading);
  const boughtCups = useSelector(state_Bought_Cups);
  const hasError = useSelector(state_Has_Error);

  useEffect(() => {
    if (boughtCups) {
      if (boughtCups.success && boughtCups.cupList.length > 0) setCupData(boughtCups.cupList)
      else if (hasError) console.log(hasError)
    } else {
      dispatch(Call_Customer_All_Cups_Bought())
    }
  }, [cupData, isLoading])

  return (
    <div className='w-[100%] lg:w-[80%] lg:mx-[10%] min-h-screen h-auto overflow-auto'>
      <div className='w-[100%] h-full my-2'>
        <h2 className="text-lg text-center font-bold">All Bought Cups</h2>
      </div>
      {isLoading
        ?
        <><Project_Loader/></>
        :
        <>
          <div className='w-[100%] min-h-[30rem] h-auto float-left overflow-auto'>
            {!isLoading && cupData
              ?
              <>{cupData.map((data) => {
                return (
                  < div key={data._id} className='w-[98%] h-[6rem] mx-[1%] my-3 shadow-md rounded-lg border border-gray-light overflow-hidden' >
                    <div className='w-[30%] h-full float-left flex items-center justify-center'>
                      <img className='w-[5rem] h-[5rem] rounded-lg' src={data.cupID?.cupImages[0]?.url || CupImage} alt='Cup_Imahge' title='Cup_Image' />
                    </div>
                    <div className='w-[70%] h-full float-left px-2 py-1'>
                      <h1 className='text-lg font-bold truncate overflow-hidden'>{data.cupID?.cupType.charAt(0).toUpperCase()+data.cupID?.cupType.slice(1) || 'Cup'}</h1>
                      <hr className='w-[60%] h-[2px] bg-navy rounded-full border-none ouline-none' />
                      <p className='w-[100%] h-auto my-1 text-xs lg:text-sm truncate overflow-hidden'>Bought on {new Date(data.orderTime).toLocaleString()}</p>
                      <p className={`w-[100%] h-auto my-1 text-xs lg:text-sm truncate overflow-hidden ${data.isReturned ? 'text-primaryBlue' : 'text-red'}`}>{data.isReturned ? <>Returned</> : <>Not returned</>}</p>
                    </div>
                  </div>
                )
              })}
              </>
              :
              <>
                <div className='w-[100%] h-auto overflow-auto'>
                  <h1 className='flex items-center justify-center text-[15rem] mt-[3rem] text-red'><TbMugOff /></h1>
                  <p className='text-center text-xl font-semibold my-4'>No data found</p>
                </div>
              </>
            }




          </div>
        </>
      }
      <Helmet>
        <title>CHIPT | YOUR CUPS</title>
      </Helmet>
    </div >
  )
}

export default Customer_All_Bought_Cups