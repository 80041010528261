import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import ProfileUpdate from '../Profile/Chipt_Profile_Account_Image_Update';
import { Call_Password_Change } from '../../../redux/authentication/CustomerAuthSlice';
import toast from 'react-hot-toast';
import { ActionsContext } from '../../../context/Context';
import { state_Is_Loading, state_Is_Active } from '../../../redux/authentication/AccountStatusSlice';
import { state_Is_Authenticated } from '../../../redux/authentication/CustomerAuthSlice';
import Project_Loader from '../../NavigationComponents/Project_Loader';
import { Helmet } from 'react-helmet';

const Chipt_Password_Update = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const chiptAction = useContext(ActionsContext);
    const isAuthenticated = useSelector(state_Is_Authenticated);
    const isLoading = useSelector(state_Is_Loading);
    const isActive = useSelector(state_Is_Active)
    const [formData, setFormData] = useState({});
    const [oldPass, setOldPass] = useState('')
    const [newPassword, setNewPassword] = useState();
    const [passAlert, setPassalert] = useState(true);
    const [newPassVisible, setNewPassVisible] = useState(false);
    const [passCondition, setPassCondition] = useState(1);
    const [passEqual, setPassEqual] = useState(false);
    const [passChangeAble, setPassChangeAble] = useState(false);
    const [confirPassVisible, setConfirPassVisible] = useState(false)
    const [callLoader, setCallLoader] = useState(false);

    const handlePasswordChange = (e) => {
        e.preventDefault();
        let numCheck = false;
        let smallChar = false;
        let capitalChar = false;
        const allowedNum = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
        const allowedSmallChar = [65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90]
        const allowedCapitalNum = [97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122]
        setNewPassword(e.target.value);
        if (!e.target.value) return
        else {
            for (let i = 0; i < e.target.value.length; i++) {
                let x = e.target.value[i]
                let charCheck = x.charCodeAt()
                if (allowedNum.includes(charCheck)) numCheck = true
                else if (allowedSmallChar.includes(charCheck)) smallChar = true
                else if (allowedCapitalNum.includes(charCheck)) capitalChar = true
            }
        }

        if (numCheck && smallChar && capitalChar) {
            setPassCondition(3);
            setPassChangeAble(true);
        }
        else {
            setPassCondition(2);
        }
        if (e.target.value === formData.confirmPassword) {
            setPassEqual(true)
        }
        else setPassEqual(false)

        setNewPassword(e.target.value);
    }

    const handlePasswordAlert = (e) => {
        e.preventDefault();
        if (passAlert) {
            setPassCondition(2)
            toast(`Password should include at least one number, one alphabet, and one special character.`, { position: 'top-right', duration: 8000 })
        }
        setPassalert(false)
    }

    const handleFormDataChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value })
        if (e.target.name == 'confirmPassword') {
            if (e.target.value === newPassword) {
                setPassEqual(true)
            }
            else setPassEqual(false)
        }
    }

    // Pssword Change function
    const submitPasswordChange = async (e) => {
        e.preventDefault();
        setCallLoader(true);
        chiptAction.loadRef.current.staticStart();
        if (!passChangeAble) {
            toast.error(`Some characters are missing in password!`);
            chiptAction.loadRef.current.complete();
            setCallLoader(false);
            return
        }
        if (newPassword.length < 8) {
            toast.error(`Password should be of minimum 8 characters!`);
            chiptAction.loadRef.current.complete();
            setCallLoader(false);
            return
        }
        if (newPassword !== formData.confirmPassword) {
            toast.error(`Password doesn't matches!`);
            chiptAction.loadRef.current.complete();
            setCallLoader(false);
            return
        }
        dispatch(Call_Password_Change({
            oldPassword: oldPass,
            newPassword: newPassword,
            confirmPassword: formData.confirmPassword
        }))
            .then((data) => {
                if (data.payload) {
                    if (data.payload.success) {
                        toast.success(data.payload.message)
                        let temp = {
                            oldPassword: '',
                            confirmPassword: ''
                        }
                        setFormData(temp)
                        setOldPass('')
                        setNewPassword('');
                        chiptAction.loadRef.current.complete();
                        setCallLoader(false);
                    } else if (!data.payload.success) {
                        toast.error(data.payload.message)
                        chiptAction.loadRef.current.complete();
                        setCallLoader(false);
                    }
                } else {
                    toast.error(`Something went wrong`)
                    chiptAction.loadRef.current.complete();
                    setCallLoader(false);
                }

            })
    }

    useEffect(() => {
        if (!isLoading && !isActive) {
            navigate('/otpverify')
        }
    }, [passCondition, callLoader]);

    useEffect(() => {
        if (!isAuthenticated) {
            toast.success(`Please login first`);
            navigate('/signin')
        }
    }, [])

    return (
        <>
            {/* Main Container Start */}
            {callLoader ? <div className='w-[100%] md:-ml-[15%] lg:-ml-[25%] h-screen absolute flex items-center justify-center'><Project_Loader /></div> : <></>}
            <div className='w-[90%] h-auto mx-[5%] lg:w-[80%] lg:mx-[10%] md:w-[75%] md:mx-[12.5%]'>

                {/* Sub Container start */}
                <div className='w-[100%] min-h-screen h-auto overflow-auto'>

                    {/* Content Start */}
                    <div className='w-[100%] h-auto mt-4'>

                        <div className='w-[100%] h-[10rem] lg:h-[11rem]'>
                            <ProfileUpdate />
                        </div>

                        {/* Profile Imformation Update*/}
                        <div className='w-[100%] h-full float-left my-8 border border-search-box-border shadow shadow-gray-light rounded-lg py-2 px-4'>

                            <div className='w-[100%] h-max flex items-center justify-center my-2'>
                                <p className='text-normal font-semibold'>Password Update</p>
                            </div>

                            <form onSubmit={submitPasswordChange}>

                                <div className='flex items-center justify-end'>
                                    <div className={`${callLoader ? '' : 'relative'} w-[100%] rounded-lg mt-4 mb-1`}>
                                        <input type={confirPassVisible ? "text" : "password"} id="oldpassword" name='oldpassword'
                                            value={oldPass || ''}
                                            onChange={(e) => setOldPass(e.target.value)} required
                                            className={`block px-2 pb-2 pt-3 w-full text-xs bg-transparent rounded-lg appearance-none border border-gray hover:border peer`} placeholder="" autoComplete='true' />
                                        {callLoader ? <></> : <label htmlFor="oldpassword" className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Old Password</label>}
                                    </div>
                                    {callLoader
                                        ? <></>
                                        :
                                        <div className='float-left px-2 pt-3 absolute text-search-box-button' onClick={() => setConfirPassVisible(!confirPassVisible)}>
                                            {confirPassVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                                        </div>
                                    }
                                </div>

                                <div>
                                    <div className={`${callLoader ? '' : 'relative'} w-[100%] rounded-lg mt-4 mb-1`}>
                                        <input type={newPassVisible ? "text" : "password"} id="password" name='password'
                                            className={`block px-2 pb-2 pt-3 w-full text-xs ${passCondition === 2 ? 'text-red' : passCondition === 3 ? 'text-primaryBlue' : 'text-black'} bg-transparent rounded-lg appearance-none border border-gray hover:border peer`}
                                            value={newPassword ? newPassword : ''}
                                            onChange={handlePasswordChange}
                                            onClick={handlePasswordAlert}
                                            placeholder=" " required autoComplete='off' />
                                        {callLoader ? <></> : <label htmlFor="password" className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">New Password</label>}
                                    </div>
                                </div>

                                <div>
                                    <div className={`${callLoader ? '' : 'relative'} w-[100%] rounded-lg mt-4 mb-1`}>
                                        <input type="text" id="confirmPassword" name='confirmPassword'
                                            className={`block px-2 pb-2 pt-3 w-full text-xs ${passEqual ? 'text-primaryBlue' : 'text-red'} bg-transparent rounded-lg appearance-none border border-gray hover:border peer`}
                                            value={formData.confirmPassword ? formData.confirmPassword : ''}
                                            onChange={handleFormDataChange}
                                            placeholder=" " required autoComplete='off' />
                                        {callLoader ? <></> : <label htmlFor="confirmPassword" className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Confirm Password</label>}

                                    </div>
                                </div>


                                <div className='h-10 mt-6 my-4 flex justify-center'>
                                    <button className='h-auto bg-primaryBlue hover:bg-primaryBlueHover text-text-light w-[80%] rounded-xl font-bold py-1.5'>Save</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Helmet>
                <title>CHIPT | PASSWORD CHANGE</title>
            </Helmet>
        </>
    )
}

export default Chipt_Password_Update