import React from 'react';
import { useNavigate } from 'react-router-dom';
import CupDetails from '../asset/admin.jpg';

const Chipt_Show_Transactions = ({data}) => {

  const navigate = useNavigate();

  const handleTransactionDetails = (e) =>{
    e.preventDefault();
    navigate(`/single/transaction/${data._id}`)
  }

  return (
    <div key={data._id} onClick={handleTransactionDetails}>
      <div className='w-[100%] sm:w-[98%] sm:mx-[1%] h-[6rem] sm:h-[5rem] float-left mb-4 rounded-lg border-2 border-gray-light overflow-hidden'>
        <div className='w-[20%] h-[6rem] sm:h-[5rem] float-left flex items-center justify-center rounded-l-lg'>
          <img className=' w-auto h-[5.5rem] sm:h-[4.5rem] rounded-full' src={data.vendor.profilePicture.url || CupDetails} alt='Cup Image'/>
        </div>
        <div className='w-[58%] h-[6rem] sm:h-[5rem] float-left px-2'>
          <p className='w-[100%] h-auto text-lg lg:text-xl font-bold sm:font-medium mt-2  truncate overflow-hidden'>{data.vendor.name.charAt(0).toUpperCase()+data.vendor.name.slice(1)}</p>
          <p className='w-[100%] h-auto text-sm sm:text-[0.7rem] mb-4 sm:mb-0 truncate overflow-hidden'>Paid on {new Date(data.timeOftxn).toString().split('GMT')[0]}</p>
        </div>
        <div className='w-[22%] h-[6rem] sm:h-[5rem] float-left'>
          <div className='w-[100%] h-auto px-1 float-left flex items-center justify-start overflow-auto mt-4 sm:mt-4'>
            <p className='w-full h-auto text-md sm:text-sm font-semibold sm:font-medium truncate overflow-hidden text-primaryBGrn'>{data.currency.toUpperCase()+" "+data.orderAmount}</p>
          </div>
          <div className='w-[100%] h-auto px-1 float-left flex items-center justify-start overflow-auto mb-4 sm:mb-3'>
            <p className='w-full h-auto text-md sm:text-sm font-semibold sm:font-medium truncate overflow-hidden text-primaryBGrn'>{data.txnStatus.charAt(0).toUpperCase()+data.txnStatus.slice(1)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chipt_Show_Transactions;