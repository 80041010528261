import React, { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import toast from 'react-hot-toast';
import Chipt_Security_Footer from '../../NavigationComponents/Chipt_Security_Footer';
import { Call_Sign_In } from '../../../redux/authentication/CustomerAuthSlice';
import { Call_Account_Status } from '../../../redux/authentication/AccountStatusSlice';
import { ActionsContext } from '../../../context/Context';
import Project_Loader from '../../NavigationComponents/Project_Loader';
import { Helmet } from 'react-helmet';

const Chipt_Account_Sign_In = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chiptAction = useContext(ActionsContext);
  const [callLoader, setCallLoader] = useState(false);
  const [formData, setFormData] = useState({ contactNumber: '', password: '' })
  const [passVisible, setPassVisible] = useState(false);

  // Handling form input change
  const handleFormInput = (e) => {
    e.preventDefault();
    if (e.target.name === 'contactNumber' && e.target.value < 0) {
      setFormData({ ...formData, [e.target.name]: -1 * (e.target.value) });
      return
    } else {
      if (e.target.name === 'contactNumber') {

        setFormData({ ...formData, [e.target.name]: e.target.value });
        return
      }
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  }

  const onCustomerSignInFormSubmit = (e) => {
    e.preventDefault();
    chiptAction.loadRef.current.staticStart();
    setCallLoader(true);
    dispatch(Call_Sign_In(formData)).then((data) => {
      if (data.payload) {
        if (data.payload.success) {
          dispatch(Call_Account_Status())
          setCallLoader(false);
          toast.success('Login Successful', { duration: 2000 })
          chiptAction.loadRef.current.complete();
          setCallLoader(false);
          navigate('/home')
        }

      } else {
        if (data.error.message.includes('400')) {
          toast.error('Please provide all details.');
          setCallLoader(false);
        } else if (data.error.message.includes('401')) {
          toast.error('Invalid credentials.');
          setCallLoader(false);
        } else if (data.error.message.includes('403')) {
          toast.error('Dear user, your account has been suspended by admin!', {
            duration: 5000,
            position: 'top-center',
            style: {
              fontSize: '0.8rem'
            },
            className: '',
            icon: '🛑',
            iconTheme: {
              primary: '#000',
              secondary: '#fff',
            },
            ariaProps: {
              role: 'status',
              'aria-live': 'polite',
            }
          });
          setCallLoader(false);
        }
        else {
          toast.error('Something went wrong');
        }
        chiptAction.loadRef.current.complete();
        setCallLoader(false);
      }
    })
  }

  useEffect(() => { }, [callLoader])

  return (
    <>
      {/* <Toaster /> */}
      {/* Main Container Start */}
      {callLoader ? <div className='w-[100%] md:-ml-[15%] lg:-ml-[25%] h-screen absolute flex items-center justify-center'><Project_Loader /></div> : <></>}
      <div className='w-[90%] h-auto mx-[5%] overflow-auto'>

        {/* Sub Container start */}
        <div className='w-[100%] h-auto'>

          {/* Content Start */}
          <div className='w-[90%] h-auto mx-[5%]'>
            <div className='w-[100%] h-auto py-12'>
              <h1 className='font-bold text-4xl break-all'>Welcome!</h1>
              <p className='text-md break-all'>Sign in here!</p>
            </div>

            {/* Sign up form start */}
            <form className='w-[100%] h-auto' onSubmit={onCustomerSignInFormSubmit}>

              {/* Contact Number */}
              <div className={`${callLoader ? '' : 'relative'} rounded-lg mt-4 mb-1`}>
                <input type="text" id="contactNumber" name='contactNumber'
                  value={formData ? formData.contactNumber ? formData.contactNumber : '' : ''}
                  onChange={handleFormInput}
                  className="block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border-primaryBlue peer hover:outline-primaryBlue" placeholder="" autoComplete='true' />
                {callLoader ? <></> : <label htmlFor="contactNumber" className="absolute text-xs text-gray bg-white duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Contact Number / Email</label>}
              </div>

              {/* Password */}
              <div className='flex items-center justify-end'>
                <div className={`w-[100%] ${callLoader ? '' : 'relative'} rounded-lg mt-4 mb-1`}>
                  <input type={passVisible ? "text" : "password"} id="password" name='password'
                    value={formData.password || ''}
                    onChange={handleFormInput}
                    className={`block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border-primaryBlue peer hover:outline-primaryBlue`} placeholder="" autoComplete='true' />
                  {callLoader ? <></> : <label htmlFor="password" className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Password</label>}

                </div>
                {callLoader
                  ? <></>
                  :
                  <div className='float-left px-2 pt-3 absolute text-search-box-button' onClick={() => setPassVisible(!passVisible)}>
                    {passVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                  </div>
                }


              </div>
              <div className='w-full text-right mt-1'>
                <p className='text-xs text-primaryBlue'>
                  <Link to='/forgot/password'>Forgot Password</Link>
                </p>
              </div>
              <div className='flex items-center justify-center'>
                <button className='bg-primaryBlue hover:bg-primaryBlueHover hover:shadow-md w-[100%] h-auto text-xl text-white py-2 my-4 text-center rounded-4xl font-bold'>SIGN IN</button>
              </div>
            </form>


            {/* Sign up form end */}
            <div className='flex items-center justify-center mb-40'>
              <p className='text-xs'>Don't have an account?&nbsp;<Link className="font-bold underline text-sm" to='/signup'>Sign Up</Link></p>
            </div>
          </div>
          {/* Content Start */}
        </div>
        {/* Sub Container End */}

        {/* bottom footer */}
        <Chipt_Security_Footer />
        <Helmet>
          <title>CHIPT | LOGIN</title>
        </Helmet>
      </div >

      {/* Main Container End */}
    </>
  )
}

export default Chipt_Account_Sign_In;