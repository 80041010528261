import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';


export const Call_All_Orders_Details = createAsyncThunk(
    'allOrders/Call_All_Orders_Details',
    async () => {
        const response = await axios.get('/api/v1/customer/order/all');
        return response.data;
    },
);


const allOrdersSlice = createSlice({
    name: "allOrders",
    initialState: {
        status: 'idle',
        isLoading: false,
        allOrders: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            
            // Scanned Cup Details 
            .addCase(Call_All_Orders_Details.pending, (state) => {
                state.status = 'loading';
                state.isLoading = true;
                state.allOrders = null;
                state.error = null;
            })
            .addCase(Call_All_Orders_Details.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.allOrders = action.payload;
                state.error = null;
            })
            .addCase(Call_All_Orders_Details.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.allOrders = false;
                state.error = action.error.message;
            })
    },
});

export const state_Call_Fetch_Status = (state) => state.allOrders.status;
export const state_Is_Loading = (state) => state.allOrders.isLoading;
export const state_All_Orders = (state) => state.allOrders.allOrders;
export const state_Has_Error = (state) => state.allOrders.error;

export default allOrdersSlice.reducer;