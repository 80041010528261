import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { ActionsContext } from '../../../context/Context';
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast';
import ChiptLogo from '../../asset/chipt.svg';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Call_Sign_Up, state_Is_Authenticated } from '../../../redux/authentication/CustomerAuthSlice';
import { Call_Account_Status } from '../../../redux/authentication/AccountStatusSlice';
import Project_Loader from '../../NavigationComponents/Project_Loader';
import { Helmet } from 'react-helmet';

const Chipt_Account_Sign_Up = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const chiptAction = useContext(ActionsContext);
    const isAuthenticated = useSelector(state_Is_Authenticated);

    const [formData, setFormData] = useState({});
    const [countryCode, setCountryCode] = useState('MY')
    const [userPassword, setUserPassword] = useState();
    const [passAlert, setPassalert] = useState(true);
    const [passVisible, setPassVisible] = useState(false);
    const [confPassVisible, setConfPassVisible] = useState(false);
    const [passCondition, setPassCondition] = useState(1);
    const [passEqual, setPassEqual] = useState(false);
    const [trmCond, setTRMCond] = useState(false);
    const [callLoader, setCallLoader] = useState(false);

    // Password alert
    const handlePasswordAlert = (e) => {
        e.preventDefault();
        if (passAlert) {
            setPassCondition(2)
            toast(`Password should include atleast one number, one alphabet, and one special character.`, { position: 'top-right', duration: 8000 })
        }
        setPassalert(false)
    }

    // Handle form data change
    const handleFormDataChange = (e) => {
        e.preventDefault();
        if (e.target.name === 'contactNumber') {
            if (e.target.value < 0) {
                toast.error('Negative value not allowed');
                return
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value })
        if (e.target.name === 'confirmPassword') {
            if (e.target.value === userPassword && e.target.value.length >= 8) {
                setPassEqual(true)
            }
            else setPassEqual(false)
        }
    }

    const handlePasswordChange = (e) => {
        e.preventDefault();
        let numCheck = false;
        let smallChar = false;
        let capitalChar = false;
        const allowedNum = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
        const allowedSmallChar = [65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90]
        const allowedCapitalNum = [97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122]
        setUserPassword(e.target.value);
        if (!e.target.value) return
        else {
            for (let i = 0; i < e.target.value.length; i++) {
                let x = e.target.value[i]
                let charCheck = x.charCodeAt()
                if (allowedNum.includes(charCheck)) numCheck = true
                else if (allowedSmallChar.includes(charCheck)) smallChar = true
                else if (allowedCapitalNum.includes(charCheck)) capitalChar = true
            }
        }

        if (e.target.value.length >= 8) {
            if (numCheck && smallChar && capitalChar) {
                setPassCondition(3);
                if (e.target.value === formData.confirmPassword) setPassEqual(true);
                else setPassEqual(false);
            }
            else setPassCondition(2);
        } else setPassCondition(2);
        setUserPassword(e.target.value);
    }


    // Sign up submit
    const onCustomerSignUpFormSubmit = (e) => {
        e.preventDefault();
        setCallLoader(true);
        chiptAction.loadRef.current.staticStart();
        let temp = formData
        temp.countryCode = getCountryCallingCode(countryCode);
        temp.password = userPassword;
        setFormData(temp);
        if (userPassword.length < 8) {
            toast.error(`Password length should be atleast 8 character!`);
            chiptAction.loadRef.current.complete();
            setCallLoader(false);
            return
        }
        if (formData.password !== formData.confirmPassword) {
            toast.error(`Passwords didn't matched`);
            chiptAction.loadRef.current.complete();
            setCallLoader(false);
            return
        }
        dispatch(Call_Sign_Up(formData))
            .then((data) => {
                if (data.payload) {
                    if (!data.payload.success) {
                        toast.error(data.payload.message);
                    } else if (data.payload.success) {
                        dispatch(Call_Account_Status())
                        toast.success(data.payload.message);
                        chiptAction.loadRef.current.complete();
                        setCallLoader(false);
                        navigate('/otpverify')
                    }

                } else {
                    if (data.error.message.includes('400')) {
                        toast.error('User already exist either by contact number or email address.');
                        setCallLoader(false);
                    } else if (data.error.message.includes('401')) {
                        toast.error('Please provide all details.');
                        setCallLoader(false);
                    }
                    else {
                        toast.error('Something went wrong');
                        setCallLoader(false);
                    }
                    chiptAction.loadRef.current.complete();
                    setCallLoader(false);
                }
            })
    }

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/home')
        }
    }, [isAuthenticated, dispatch, trmCond]);
    useEffect(() => { }, [callLoader])

    return (
        <>
            {/* Main Container Start */}
            {callLoader ? <div className='w-[100%] md:-ml-[15%] lg:-ml-[25%] h-screen absolute flex items-center justify-center'><Project_Loader /></div> : <></>}
            <div className='w-[90%] mx-[5%] overflow-auto'>

                {/* Sub Container start */}
                <div className='w-[100%] h-auto'>

                    {/* Content Start */}
                    <div className='w-[90%] h-auto mx-[5%]'>
                        <div className='w-[100%] h-auto py-12'>
                            <h1 className='font-bold text-4xl break-all'>Welcome!</h1>
                            <p className='text-md break-all'>Sign up here!</p>
                        </div>

                        {/* Sign up form start */}
                        <form className='w-[100%] h-auto' onSubmit={onCustomerSignUpFormSubmit}>
                            <div className='w-[100%] py-2'>
                                {/* Contact Number */}
                                <div className='flex items-center justify-center'>
                                    <div className={`${callLoader ? '' : 'relative'} w-[35%] rounded-lg bg-white`}>
                                        <select type='text' id='countrySelect' name='countrySelect'
                                            value={countryCode} onChange={(e) => setCountryCode(e.target.value)}
                                            className="truncate overflow-hidden block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none bg-white border border-gray hover:outline-primaryBlue hover:border peer"
                                            placeholder=" " autoComplete='on' >
                                            <option value="">{en.ZZ}</option>
                                            {getCountries().map((country) => (
                                                <option key={country} value={country}>
                                                    {en[country]} + {getCountryCallingCode(country)}
                                                </option>
                                            ))}
                                        </select>
                                        {callLoader ? <></> : <label htmlFor="countrySelect" className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Country</label>}
                                    </div>

                                    <div className={`${callLoader ? '' : 'relative'} w-[63%] ml-[2%] rounded-lg`}>
                                        <input type="number" id="contactNumber" name='contactNumber'
                                            value={formData ? formData.contactNumber ? formData.contactNumber : '' : ''}
                                            onChange={handleFormDataChange}
                                            className="block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer hover:outline-primaryBlue"
                                            placeholder="" autoComplete='true' required />
                                        {callLoader ? <></> : <label htmlFor="contactNumber" className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Contact Number</label>}

                                    </div>
                                </div>

                                {/* Email */}
                                <div className={`${callLoader ? '' : 'relative'} rounded-lg mt-4 mb-1`}>
                                    <input type="email" id="email" name='email'
                                        value={formData ? formData.email ? formData.email : '' : ''}
                                        onChange={handleFormDataChange}
                                        className="block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer hover:outline-primaryBlue"
                                        placeholder="" autoComplete='true' required />
                                    {callLoader ? <></> : <label htmlFor="email" className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Email</label>}

                                </div>

                                {/* Password */}
                                <div className='flex items-center justify-end'>
                                    <div className={`${callLoader ? '' : 'relative'} w-[100%] rounded-lg mt-4 mb-1`}>
                                        <input type={passVisible ? "text" : "password"} id="password" name='password'
                                            value={userPassword ? userPassword : ''}
                                            onChange={handlePasswordChange}
                                            onClick={handlePasswordAlert}
                                            className={`block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer ${passCondition === 2 ? 'text-red' : passCondition === 3 ? 'text-primaryBlue' : 'text-black'} hover:outline-primaryBlue`}
                                            placeholder="" autoComplete='true' required />
                                        {callLoader ? <></> : <label htmlFor="password" className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Password</label>}

                                    </div>
                                    {callLoader
                                        ? <></>
                                        :
                                        <div className='float-left px-2 pt-3 absolute text-search-box-button' onClick={() => setPassVisible(!passVisible)}>
                                            {passVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                                        </div>
                                    }

                                </div>


                                {/* Confirm Password */}
                                <div className='flex items-center justify-end'>
                                    <div className={`${callLoader ? '' : 'relative'} w-[100%] rounded-lg mt-4 mb-1`}>
                                        <input type={confPassVisible ? "text" : "password"} id="confirmPassword"
                                            name='confirmPassword' value={formData.confirmPassword || ''}
                                            onChange={handleFormDataChange}
                                            className={`block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer ${passEqual ? 'text-primaryBlue' : 'text-red'} hover:outline-primaryBlue`}
                                            placeholder="" autoComplete='true' required />
                                        {callLoader ? <></> : <label htmlFor="confirmPassword" className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Confirm Password</label>}
                                    </div>
                                    {callLoader
                                        ? <></>
                                        :
                                        <div className='float-left px-2 pt-3 absolute text-search-box-button' onClick={() => setConfPassVisible(!confPassVisible)}>
                                            {confPassVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                                        </div>
                                    }
                                </div>

                            </div>

                            <div className='w-[100%] min-h-[3rem] h-auto flex items-center justify-center overflow-auto'>
                                <div className='flex flex-wrap text-xs break-all'>
                                    <input type='checkbox' className='w-4 h-4 mr-2' onClick={() => setTRMCond(!trmCond)} />
                                    <span className='w-max text-xs lg:font-medium'>By continuing, I agree to&nbsp;&nbsp;</span><img className='w-10' src={ChiptLogo} alt="Chipt_Logo" />
                                    <Link to="/privacypolicy" className='font-medium lg:font-bold underline decoration-1'>Privacy Policy</Link>,&nbsp;
                                    <Link to="/termcondition" className='font-medium lg:font-bold underline decoration-1'>Term & Condition</Link>,&nbsp;
                                    <span className='lg:font-medium'>&nbsp;and&nbsp;</span>
                                    <Link to="/refundpolicy" className='font-medium lg:font-bold underline decoration-1'>Refund Policy</Link>
                                </div>
                            </div>

                            <div className='flex justify-center mt-2'>
                                {trmCond
                                    ? <button className={`w-[100%] h-auto text-xl text-white py-2 my-4 rounded-4xl font-bold break-all bg-primaryBlue hover:bg-primaryBlueHover hover:shadow-lg`}>SIGN UP</button>
                                    : <span className={`w-[100%] h-auto text-xl text-white py-2 my-4 text-center rounded-4xl font-bold break-all bg-primaryBGrn cursor-not-allowed`}>SIGN UP</span>
                                }

                            </div>
                        </form>
                        {/* Sign up form end */}
                        <div className='flex items-center justify-center mb-16'>
                            <p className='text-xs break-all'>Already have an account?&nbsp;<Link className="font-bold underline text-sm" to='/signin'>Sign In</Link></p>
                        </div>
                    </div>
                    {/* Content Start */}
                </div>
                {/* Sub Container End */}

            </div>
            {/* Main Container end */}
            <Helmet>
          <title>CHIPT | REGISTRATION</title>
        </Helmet>
        </>
    );
}

export default Chipt_Account_Sign_Up;