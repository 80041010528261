import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import ProfileUpdate from './Chipt_Profile_Account_Image_Update';
import Chipt_Data_Loader from '../../NavigationComponents/Chipt_Data_Loader';
import { state_Is_Authenticated } from '../../../redux/authentication/CustomerAuthSlice';
import { Call_Get_Profile, state_Customer_Profile, state_Is_Loading } from '../../../redux/Profile/CustomerProfileSlice';
import { Helmet } from 'react-helmet';

const Chipt_Profile_Account_Details = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customerInfo = useSelector(state_Customer_Profile);
  const [customer, setCustomer] = useState();
  const isAuthenticated = useSelector(state_Is_Authenticated);
  const isLoading = useSelector(state_Is_Loading);

  useEffect(() => {
  }, [dispatch, isLoading, isAuthenticated])

  useEffect(() => {
    if (!isAuthenticated) {
      toast.success(`Please login first`);
      navigate('/signin')
    }
    else if (!customerInfo?.customer && isAuthenticated) {
      dispatch(Call_Get_Profile())
        .then((data) => {
          if (data.payload) {
            if (data.payload.customer) {
              setCustomer(data.payload.customer)
            }
          }
        });
    } else if (isAuthenticated) {
      setCustomer(customerInfo.customer)
    }
  }, [])


  return (
    <>
      {/* Main Container Start */}
      <div className='w-[90%] h-auto mx-[5%] lg:w-[80%] lg:mx-[10%] md:w-[75%] md:mx-[12.5%]'>

        {/* Sub Container start */}
        <div className='w-[100%] min-h-[76vh] overflow-auto'>

          {/* Content Start */}
          <div className='w-[100%] min-h-[84vh] mt-4'>

            <div className='w-[100%] h-max'>
              <ProfileUpdate />
            </div>

            <div className='h-9 my-8 flex justify-center'>
              <button className='w-[60%] h-auto bg-primaryNavy hover:bg-primaryNavyHover text-normal text-white hover:shadow-lg rounded-xl font-bold'><Link to='/profile/update'>Edit Profile</Link></button>
            </div>

            {/* Profile Information Update*/}
            <div className='min-h-[10rem] h-auto w-auto mb-16'>

              {isLoading
                ?
                <div className='w-[100%] h-[10rem] flex items-center justify-center'>
                  <Chipt_Data_Loader />
                </div>
                :
                <div>
                  <div>
                    <div className="relative rounded-lg my-4">
                      <input type="text" id="name" name='name'
                        value={customer ? customer.firstname + " " + customer.middlename + " " + customer.lastname : ''}
                        readOnly
                        className="block outline-none px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer" placeholder="" autoComplete='off' />
                      <label htmlFor="name" className="absolute text-xs text-primaryBlue duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Name</label>
                    </div>

                    <div className="relative rounded-lg my-4">
                      <input type="text" id="email" name='email'
                        value={customer ? customer.email : ''}
                        readOnly
                        className="block outline-none px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer" placeholder="" autoComplete='off' />
                      <label htmlFor="email" className="absolute text-xs text-primaryBlue duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Email</label>
                    </div>

                    <div className="relative rounded-lg my-4">
                      <input type="text" id="contact" name='contact'
                        value={customer ? "+" + customer.countryCode + " " + customer.contact : ''}
                        readOnly
                        className="block outline-none px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer" placeholder="" autoComplete='off' />
                      <label htmlFor="contact" className="absolute text-xs text-primaryBlue duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Contact Number</label>
                    </div>

                    <div className="relative rounded-lg my-4">
                      <input type="text" id="gender" name='gender'
                        value={customer ? customer.gender : ''}
                        readOnly
                        className="block outline-none px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer" placeholder="" autoComplete='off' />
                      <label htmlFor="gender" className="absolute text-xs text-primaryBlue duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Gender</label>
                    </div>


                    <div className="relative rounded-lg my-4">
                      <input type='date' id="dateOfBirth" name='dateOfBirth'
                        value={customer ? customer.dateOfBirth ? customer.dateOfBirth.toString().slice(0, 10) : '' : ''}
                        readOnly
                        className="block outline-none px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none text-black bg-white border border-gray hover:border peer" placeholder=" " autoComplete='off' />
                      <label htmlFor="dateOfBirth" className="absolute text-xs text-primaryBlue duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Date of Birth</label>
                    </div>

                    <div className="relative rounded-lg my-4">
                      <input type="text" id="plotnumber" name='plotnumber'
                        value={customer ? customer?.plotnumber ? customer.plotnumber : '' : ''}
                        readOnly
                        className="block outline-none px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer" placeholder=" " autoComplete='off' />
                      <label htmlFor="plotnumber" className="absolute text-xs text-primaryBlue duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Plot/Flat number</label>
                    </div>

                    <div className="relative rounded-lg my-4">
                      <input type="text" id="address" name='address'
                        value={customer ? customer?.address ? customer.address : '' : ''}
                        readOnly
                        className="block outline-none px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer" placeholder=" " autoComplete='off' />
                      <label htmlFor="address" className="absolute text-xs text-primaryBlue duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Address</label>
                    </div>

                    <div className="relative rounded-lg my-4">
                      <input type="text" id="city" name='city'
                        value={customer ? customer?.city ? customer.city : '' : ''}
                        readOnly
                        className="block outline-none px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer" placeholder=" " autoComplete='off' />
                      <label htmlFor="city" className="absolute text-xs text-primaryBlue duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">City</label>
                    </div>

                    <div className="relative rounded-lg my-4">
                      <input type="text" id="state" name='state'
                        value={customer ? customer?.state ? customer.state : '' : ''}
                        readOnly
                        className="block outline-none px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer" placeholder=" " autoComplete='off' />
                      <label htmlFor="state" className="absolute text-xs text-primaryBlue duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">State</label>
                    </div>

                    <div className="relative rounded-lg my-4">
                      <input type="text" id="country" name='country'
                        value={customer ? customer?.country ? customer.country : '' : ''}
                        readOnly
                        className="block outline-none px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer" placeholder=" " autoComplete='off' />
                      <label htmlFor="country" className="absolute text-xs text-primaryBlue duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Country</label>
                    </div>

                    <div className="relative rounded-lg my-4">
                      <input type="number" id="zipCode" name='zipCode'
                        value={customer ? customer?.zipCode ? customer.zipCode : '' : ''}
                        readOnly
                        className="block outline-none px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer" placeholder=" " autoComplete='off' />
                      <label htmlFor="zipCode" className="absolute text-xs text-primaryBlue duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">ZipCode</label>
                    </div>

                  </div>
                </div>
              }

            </div>
          </div>
        </div>
      </div>
      <Helmet>
        <title>CHIPT | PROFILE</title>
      </Helmet>
    </>
  )
}

export default Chipt_Profile_Account_Details;