import React from 'react';
import { RxDotFilled } from "react-icons/rx";
import { Helmet } from 'react-helmet';

const TermAndCondition = () => {
  return (
    <div className='w-full h-max flex items-center justify-center'>
            <Helmet>
        <title>CHIPT | TERMS & CONDITION</title>
      </Helmet>
      <div className='w-[92%] mx-[4%] h-auto overflow-auto mt-4 mb-16'>
        <p className='w-full h-auto py-2 px-4 text-2xl font-bold text-center'>Term and Condition</p>
        <div className='w-[100%] h-auto my-4'>
          <p className='w-[100%] h-auto text-lg font-semibold break-all'>1. Introduction</p>
          <p className='w-[100%] h-auto text-xs sm:text-xs font-normal break-word'>These Terms and Conditions ("Terms") govern your use of the CHIPT smart cup system (the "System") and any services offered through the System (the "Services"). The System and Services are owned and operated by CHIPT Smart Cup System ("We," "Us," or "Our"). By using the System or Services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use the System or Services.</p>
        </div>

        {/* 2 */}
        <div className='w-[100%] h-auto my-8'>
          <p className='w-[100%] h-auto text-lg font-semibold break-all'>2. Definitions</p>
          <div className='w-[100%] h-auto'>
            <p className='text-xs sm:text-xs font-normal break-word'>In these Terms, the following terms have the following meanings:</p>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal break-word'>"Account" means an account that you create with Us in order to use the Services.</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>"Content" means any text, images, audio, video, or other materials that you upload, post, or otherwise transmit through the System or Services.</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>"Cup" means a reusable cup that is equipped with an NFC tag.</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>"NFC Tag" means a small, electronic tag that can be read by a smartphone or other device.</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>"Third Party Site" means any website or online service that is not owned or operated by Us.</p>
            </div>
          </div>
        </div>

        {/* 3 */}
        <div className='w-[100%] h-auto my-8'>
          <p className='w-[100%] h-auto text-lg font-semibold  break-all'>3. Registration and Account</p>
          <p className='w-[100%] h-auto text-xs sm:text-xs font-normal  break-word'>In order to use certain features of the System or Services, you may be required to register for an Account. When you register for an Account, you agree to provide Us with accurate and complete information about yourself. You are also responsible for maintaining the confidentiality of your Account login information and for all activities that occur under your Account. You agree to notify Us immediately of any unauthorized use of your Account.</p>
        </div>

        {/* 4 */}
        <div className='w-[100%] h-auto my-8'>
          <p className='w-[100%] h-auto text-lg font-semibold break-all'>4. User Conduct</p>
          <div className='w-[100%] h-auto'>
            <p className='text-xs sm:text-xs font-normal break-word'>You agree to use the System and Services in a responsible and lawful manner. You further agree not to:</p>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal break-word'>Use the System or Services for any illegal or unauthorized purpose.</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>Use the System or Services to transmit, post, or otherwise make available any Content that is harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>Use the System or Services to impersonate any person or entity, or to forge or otherwise misrepresent your affiliation with a person or entity.</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>Use the System or Services to upload, post, or otherwise transmit any Content that you do not have the right to transmit under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements).</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>Use the System or Services to upload, post, or otherwise transmit any Content that contains any viruses, Trojan horses, worms, time bombs, cancelbots, or other harmful or disruptive code.</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>Use the System or Services to interfere with or disrupt the System or Services or servers or networks connected to the System or Services, or to disobey any requirements, procedures, policies, or regulations of networks connected to the System or Services.</p>
            </div>
            <div className='flex items-start justify-start'>
              <p className='w-[3%] h-full float-left'><RxDotFilled className='text-lg' /></p>
              <p className='w-[96%] ml-[1%] sm:ml-[4%] sm:w-[93%] h-auto float-left text-xs sm:text-xs font-normal'>Use the System or Services to violate any applicable laws or regulations.</p>
            </div>
          </div>
        </div>

        {/* 5 */}
        <div className='w-[100%] h-auto my-8'>
          <p className='w-[100%] h-auto text-lg font-semibold  break-all'>5. Intellectual Property Rights</p>
          <p className='w-[100%] h-auto text-xs sm:text-xs font-normal break-word'>All Content on the System and Services is protected by copyright, trademark, and other intellectual property laws. You may not use any Content on the System or Services without Our prior written permission.</p>
        </div>

        {/* 6 */}
        <div className='w-[100%] h-auto my-8'>
          <p className='w-[100%] h-auto text-lg font-semibold  break-all'>6. Third Party Sites</p>
          <p className='w-[100%] h-auto text-xs sm:text-xs font-normal break-word'>The System or Services may contain links to Third Party Sites. We are not responsible for the content or practices of Third-Party Sites. We recommend that you review the privacy policies and terms of use of all Third-Party Sites that you visit.</p>
        </div>

        {/* 7 */}
        <div className='w-[100%] h-auto my-8'>
          <p className='w-[100%] h-auto text-lg font-semibold  break-all'>7. Disclaimer</p>
          <p className='w-[100%] h-auto text-xs sm:text-xs font-normal break-word'>The System and Services are provided "as is" and "as available". We disclaim all warranties, express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that the System or Services will be uninterrupted or error-free.</p>
        </div>

        {/* 8 */}
        <div className='w-[100%] h-auto my-8'>
          <p className='w-[100%] h-auto text-lg font-semibold  break-all'>8. Limitation of Liability</p>
          <p className='w-[100%] h-auto text-xs sm:text-xs font-normal break-word'>In no event shall We be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising out of your use of the System or Services, including, but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses.</p>
        </div>

        {/* 9 */}
        <div className='w-[100%] h-auto my-8'>
          <p className='w-[100%] h-auto text-lg font-semibold  break-all'>9. Indemnification</p>
          <p className='w-[100%] h-auto text-xs sm:text-xs font-normal break-word'>You agree to indemnify, defend, and hold Us harmless from and against any and all claims, liabilities, damages, losses, costs, expenses, and fees (including reasonable attorneys' fees) arising out of or in connection with your use of the System or Services.</p>
        </div>

        {/* 10 */}
        <div className='w-[100%] h-auto my-8'>
          <p className='w-[100%] h-auto text-lg font-semibold  break-all'>10. Termination</p>
          <p className='w-[100%] h-auto text-xs sm:text-xs font-normal break-word'>We may terminate your Account or your use of the System or Services at any time, without notice or cause. Upon termination, you will no longer have access to the System or Services.</p>
        </div>

        {/* 11 */}
        <div className='w-[100%] h-auto my-8'>
          <p className='w-[100%] h-auto text-lg font-semibold  break-all'>11. Governing Law</p>
          <p className='w-[100%] h-auto text-xs sm:text-xs font-normal break-word'>These Terms are governed by and construed in accordance with the laws of Malaysia.</p>
        </div>

      </div>
    </div>
  )
}

export default TermAndCondition