import React from 'react';
import { useNavigate } from 'react-router-dom';
import CupDetails from '../asset/cupDetails.svg';

const Chipt_Show_Orders = ({data}) => {

  const navigate = useNavigate();
  const singleOrderDetails = (e) =>{
    e.preventDefault();
    navigate(`/single/order/${data._id}`)
  }

  return (
    <div key={data._id} onClick={singleOrderDetails}>
      <div className='w-[98%] h-[5.5rem] mx-[1%] float-left mb-4 rounded-lg shadow border-2 border-gray-light overflow-hidden'>
        <div className='w-[25%] h-[5rem] float-left flex items-center justify-center p'>
          <img className=' w-[100%] h-[4rem]' src={CupDetails} alt='Cup Image'/>
        </div>
        <div className='w-[75%] h-[8rem] float-left px-2 py-[0.75rem]'>

          <div className='w-[100%] h-[1rem] float-left text-[0.65rem] truncate overflow-hidden'>
            <i className='w-[75%] float-left text-primaryBlue truncate overflow-hidden'>{new Date(data.createdAt).toLocaleString()}</i>
            <i className={`w-[25%] float-left text-[0.75rem] pr-2 text-right ${data.orderStatus === 'fail' ? 'text-red' : 'text-primaryBlue'} truncate overflow-hidden`}>{data.orderStatus.charAt(0).toUpperCase()+data.orderStatus.slice(1)}</i>
          </div>

          <div className='w-[100%] h-[1.455rem] float-left'>
            <span className='text-md font-bold'>{data.cupID.cupType.charAt(0).toUpperCase()+data.cupID.cupType.slice(1)}</span>&nbsp;&nbsp;<i className='text-[1rem]'>{data.cupID.cupSize.charAt(0).toUpperCase()+data.cupID.cupSize.slice(1)}</i>
          </div>
          <div className='w-[100%] h-[1rem] float-left text-sm sm:text-xs font-normal truncate overflow-hidden'><i>{data.orderStatus ==='success' ?  'Bought from ': 'Buying from '} {data.fromVendor.name.charAt(0).toUpperCase()+data.fromVendor.name.slice(1)}</i></div>
        </div>
      </div>
    </div>
  )
}

export default Chipt_Show_Orders