import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import ChiptLogo from '../asset/chipt.svg';
import Chipt_Security_Footer from '../NavigationComponents/Chipt_Security_Footer';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import { Helmet } from 'react-helmet';

const Chipt_Information_Screen = () => {
  const navigate = useNavigate()
  return (
    <>
          <Helmet>
        <title>CHIPT | INFORMATION</title>
      </Helmet>
      {/* Main container start */}
      <div className='w-[100%] h-screen'>

        <div className='w-[100%] h-auto float-left overflow-hidden'>
          {/* Sub Container start */}
          <div className='w-[10%] h-[39rem] text-xs float-left flex items-center justify-center animate-ping' onClick={()=>navigate('/')}><MdOutlineArrowBackIos /></div>
          <div className='w-[80%] h-[39rem] float-left'>

            {/* navigation start */}
            <div className='w-[100%] h-[3rem] mx-0 flex items-center justify-center'>
              <div className='w-[50%] h-[3rem] flex items-center justify-start'>
                <Link to='/'><button className='float-left bg-gray-light rounded-xl text-xs font-bold px-2 py-1'>BACK</button></Link>
              </div>
              <div className='w-[50%] h-[3rem] flex items-center justify-end'>
                <Link to="/cup/scan"><button className='float-right bg-gray-light rounded-xl text-xs font-bold px-2 py-1' >NEXT</button></Link>
              </div>
            </div>
            {/* navigation end */}

            {/* Content start */}
            <div className='w-[100%] h-[35rem] mx-0'>
              <div className='pt-24 sm:pt-20'>
                <img className='w-44 h-24 mt-2' src={ChiptLogo} alt="Chipt_Logo" />
              </div>
              <div className='my-4'>
                <p className='text-xl font-small break-word'>Welcome ! Join our mission to eliminate all single-use of your coffee cups</p>
                <Link to='/cup/scan'><p className='mt-12 text-2xl font-medium underline decoration-1 break-all'>See how it works...</p></Link>

              </div>
            </div>
            {/* Content end */}
          </div>
          <div className='w-[10%] h-[39rem] text-xs float-right flex items-center justify-center animate-ping' onClick={()=>navigate('/cup/scan')}><MdOutlineArrowForwardIos /></div>
          {/* Sub Container end */}
        </div>


        {/* bottom footer */}
        <div className='w-[100%] h-auto float-left overflow-auto'> <Chipt_Security_Footer /></div>
       
      </div>
      {/* Main container end */}
    </>

  )
}

export default Chipt_Information_Screen;