import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FaSearch } from 'react-icons/fa';
import NFCScanBar from '../NavigationComponents/Chipt_Footer_NFC_Bar';
import Loader from '../NavigationComponents/Chipt_Loader';
import Chipt_Show_Vendor from '../VendorComponents/Chipt_Show_Vendor';
import { useDispatch, useSelector } from 'react-redux';
import { Call_Get_All_Vendors, state_Is_Loading, state_Vendors } from '../../redux/authentication/AllVendorSlice';
import { BiErrorCircle } from "react-icons/bi";
const Chipt_Home_Dashboard = () => {

  const dispatch = useDispatch();
  const isLoading = useSelector(state_Is_Loading);
  const vendors = useSelector(state_Vendors);
  const [searchTerm, setSearchTerm] = useState();

  const handleSearchValueChange = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
    dispatch(Call_Get_All_Vendors({ srch: e.target.value, loc: {} }))
  }
  const searchItemSubmit = (e) => {
    e.preventDefault();
    if(searchTerm) dispatch(Call_Get_All_Vendors({ srch: searchTerm, loc: {} }))
  }

  useEffect(()=>{
    dispatch(Call_Get_All_Vendors({ srch: '', loc: {} }))
  }, [])


  return (
    <>
      {/* Main container start */}
      <div className='w-[100%] min-h-screen h-auto'>
        {/* Sub Container start */}
        <div className='w-[100%] h-auto float-left' >

          {/* Content Start */}
          <div className='w-[86%] min-h-[5rem] h-auto mx-[7%] flex flex-wrap'>
            <div className='w-[100%] h-[3rem] mt-[2rem] mb-[1rem] flex items-center justify-center shadow-lg bg-white hover:border hover:border-gray-light hover:shadow-xl shadow-card-shadow-ligh-dark rounded-2xl border border-search-box-border overflow-hidden'>
              <form className='w-[100%] h-[3rem] flex items-center justify-center' onSubmit={searchItemSubmit}>
                <input type='text' name='searchTerm' value={searchTerm ? searchTerm : ''} onChange={handleSearchValueChange}
                  className='w-[83%] float-left px-4 outline-none rounded-2xl'
                  placeholder='Search for vendor' autoComplete='on' />
                <span className='w-[2%] h-full text-xl text-search-box-button float-left flex justify-center items-center'>|</span>
                <button className='w-[15%] h-[3rem] float-left py-2 text-xl text-gray hover:bg-gray hover:text-white flex justify-center items-center rounded-2xl'><FaSearch />&nbsp;&nbsp;</button>
              </form>
            </div>
          </div>

          {/* All Vendors */}
          <div className='w-[100%] h-[4rem] float-left'>
            <div className='w-[100%] float-left h-[4rem] text-xl text-gray font-bold flex items-center justify-center'>
              <div className='w-full flex items-center justify-center'>
                <p className='w-full h-[1px] float-left bg-gray'></p>
                <p className='w-full px-1  text-center float-left'>All Vendors</p>
                <p className='w-full h-[1px] float-left bg-gray'></p>

              </div>
            </div>
          </div>
          <div className='w-[100%] h-auto float-left bg-vendor-bg rounded-3xl py-4 overflow-auto mb-20'>
            <div className='w-[100%] min-h-[30rem] h-auto float-left pb-2 overflow-auto'>
              {isLoading ? <div className='w-[100%] min-h-[30rem] flex items-center justify-center'><Loader /></div> :
                vendors?.success && vendors.vendors.length > 0
                  ? <Chipt_Show_Vendor vendorsData={vendors.vendors} />
                  :
                  <div className='w-[100%] h-[30rem] flex items-center justify-center'>
                    <div className='w-[100%] h-auto block text-center'>
                      <p className='w-[100%] flex items-center justify-center'><BiErrorCircle className='text-[5rem] text-gold' /></p>
                      <p className='w-[100%] text-xs text-center break-word my-1'>No data exist related to what you are looking for!</p>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
        {/* Sub Container end */}
        <div className='w-[100%] h-auto flex items-center justify-center overflow-hidden'>
          <NFCScanBar />
        </div>

      </div>
      {/* Main container end */}
      <Helmet>
        <title>CHIPT | HOME</title>
      </Helmet>
    </>
  )
}

export default Chipt_Home_Dashboard;