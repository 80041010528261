import { createContext } from "react";

export const ActionsContext = createContext(
    {
        sideNavBar: false,
        isPopUp: false,
        notiNavBar: false,
        loadRef: null,
        screenSize: 0,
        setScreenSize: ()=>{},
        scan: null,
        write: null,
        setActions:()=>{},
        isActive: false,
        setUserActive: ()=>{},

    }
);

export const CupsArrayContext = createContext(
    [
        {
            _id: '1',
            cupType: 'normal',
            cupSize: 'small',
            cupCapacity: '200 ml',
            price: 1,
            point: '4',
        },
        {
            _id: '2',
            cupType: 'normal',
            cupSize: 'medium',
            cupCapacity: '400 ml',
            price: 2,
            point: '7',
        },
        {
            _id: '3',
            cupType: 'normal',
            cupSize: 'large',
            cupCapacity: '750 ml',
            price: 3,
            point: '10',
        },
        {
            _id: '4',
            cupType: 'millennium',
            cupSize: 'small',
            cupCapacity: '250 ml',
            price: 1.5,
            point: '8',
        },
        {
            _id: '5',
            cupType: 'millennium',
            cupSize: 'medium',
            cupCapacity: '500 ml',
            price: 2.5,
            point: '11',
        },
        {
            _id: '6',
            cupType: 'millennium',
            cupSize: 'large',
            cupCapacity: '750 ml',
            price: 4,
            point: '8',
        },
        {
            _id: '7',
            cupType: 'premium',
            cupSize: 'small',
            cupCapacity: '250 ml',
            price: 2,
            point: '20',
        },
        {
            _id: '8',
            cupType: 'premium',
            cupSize: 'medium',
            cupCapacity: '500 ml',
            price: 3,
            point: '27',
        },
        {
            _id: '9',
            cupType: 'premium',
            cupSize: 'large',
            cupCapacity: '750 ml',
            price: 4,
            point: '30',
        },
        {
            _id: '10',
            cupType: 'diamond',
            cupSize: 'small',
            cupCapacity: '250 ml',
            price: 5,
            point: '40',
        },
        {
            _id: '11',
            cupType: 'diamond',
            cupSize: 'medium',
            cupCapacity: '500 ml',
            price: 7,
            point: '60',
        },
        {
            _id: '12',
            cupType: 'diamond',
            cupSize: 'large',
            cupCapacity: '750 ml',
            price: 9,
            point: '90',
        },
        {
            _id: '13',
            cupType: 'diamond',
            cupSize: 'extra large',
            cupCapacity: '1000 ml',
            price: 12,
            point: '120',
        }
    ]
);
