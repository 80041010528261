import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import CustomerAuthReducer from "./authentication/CustomerAuthSlice.js";
import customerProfileReducer from './Profile/CustomerProfileSlice.js';
import customerProfileImageReducer from './Profile/CustomerProfileImageSlice.js';
import CustomerPasswordReducer from './authentication/ForgotPasswordSlice.js';
import customerNotificationReducer from './Profile/CustomerNotificationSlice.js';
import customerAccountVerified from './authentication/AccountStatusSlice.js';
import AllVendorsReducer from './authentication/AllVendorSlice.js';
import vendorStoreCupReducer from './authentication/StoreAllCupSlice.js';
import accountOTPReducer from './authentication/AccountOTPSlice.js';
import scannedCupReducer from './orders/ScannedCupSlice.js';
import allOrdersReducer from './orders/AllOrderSlice.js';
import singleOrderReducer from './orders/SingleOrderSlice.js';
import allTxnReducer from './transactions/AllTransactionSlice.js';
import singleTxnReducer from './transactions/SingleTransactionsSlice.js';
import customerBoughtCupsReducer from './orders/CustomerAllOrderedCups.js';
import customerNewOrderReducer from './orders/NewOrderCreation.js';
import customerTxnStatusReducer from './transactions/TransactionStatus.js';
import CustomerReturnCupReducer from './transactions/ReturnCupSlice.js';
import CustomerReturableCupsReducer from './orders/CustomerReturnableCupSlice.js'

const persistConfig = {
  key: 'root',
  storage,
}

const rootReduces = combineReducers({
  // Customer
  customerAuth: CustomerAuthReducer,
  customer: customerProfileReducer,
  customerProfileImage: customerProfileImageReducer,
  passwordReset: CustomerPasswordReducer,
  accountStatus: customerAccountVerified,
  customerNotification: customerNotificationReducer,

  // Vendor 
  allVendors: AllVendorsReducer,
  vendorStoreCup: vendorStoreCupReducer,
  accountOTP: accountOTPReducer,
  scannedCup: scannedCupReducer,

  // Orders
  allOrders: allOrdersReducer,
  singleOrder: singleOrderReducer,
  customerNewOrder: customerNewOrderReducer,

  // Transactions
  allTxn: allTxnReducer,
  singleTxn: singleTxnReducer,
  userTXNStatus: customerTxnStatusReducer,

  // Cups
  boughtCups: customerBoughtCupsReducer,
  customerReturnCup: CustomerReturnCupReducer,
  returnAbleCups: CustomerReturableCupsReducer
})

const persistedReducer = persistReducer(persistConfig, rootReduces)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

export const persistor = persistStore(store)
