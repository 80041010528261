import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { BsPatchCheckFill } from 'react-icons/bs';
import { BiSolidErrorAlt } from 'react-icons/bi';
import { GiReceiveMoney } from "react-icons/gi";
import { state_Is_Loading, state_Transaction_Status } from '../../redux/transactions/TransactionStatus';
import Project_Loader from '../NavigationComponents/Project_Loader';
import { state_Is_Authenticated } from '../../redux/authentication/CustomerAuthSlice';
import { Helmet } from 'react-helmet';
const TransactionStatus = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [txnStatus, setTXNstatus] = useState(33);
    const isLoading = useSelector(state_Is_Loading);
    const transactionStatus = useSelector(state_Transaction_Status);
    const isAuthenticated = useSelector(state_Is_Authenticated);

    useEffect(() => {
        if (!isAuthenticated) {
            toast.success(`Please login first`);
            navigate('/signin')
        }
        if (transactionStatus && transactionStatus?.transaction?.txnStatus.toLowerCase() === 'success') {
            setTXNstatus(11)
        } else if (transactionStatus && transactionStatus?.transaction?.txnStatus.toLowerCase() === 'fail') {
            setTXNstatus(22)
        } else {
            setTXNstatus(33)
        }
    }, [isLoading, dispatch, txnStatus])

    return (

        <div className='w-[100%] min-h-screen h-auto overflow-auto'>
            <div className='w-[100%] min-h-screen h-auto'>
                {isLoading ? <div className='w-[100%] min-h-screen h-auto absolute'><Project_Loader /></div>
                    :
                    <>
                        <div className='w-[100%] float-left h-auto my-4 overflow-auto'>
                            {txnStatus === 11
                                ?
                                <>
                                    <div className='w-[100%] h-auto'>
                                        <p className='w-[100%] h-auto float-left mt-2 mb-8 text-3xl text-center text-primaryBGrn font-bold'>Success!</p>
                                        <div className={`w-[100%] h-auto float-left flex flex-wrap items-center justify-center ${isLoading ? '' : 'animate-pulse'} `}><BsPatchCheckFill className='text-[16rem] text-primaryBGrn' /></div>
                                    </div>
                                </>
                                : <>{
                                    txnStatus === 22
                                        ?
                                        <>
                                            <div className='w-[100%] h-auto'>
                                                <p className='w-[100%] h-auto float-left mt-2 mb-8 text-3xl text-center text-red font-bold'>Fails!</p>
                                                <div className={`w-[100%] h-auto float-left flex flex-wrap items-center justify-center  ${isLoading ? '' : 'animate-pulse'} `}><BiSolidErrorAlt className='text-[16rem] text-red' /></div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className='w-[100%] h-auto'>
                                                <p className='w-[100%] h-auto float-left mt-2 mb-8 text-3xl text-center font-bold'>Pending!</p>
                                                <div className={`w-[100%] h-auto float-left flex flex-wrap items-center justify-center  ${isLoading ? '' : 'animate-pulse'} `}><GiReceiveMoney className='text-[16rem] text-gray' /></div>
                                            </div>
                                        </>
                                }</>
                            }
                        </div>
                        <div className='w-[100%] h-auto mt-[6rem] float-left flex items-center justify-center overflow-auto'>
                            <button className='px-8 py-1 text-[1.25rem] text-white font-bold bg-primaryBlue rounded-md' onClick={() => navigate('/home')}>Home</button>
                        </div>
                    </>
                }
            </div>
            <Helmet>
        <title>CHIPT | PAYMENT STATUS</title>
      </Helmet>
        </div>
    )
}

export default TransactionStatus;