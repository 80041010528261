import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { Call_Single_Order_Details } from '../../redux/orders/SingleOrderSlice';
import { CiCoffeeCup } from "react-icons/ci";
import { ActionsContext } from '../../context/Context';
import Project_Loader from '../NavigationComponents/Project_Loader';
import { state_Is_Authenticated } from '../../redux/authentication/CustomerAuthSlice';
import ShopImage from '../asset/admin.jpg';
import {Helmet} from 'react-helmet';

const Chipt_Single_Order_Details = () => {

  const navigate = useNavigate()
  const { id } = useParams();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state_Is_Authenticated);
  const [order, setOrder] = useState()
  const [orderStatus, setOrderStatus] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const chiptAction = useContext(ActionsContext);
  const [callLoader, setCallLoader] = useState(false);


  useEffect(() => {
    if (!isAuthenticated) {
      toast.success(`Please login first`);
      navigate('/signin')
    }
    setLoading(true);
    setCallLoader(true);
    chiptAction.loadRef.current.staticStart();
    dispatch(Call_Single_Order_Details(id))
      .then((data) => {
        if (data.payload) {
          if (data.payload.success) {
            setOrder(data.payload.order);
            if (data.payload.order.orderStatus.toLowerCase() == 'success') {
              setOrderStatus(true);
              chiptAction.loadRef.current.complete();
              setCallLoader(false);
            }
            else {
              setOrderStatus(false);
              chiptAction.loadRef.current.complete();
              setCallLoader(false)
            }
            setLoading(false);
            chiptAction.loadRef.current.complete();
            setCallLoader(false)
          }
        } else {
          setLoading(false);
          chiptAction.loadRef.current.complete();
          setCallLoader(false)
        }
      })
  }, [dispatch]);

  useEffect(() => { }, [callLoader])

  return (
    <>
      {/* Navbar */}

      {/* Main Container Start */}
      {callLoader ? <div className='w=[100%] h-screen'><Project_Loader /></div>
        :
        <>
          <div className='w-[100%] lg:w-[90%] lg:mx-[5%] h-auto float-left overflow-auto'>

            <h2 className='text-center text-2xl font-bold my-2'>Order Detail</h2>
            {/* Sub Container Start */}
            <div className='w-[94%] h-auto mx-[3%] my-2 border rounded-xl border-gray-light shadow-sm shadow-gray-light overflow-auto'>

              {/* Order Details */}
              <div className='w-[100%] h-auto flex items-center justify-center rounded-t-xl overflow-auto '>
                {/* <div className='w-[94%] mx-[3%] min-h-[8rem] h-auto flex items-center justify-center rounded-t-xl overflow-auto border-b border-search-box-border'> */}

                <div className='w-[100%] h-auto float-left'>
                  {/* <div className='w-[98%] h-auto py-4 float-left'> */}

                  <div className='w-[100%] h-[4rem] px-4 text-sm font-normal float-left grid grid-cols-1 place-items-center bg-primaryNavy'>
                    <p className='w-full h-auto mt-[1rem] text-left text-white font-semibold flex items-center justify-start'>
                      ORDER STATUS&nbsp; {!isLoading && orderStatus && order ? <i className='text-green'>Success</i> : <>{isLoading ? <i className='text-black'>Loading...</i> : <i className='text-red'>Fail</i>}</>}
                    </p>
                    <p className='w-full h-auto pb-2 text-left text-[0.7rem] text-white font-semibold flex items-center justify-start'>
                      {!isLoading && orderStatus && order ? <i className='text-green'>{new Date(order.createdAt).toDateString()}</i> : <>{isLoading ? <i className='text-black'>Loading...</i> : <i className='text-red line-through'></i>}</>}
                    </p>
                  </div>
                  <div className='w-[90%] mx-[5%] h-auto text-sm px-2 my-4 pb-8 font-normal float-left grid grid-cols-1 border-b border-search-box-border'>
                    <p className='w-[100%] truncate py-0.5 overflow-hidden'>AMOUNT:&nbsp;{!isLoading && orderStatus && order ? <i className='text-green'>RM{' ' + order.orderAmount}</i> : <>{isLoading ? <i className='text-black'>Loading...</i> : <i className='text-red line-through'>RM{' 0'}</i>}</>}</p>
                    <p className='w-[100%] truncate py-0.5 overflow-hidden'>TXN ID:&nbsp;{!isLoading && orderStatus && order ? <i className='text-green'>{order.tnxID}</i> : <>{isLoading ? <i className='text-black'>Loading...</i> : <i className='text-red line-through'></i>}</>}</p>
                  </div>
                </div>
              </div>

              {/* Cup Details */}
              <div className='w-[94%] mx-[3%] min-h-[9rem] h-auto flex items-center justify-center overflow-auto border-b border-search-box-border'>
                <div className='w-[98%] h-auto py-4 float-left'>
                  <div className='w-[6rem] h-[6rem] flex items-center justify-center float-left rounded-sm overflow-hidden'>
                    <div className='w-[5.5rem] h-[5.5rem] overflow-hidden'>
                      {!isLoading && orderStatus && order.cupID.cupImages[0]?.url ?
                        <><img className="w-[5.25rem] h-[5.25rem] rounded-sm" src={order.cupID.cupImages[0]?.url} alt='Cup_Image' /></>
                        : <div className='w-[5.5rem] h-[5.5rem]'><CiCoffeeCup className='text-[5.35rem] text-primaryBlue' /></div>
                      }
                    </div>
                  </div>
                  <div className='w-[60%] h-[6rem] float-left px-2'>
                    <p className='w-[100%] h-[1.85rem] text-xl font-bold break-word truncate overflow-hidden'>{!isLoading && orderStatus && order ? order.cupID.cupType.charAt(0).toUpperCase() + order.cupID.cupType.slice(1) : <>Loading...</>}</p>
                    <p className='w-[100%] h-[1.65rem] text-base font-medium break-word truncate overflow-hidden'>{!isLoading && orderStatus && order ? <>{order.cupID.cupSize}&nbsp;<i className='text-lg sm:text-xs'>{order.cupID.cupCapacity} ml</i></> : <>Loading...</>}</p>
                    <p className='w-[100%] h-[1.15rem] text-[0.7rem] break-word truncate overflow-hidden'><i>Model ID:&nbsp;</i>{!isLoading && orderStatus && order ? order.cupID.cupModelUniqueId : <>Loading...</>}</p>
                    <p className='w-[100%] h-[1.15rem] text-[0.7rem] break-word truncate overflow-hidden'><i>Unique ID:&nbsp;</i>{!isLoading && orderStatus && order ? order.cupUniqueId : <>Loading...</>}</p>
                  </div>
                </div>
              </div>



              {/* Vendor Container Start */}
              <div className='w-[90%] mx-[5%] min-h-[12rem] h-auto p-4 flex flex-wrap items-center justify-center overflow-auto'>
                <div className='w-[100%] h-[12rem] float-left flex items-center justify-start'>
                  <img className="w-[11rem] h-[11rem] rounded-sm m-1" src={!isLoading && orderStatus && order ? order.fromVendor.profilePicture.url : ShopImage} alt='SHop_Image' />
                </div>
                <div className='w-[100%] min-h-[10rem] h-auto float-left'>
                  <p className='w-[100%] h-[2.25rem] text-3xl text-navy font-semibold break-word truncate overflow-hidden mt-2'>{!isLoading && orderStatus && order ? <>{order.fromVendor.name.charAt(0).toUpperCase() + order.fromVendor.name.slice(1)}</> : <>{isLoading ? <>Loading...</> : <></>}</>}</p>
                  <p className='w-[100%] h-[1.65rem] text-base font-medium break-word truncate overflow-hidden'>{!isLoading && orderStatus && order ? <>{order.fromVendor.primaryEmail}</> : <>{isLoading ? <>Loading...</> : <></>}</>}</p>
                  <p className='w-[100%] h-[1.65rem] text-base font-medium break-word truncate overflow-hidden'>{!isLoading && orderStatus && order ? <>{"+" + order.fromVendor?.countryCode + " " + order.fromVendor?.primaryContactNumber}</> : <>{isLoading ? <>Loading...</> : <></>}</>}</p>
                  <p className='w-[100%] h-[4.55rem] text-sm break-word font-normal overflow-hidden'>{!isLoading && orderStatus && order ? <>{order.fromVendor?.plotnumber + " " + order.fromVendor?.address + " " + order.fromVendor?.city + ", " + order.fromVendor?.state + ", " + order.fromVendor?.country}</> : <>{isLoading ? <>Loading...</> : <></>}</>}</p>
                </div>
              </div>
              {/* Vendor Container End */}

            </div>

            {/* Sub Container End */}
          </div>
        </>
      }


      {/* Main Container End */}
      <Helmet>
        <title>CHIPT | SINGLE ORDER</title>
      </Helmet>
    </>
  )
}

export default Chipt_Single_Order_Details