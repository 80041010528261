import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import PaymentCup from '../asset/paymentCup.svg';
import { GoDotFill } from 'react-icons/go';
import { useToggle, useVibrate } from 'react-use';
import { ActionsContext } from '../../context/Context';
import { Helmet } from 'react-helmet';

const Chipt_Payment_Success = () => {

  const sideNavBar = useContext(ActionsContext);
  const navigate = useNavigate();

  const [vibrating, toggleVibrating] = useToggle(false);
  useVibrate(vibrating, [10], false);

  const joinUsOnClick = (e) => {
    e.preventDefault();

    if (sideNavBar.screenSize.current.width < 800) {
      setTimeout(() => {
        toggleVibrating();
      }, 0);

      setTimeout(() => {
        toggleVibrating();
        navigate('/signup')
      }, 500)
    } else {
      setTimeout(() => {
        navigate('/signup')
      }, 100)
    }
  }

  return (
    <>
          <Helmet>
        <title>CHIPT | PAYMENT</title>
      </Helmet>
      {/* Main container start */}
      <div className='w-[100%] h-screen'>

        {/* Sub Container start */}
        <div className='w-[90%] h-[44rem] mx-[5%]' >

          {/* Patment text */}
          <div className='h-[4rem] flex items-center justify-center'>
            <h1 className='text-[1.5rem] font-medium break-all'>Payment Successfull!</h1>
          </div>

          {/* Payment cup image */}
          <div className='h-[35rem] flex items-center justify-center overflow-hidden'>
            <img className='w-auto h-[35rem] mt-[7rem]' src={PaymentCup} alt="Chipt_Logo" />
          </div>
          <div className='h-[3rem] flex items-center justify-center'>
            <button className='bg-primaryBlue text-text-light text-lg font-bold py-1 px-2 rounded-xl break-all' onClick={joinUsOnClick}>JOIN US</button>
          </div>
          {/* Content end */}
        </div>
        {/* Sub Container end */}

        {/* bottom footer */}
        <div className='w-[80%] h-[2rem] mx-[10%] float-left text-center'>
          <div className='flex justify-center text-xl'>
            <p className='text-gray-light'><GoDotFill /></p>
            <p className='text-gray-light'><GoDotFill /></p>
            <p><GoDotFill /></p>
          </div>
        </div>

      </div>
      {/* Main container end */}
    </>
  )
}

export default Chipt_Payment_Success;