import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import random_logo from '../asset/admin.jpg';
import Loader from '../NavigationComponents/Chipt_Loader';

const Chipt_Show_Vendor = ({ vendorsData }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const vendorStoreDetails = (id) => {
    navigate(`/vendor/store/${id}`)
  }

  useEffect(() => {
  }, [dispatch])

  return (
    <>
      <div className='w-[97%] h-auto mx-[1.5%] flex flex-wrap'>
        {
          vendorsData ?
            <>
              {vendorsData.map((data) => {
                return (
                  <div key={data._id} onClick={() => vendorStoreDetails(data._id)} className='w-[46%] h-[10rem] mx-[2%] my-3 rounded-lg bg-vendor-card-bg shadow-xl hover:shadow-2xl'>
                    <div className='px-4 overflow-hidden'>
                      <div className='h-[6rem] flex items-center justify-center overflow-hidden'>
                        <img className='w-auto h-[5rem]' src={data.profilePicture ? data.profilePicture.url : random_logo} alt='vendor_store' />
                      </div>
                      <div className='w-[100%] h-[4rem] border-t border-search-box-border'>
                        <p className='w-[100%] h-[1.5rem] text-lg font-bold text-start truncate overflow-hidden'>{data?.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : ''}</p>
                        <div className='w-[100%] h-[2.25rem] text-[0.7rem] text-start text-ellipsis overflow-hidden'>
                          <span>
                            {data.plotnumber ? data.plotnumber + ", " : ""}
                            {data.address ? data.address + ", " : ''}
                            {data.city ? data.city : ''}
                          </span>
                        </div>
                      </div>
                    </div>

                  </div>
                )
              })
              }
            </>
            : <Loader />}


      </div>
    </>
  )
}

export default Chipt_Show_Vendor;