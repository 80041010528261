import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToggle, useVibrate } from 'react-use';
import { useDispatch, useSelector } from 'react-redux'
import { RiHandCoinLine } from 'react-icons/ri';
import CupImage from '../asset/cupDetails.svg';
import { Call_Scanned_Cup_Details, state_Is_Loading, state_Cup_Details } from '../../redux/orders/ScannedCupSlice';
import { Call_Get_All_Vendors } from '../../redux/authentication/AllVendorSlice';
import Project_Loader from '../NavigationComponents/Project_Loader';
import { Helmet } from 'react-helmet';


const Chipt_Scanned_Cup_Details = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mid } = useParams();
  const isLoading = useSelector(state_Is_Loading);
  const state_cupDetail = useSelector(state_Cup_Details);
  const [cupDetails, setCupDetails] = useState();
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  // Vibration
  const [vibrating, toggleVibrating] = useToggle(false);
  useVibrate(vibrating, [10], false);

  const handleProceedNowOrder = (e) => {
    e.preventDefault();
    if (latitude && longitude) {
      dispatch(Call_Get_All_Vendors({ srch: '', loc: { latitude: latitude, longitude: longitude } }))
    } else {
      dispatch(Call_Get_All_Vendors())
    }
    setTimeout(() => {
      toggleVibrating()
    }, 0);
    setTimeout(() => {
      toggleVibrating()

      if (!cupDetails?.isReturn) {
        navigate('/return/cup/preview')
      } else {
        navigate(`/cup/order/preview/${cupDetails?.modelID}/${cupDetails?.uniqueID}`)
      }
    }, 100)
  }

  useEffect(() => {
    if (!state_cupDetail && !cupDetails) {
      dispatch(Call_Scanned_Cup_Details({ uniqueID: mid }))
    } else if (state_cupDetail && !cupDetails) {
      setCupDetails(state_cupDetail.cupData)
    }
  }, [dispatch])

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        error => {
          console.log("===>", error)
          setLatitude(undefined);
          setLongitude(undefined);
        }
      );
    }
  }, [isLoading])

  return (
    <>
      <div className='w-[100%] min-h-screen h-auto float-left overflow-auto'>

        {isLoading
          ? <div className='w-[100%] h-screen'><Project_Loader /></div>
          :
          <>
            {/* Cup -> Type Price Point Size */}
            <div className='w-[100%] h-auto float-left overflow-auto'>
              <div className='w-[100%] h-auto my-4 overflow-auto'>
                <p className='w-[100%] h-auto mt-6 text-3xl font-bold text-center truncate overflow-hidden'>{cupDetails?.cupType ? cupDetails.cupType.charAt(0).toUpperCase() + cupDetails.cupType.slice(1) : '-'}</p>
                <div className='w-[100%] h-auto text-center flex items-center justify-center'>
                  <p className='w-auto h-auto px-2 text-center text-lg font-bold'><span className='text-primaryBlue'><i>{cupDetails?.currency.toUpperCase()}</i></span>&nbsp;{cupDetails?.price ? cupDetails.price : '0'}</p>
                  <p className='w-auto h-auto px-1 text-center text-lg font-bold'>&nbsp;|&nbsp;</p>
                  <p className='w-auto h-auto px-2 text-center text-lg font-bold flex items-center justify-start'><RiHandCoinLine className='text-sm text-gold' />&nbsp;{cupDetails?.point ? cupDetails.point : 0}</p>
                </div>
              </div>
            </div>

            {/* Cup -> Image */}
            <div className='w-[100%] h-[20rem] md:h-[24rem] float-left flex items-center justify-center'>
              <img className='w-auto h-[18rem] md:h-[22rem] rounded-md' src={cupDetails?.cupImages[0] ? cupDetails.cupImages[0].url : CupImage} alt='cup_details' />
            </div>

            {/* Cup -> Model ID and Unique ID
            <div className='w-[100%] h-[5rem] float-left py-8'>
              <div className='w-[80%] h-auth mx-[10%] flex items-center justify-center'>
                <p className='w-[30%] h-auto px-2 text-sm text-right text-gray'><i>Model Id:</i></p>
                <p className='w-[70%] h-auto px-2 text-sm truncate overflow-hidden'>{cupDetails?.modelID}</p>
              </div>
              <div className='w-[80%] h-auth mx-[10%] flex items-center justify-center'>
                <p className='w-[30%] h-auto px-2 text-sm text-right text-gray'><i>Unique Id:</i></p>
                <p className='w-[70%] h-auto px-2 text-sm truncate overflow-hidden'>{cupDetails?.uniqueID}</p>
              </div>
            </div> */}

            {/* Cup SIze and Capacity */}
            <div className='w-[100%] h-auto p-4 grid grid-cols-1 place-items-center'>
              <p className='w-[100%] h-auto text-center text-2xl font-bold text-primaryNavy'>{cupDetails?.cupSize ? cupDetails.cupSize : ''}&nbsp;<i className='font-medium text-lg'>&#10098;{cupDetails?.capacity ? cupDetails.capacity + " ml" : '0 ml'}&#10099;</i></p>
            </div>

            {/* Proceed BTN */}
            <div className='w-[50%] md:w-[70%] sm:w-[100%] h-auto py-2 fixed bottom-0 float-left flex items-center justify-center'>
              <button className='w-auto h-auto py-2 px-8 shadow-md shadow-gray text-lg text-white font-bold rounded-4xl bg-primaryBlue hover:bg-primaryBlueHover' onClick={handleProceedNowOrder}>{cupDetails?.isReturn ? 'Proceed' : 'Return'}</button>
            </div>
          </>
        }

      </div>
      <Helmet>
        <title>CHIPT | SCANNED CUP</title>
      </Helmet>
    </>
  )
}

export default Chipt_Scanned_Cup_Details;