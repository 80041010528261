import { useEffect } from 'react';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle, useVibrate } from 'react-use';
import { ActionsContext } from '../../context/Context';
import { IoQrCode } from "react-icons/io5";
import { SiNfc } from "react-icons/si";
import { state_Is_Authenticated } from '../../redux/authentication/CustomerAuthSlice'
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

const Chipt_Footer_NFC_Bar = () => {

  const navigate = useNavigate();

  const [vibrating, toggleVibrating] = useToggle(false);
  useVibrate(vibrating, [300], false);
  const isAuthenticated = useSelector(state_Is_Authenticated);

  const handleVibrationFunction = (e, toPage) => {
    e.preventDefault();
    if(!isAuthenticated){
      toast.error(`Please login to proceed!`)
      navigate('/signin');
      return
    }
    setTimeout(() => toggleVibrating(), 0);
    setTimeout(() => {
      toggleVibrating();
      navigate(toPage);
    }, 100)
  }

  useEffect(()=>{
  }, [isAuthenticated])


  return (
    <>
      <div className='lg:w-[50%] md:w-[70%] sm:w-[100%] mt-2 shadow-md shadow-gray float-left fixed bottom-0 z-40 bg-white rounded-t-xl'>
        <div className='w-[100%] h-[3rem] float-left flex items-center justify-center'>
          <button className='w-[30%] lg:w-0 lg:h-0 h-10 lg:mx-0 mx-4 flex items-center justify-center overflow-hidden' onClick={(e) => handleVibrationFunction(e, '/nfc/reader')}><SiNfc className='text-2xl text-gray' /></button>
          <button className='w-[30%] h-10 mx-4 flex items-center justify-center' onClick={(e) => handleVibrationFunction(e, '/qr/reader')}><IoQrCode className='text-2xl text-gray' /></button>
        </div>
      </div>
    </>

  )
}

export default Chipt_Footer_NFC_Bar;