import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';


export const Call_OTP_Verify = createAsyncThunk(
    'accountOTP/Call_OTP_Verify',
    async (otp) => {
        const response = await axios.put(`/api/v1/customer/account/otp/verification`, { otp });
        return response.data;
    },
);

export const Call_Resend_OTP_Verify = createAsyncThunk(
    'accountOTP/Call_Resend_OTP_Verify',
    async () => {
        const response = await axios.get(`/api/v1/customer/account/resend/otp`);
        return response.data;
    },
);

export const Call_Clear_Resend_OTP = createAsyncThunk(
    'accountOTP/Call_Clear_Resend_OTP',
    async () => {
        const response = {
            data: null
        };
        return response.data;
    },
);


const accountOTPSlice = createSlice({
    name: "accountOTP",
    initialState: {
        status: 'idle',
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Account OTP
            .addCase(Call_OTP_Verify.pending, (state) => {
                state.status = 'loading';
                state.isLoading = true;
                state.error = null;
            })
            .addCase(Call_OTP_Verify.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.error = null;
            })
            .addCase(Call_OTP_Verify.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.error = action.error.message;
            })

            // Forgot OTP
            .addCase(Call_Resend_OTP_Verify.pending, (state) => {
                state.status = 'loading';
                state.isLoading = true;
                state.error = null;
            })
            .addCase(Call_Resend_OTP_Verify.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.error = null;
            })
            .addCase(Call_Resend_OTP_Verify.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.error = action.error.message;
            })

            // Clear State
            .addCase(Call_Clear_Resend_OTP.pending, (state) => {
                state.status = 'loading';
                state.isLoading = true;
                state.error = null;
            })
            .addCase(Call_Clear_Resend_OTP.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.error = null;
            })
            .addCase(Call_Clear_Resend_OTP.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.error = null;
            })
    },
});

export const state_Call_Fetch_Status = (state) => state.accountOTP.status;
export const state_Is_Loading = (state) => state.accountOTP.isLoading;
export const state_Has_Error = (state) => state.accountOTP.error;

export default accountOTPSlice.reducer;