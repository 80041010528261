import React, { useState, useEffect, useContext } from 'react';
import toast from 'react-hot-toast';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { Call_Get_Profile, Call_Update_Profile_Contact, Call_Update_Profile_Basic_Information, state_Is_Loading, state_Customer_Profile } from '../../../redux/Profile/CustomerProfileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ActionsContext } from '../../../context/Context';
import Project_Loader from '../../NavigationComponents/Project_Loader';
import { Helmet } from 'react-helmet';

const Chipt_Contact_Update = () => {

    const dispatch = useDispatch();
    const isLoading = useSelector(state_Is_Loading);
    const customer = useSelector(state_Customer_Profile);
    const [userInfo, setUserInfo] = useState();
    const [countryCode, setCountryCode] = useState('MY');
    const [callLoading, setCallLoading] = useState(false);
    const notificationContext = useContext(ActionsContext);

    const updateCustomerContact = async (e) => {
        e.preventDefault();
        notificationContext.loadRef.current.staticStart();
        setCallLoading(true)
        let tempCode = getCountryCallingCode(countryCode);
        let temp = {}
        temp.username = userInfo.username || undefined;
        temp.email = userInfo.email;
        temp.countryCode = tempCode;
        temp.contact = userInfo.contact;
        dispatch(Call_Update_Profile_Contact(temp))
            .then((data) => {
                if (data.payload && data.payload.data) {
                    if (data.payload.data.success) toast.success('Information has been updated!');
                    else toast.error(data.payload.data.message);

                }
                setCallLoading(false);
            })
        notificationContext.loadRef.current.complete();
    }

    const updateBasicInformation = (e) => {
        e.preventDefault();
        setCallLoading(true)
        notificationContext.loadRef.current.staticStart();
        dispatch(Call_Update_Profile_Basic_Information(userInfo))
            .then((data) => {
                if (data.payload) toast.success('Information has been updated!');
                dispatch(Call_Get_Profile())
                    .then((data) => {
                        if (data.payload && data.payload.customer) {
                            setUserInfo(data.payload.customer);
                        }
                    })
                setCallLoading(false);
                notificationContext.loadRef.current.complete();
            })
        notificationContext.loadRef.current.complete();
    }

    const handleContactChange = (e) => {
        e.preventDefault();
        if (e.target.name == 'contactNumber') {
            if (e.target.value < 0) {
                toast.error('Negative value not allowed');
                return
            }
        }
        if (e.target.name == 'dateOfBirth') {
            let dob = new Date(e.target.value)
            if (dob > Date.now()) {
                toast.error(`Birthday cannot be later than today.`)
                return
            }
        }
        setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    }

    useEffect(() => {

        if (customer) {
            if (customer.data) {
                if (customer.data.customer) {
                    setUserInfo(customer.data.customer)
                }
            } else {
                setUserInfo(customer.customer)
            }
        }
        else if (!callLoading) {
            dispatch(Call_Get_Profile())
                .then((data) => {
                    if (data.payload) {
                        setUserInfo(data.payload.customer)
                    }
                })
        } else {
            dispatch(Call_Get_Profile())
                .then((data) => {
                    if (data.payload) {
                        setUserInfo(data.payload.customer)
                    }
                })
        }
    }, [isLoading, callLoading]);

    return (
        <>
            {callLoading
                ? <div className="w-full lg:w-[40%] h-full absolute">
                    <Project_Loader />
                </div>
                :
                <>
                    <div className='w-[100%] h-[17rem] float-left my-4 border border-gray-light shadow shadow-gray-light bg-white rounded-lg p-2 px-4 overflow-hidden'>
                        <form className='' onSubmit={updateCustomerContact}>

                            <div className={`${callLoading ? '' : 'relative'} rounded-lg mt-6 mb-4`}>
                                <input type="text" id="username" name='username'
                                    value={userInfo?.username ? userInfo.username : ''}
                                    onChange={handleContactChange}
                                    className="block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer hover:outline-primaryBlue" placeholder="" autoComplete='true' />
                                <label htmlFor="username" className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">User Name</label>
                            </div>

                            <div className={`${callLoading ? '' : 'relative'} rounded-lg mt-4 mb-1`}>
                                <input type="text" id="email" name='email'
                                    value={userInfo ? userInfo.email ? userInfo.email : '' : ''}
                                    onChange={handleContactChange}
                                    className="block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer hover:outline-primaryBlue" placeholder="" autoComplete='true' />
                                <label htmlFor="email" className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Email</label>
                            </div>

                            <div className={`${callLoading ? '' : 'relative'} w-[35%] mr-[2%] float-left rounded-lg my-4`}>
                                <select type='text' id='countrySelect' name='countrySelect'
                                    value={countryCode} onChange={(e) => setCountryCode(e.target.value)}
                                    className="truncate overflow-hidden block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none bg-white border border-gray hover:border peer hover:outline-primaryBlue"
                                    placeholder=" " autoComplete='on' >
                                    <option value="">{en.ZZ}</option>
                                    {getCountries().map((country) => (
                                        <option key={country} value={country}>
                                            {en[country]} + {getCountryCallingCode(country)}
                                        </option>
                                    ))}
                                </select>
                                <label htmlFor="countrySelect" className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Country</label>
                            </div>
                            <div className={`${callLoading ? '' : 'relative'} w-[63%] float-left rounded-lg my-4`}>
                                <input type="text" id="contact" name='contact'
                                    value={userInfo ? userInfo.contact ? userInfo.contact : '' : ''}
                                    onChange={handleContactChange}
                                    className="block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer hover:outline-primaryBlue" placeholder="" autoComplete='on' />
                                <label htmlFor="contact" className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Contact</label>
                            </div>
                            <div className={`${callLoading ? '' : 'relative'} w-[100%] my-4 mb-4 flex items-center justify-start`}>
                                <button className='w-16 bg-primaryBlue text-sm text-white font-bold py-1.5 rounded-lg'>Save</button>
                            </div>
                        </form>


                    </div>

                    <div className='w-[100%] h-[25rem] float-left my-4 border border-gray-light shadow shadow-gray-light bg-white rounded-lg p-2 px-4 overflow-hidden'>
                        <form onSubmit={updateBasicInformation}>
                            <div className='h-auto w-[100%]'>
                                <div>
                                    <div className={`${callLoading ? '' : 'relative'} rounded-lg mt-4`}>
                                        <input type="text" id="firstname" name='firstname'
                                            value={userInfo ? userInfo.firstname ? userInfo.firstname : '' : ''}
                                            onChange={handleContactChange}
                                            className="block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer hover:outline-primaryBlue" placeholder=" " autoComplete='true' />
                                        <label htmlFor="firstname" className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">First Name</label>
                                    </div>

                                    <div className={`${callLoading ? '' : 'relative'} rounded-lg mt-4`}>
                                        <input type="text" id="middlename" name='middlename'
                                            value={userInfo ? userInfo.middlename ? userInfo.middlename : '' : ''}
                                            onChange={handleContactChange}
                                            className="block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer hover:outline-primaryBlue" placeholder=" " autoComplete='true' />
                                        <label htmlFor="middlename" className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Middle Name</label>
                                    </div>

                                    <div className={`${callLoading ? '' : 'relative'} rounded-lg mt-4`}>
                                        <input type="text" id="lastname" name='lastname'
                                            value={userInfo ? userInfo.lastname ? userInfo.lastname : '' : ''}
                                            onChange={handleContactChange}
                                            className="block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer hover:outline-primaryBlue" placeholder=" " autoComplete='true' />
                                        <label htmlFor="lastname" className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Last Name</label>
                                    </div>

                                    <div className={`${callLoading ? '' : 'relative'} rounded-lg mt-4 bg-white`}>
                                        <select type='text' id='gender' name='gender'
                                            value={userInfo ? userInfo.gender ? userInfo.gender : '' : ''}
                                            onChange={handleContactChange}
                                            className="block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none bg-white border border-gray hover:border peer hover:outline-primaryBlue" placeholder=" " autoComplete='on' >
                                            <option className='text-gray'>Please choose one option</option>
                                            <option>Male</option>
                                            <option>Female</option>
                                            <option>Others</option>
                                        </select>
                                        <label htmlFor="gender" className="absolute text-sm text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Gender</label>
                                    </div>

                                    <div className={`${callLoading ? '' : 'relative'} rounded-lg mt-4`}>
                                        <input type='date' id="dateOfBirth" name='dateOfBirth'
                                            value={userInfo ? userInfo.dateOfBirth ? userInfo.dateOfBirth.toString().slice(0, 10) : '' : ''}
                                            onChange={handleContactChange}
                                            className="block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none text-black bg-white border border-gray hover:border peer hover:outline-primaryBlue" placeholder=" " autoComplete='on' />
                                        <label htmlFor="dateOfBirth" className="absolute text-xs text-black duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Date of Birth</label>
                                    </div>

                                </div>
                            </div>
                            <div className={`${callLoading ? '' : 'relative'} w-[100%] my-4 flex items-center justify-start`}>
                                <button className='w-16 bg-primaryBlue text-sm text-white font-bold py-1.5 rounded-lg'>Save</button>
                            </div>
                        </form>
                    </div>
                </>
            }
            <Helmet>
                <title>CHIPT | PROFILE UPDATE</title>
            </Helmet>
        </>
    )
}

export default Chipt_Contact_Update