import React, { useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import WelcomeLogo from '../asset/window1.svg';
import ChiptLogo from '../asset/chipt.svg';
import { state_Is_Authenticated } from '../../redux/authentication/CustomerAuthSlice';
import Chipt_Security_Footer from '../NavigationComponents/Chipt_Security_Footer';
import {Helmet} from 'react-helmet';

const Chipt_Welcome = () => {

  const navigate =useNavigate()
  const isAuthenticated = useSelector(state_Is_Authenticated);

  useEffect(()=>{
    if(isAuthenticated){
      navigate('/home')
    }
  }, [isAuthenticated])
  return (
    <>
      {/* Main container start */}
      <div className='w-[100%] h-screen'>

        {/* Sub Container start */}
        <div className='w-[90%] h-auto mx-[5%] float-left' >

          {/* navigation start */}
          <div className='w-[100%] h-[3rem] mx-0 float-start flex items-center justify-end'>
            <Link to="/about"><button className='float-right bg-gray-light rounded-xl text-xs font-bold px-2 py-1' >NEXT</button></Link>
          </div>
          {/* navigation end */}

          {/* Content start */}
          <div className='w-[100%] h-[28rem] mx-0' onClick={()=>navigate('/about')}>
            {/* Welcome logo */}
            <div className='h-[14rem] flex items-center justify-start'>
              <img className='w-auto h-[5rem] mt-[2rem]' src={WelcomeLogo} alt="Chipt_Logo" />
            </div>
            {/* Project logo */}
            <div className='h-[14rem] flex items-start justify-center'>
              <img className='w-auto h-[4rem] mt-[2rem]' src={ChiptLogo} alt="Chipt_Logo" />
            </div>
          </div>
          {/* Content end */}

        </div>
        {/* Sub Container end */}

        {/* bottom footer */}
        <div className='w-[80%] h-[14rem] mx-[10%] float-left text-center'>
          <div className='h-[1.5rem] mb-[8rem] flex items-start justify-center'>
            <Link to="/signin"><p className='text-md font-bold underline decoration-1 break-all'>Already have an account?</p></Link>
          </div>
          <Chipt_Security_Footer/>
        </div>
      </div>
      {/* Main container end */}
      <Helmet>
        <title>CHIPT | WELCOME</title>
      </Helmet>
    </>


  )
}

export default Chipt_Welcome;