import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';


export const Call_Get_Profile_Image = createAsyncThunk(
    'customerProfileImage/Call_Get_Profile_Image',
    async () => {
        const response = await axios.get(`/api/v1/customer/account/profile/image`);
        return response.data;
    }
)

export const Call_Update_Profile_Image = createAsyncThunk(
    'customerProfileImage/Call_Update_Profile_Image',
    async (data) => {
        const response = await axios.put(`/api/v1/customer/account/profile/image/upload`, data);
        return response;
    }
);

export const Call_Clear_Profile_Image_Data = createAsyncThunk(
    'customerProfileImage/Call_Clear_Profile_Image_Data',
    async () => {
        const response = {
            data: null
        };
        return response.data;
    },
);

const customerProfileImageSlice = createSlice({
    name: "customerProfileImage",
    initialState: {
        status: 'idle',
        isLoading: false,
        customerProfile: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder

            // Profile Image
            .addCase(Call_Get_Profile_Image.pending, (state) => {
                state.status = 'idle';
                state.isLoading = true;
                state.customerProfile = null;
                state.error = null;
            })
            .addCase(Call_Get_Profile_Image.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.customerProfile = action.payload;
                state.error = null;
            })
            .addCase(Call_Get_Profile_Image.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.customerProfile = null;
                state.error = action.error.message;
            })

            // Profile Image Update
            .addCase(Call_Update_Profile_Image.pending, (state) => {
                state.status = 'idle';
                state.isLoading = true;
                state.customerProfile = null;
                state.error = null;
            })
            .addCase(Call_Update_Profile_Image.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.customerProfile = action.payload;
                state.error = null;
            })
            .addCase(Call_Update_Profile_Image.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.customerProfile = null;
                state.error = action.error.message;
            })

            // Clear State
            .addCase(Call_Clear_Profile_Image_Data.pending, (state) => {
                state.status = 'idle';
                state.isLoading = true;
                state.customerProfile = null;
                state.error = null;
            })
            .addCase(Call_Clear_Profile_Image_Data.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.customerProfile = null;
                state.error = null;
            })
            .addCase(Call_Clear_Profile_Image_Data.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.customerProfile = null;
                state.error = null;
            })
    }
});

export const state_Call_Fetch_Status = (state) => state.customerProfileImage.status;
export const state_Is_Loading = (state) => state.customerProfileImage.isLoading;
export const state_Customer_Profile_Image = (state) => state.customerProfileImage.customerProfile;
export const state_Has_Error = (state) => state.customerProfileImage.error;

export default customerProfileImageSlice.reducer;