import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const Call_Customer_All_Cups_Bought = createAsyncThunk(
    'customerBoughtCups',
    async() => {
        const response = await axios.get('/api/v1/customer/order/all/cups')
        return response.data
    }
);

const customerBoughtCupsSlice = createSlice({
    name: 'boughtCups',
    initialState:{
        status: 'idle',
        isLoading: false,
        boughtCups: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder

            // Bought Cups
            .addCase(Call_Customer_All_Cups_Bought.pending, (state) => {
                state.status = 'loading';
                state.isLoading = true;
                state.boughtCups = null;
                state.error = null;
            })
            .addCase(Call_Customer_All_Cups_Bought.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isLoading = false;
                state.boughtCups = action.payload;
                state.error = null;
            })
            .addCase(Call_Customer_All_Cups_Bought.rejected, (state, action) => {
                state.status = 'failed';
                state.isLoading = false;
                state.boughtCups = false;
                state.error = action.error.message;
            })
    },
});

export const state_Call_Fetch_Status = (state) => state.boughtCups.status;
export const state_Is_Loading = (state) => state.boughtCups.isLoading;
export const state_Bought_Cups = (state) => state.boughtCups.boughtCups;
export const state_Has_Error = (state) => state.boughtCups.error;
export default customerBoughtCupsSlice.reducer;