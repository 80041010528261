import React, { useState } from 'react';

const Chipt_Data_Loader = () => {
    const [intensity, setIntensity] = useState(0);

    setInterval(()=>{
        if(intensity===0){
            setIntensity(25)
        } else if(intensity===25){
            setIntensity(50)
        } else if(intensity===50){
            setIntensity(75)
        } else if(intensity===75){
            setIntensity(100)
        } else if(intensity===100){
            setIntensity(0)
        }
    },500)
  return (
    <div className='w-[100%] h-max flex items-center justify-center overflow-hidden'>
        <div className={`w-2 h-2 mx-2 lg:w-4 lg:h-4 lg:mx-4 rounded-full flex items-center justify-center ${intensity === 0 ? 'bg-black' : 'bg-gray'}`}></div>
        <div className={`w-2 h-2 mx-2 lg:w-4 lg:h-4 lg:mx-4 rounded-full flex items-center justify-center ${intensity === 25 ? 'bg-black' : 'bg-gray'}`}></div>
        <div className={`w-2 h-2 mx-2 lg:w-4 lg:h-4 lg:mx-4 rounded-full flex items-center justify-center ${intensity === 50 ? 'bg-black' : 'bg-gray'}`}></div>
        <div className={`w-2 h-2 mx-2 lg:w-4 lg:h-4 lg:mx-4 rounded-full flex items-center justify-center ${intensity === 75 ? 'bg-black' : 'bg-gray'}`}></div>
        <div className={`w-2 h-2 mx-2 lg:w-4 lg:h-4 lg:mx-4 rounded-full flex items-center justify-center ${intensity === 100 ? 'bg-black' : 'bg-gray'}`}></div>
    </div>
  )
}

export default Chipt_Data_Loader